import React, { useCallback, useEffect, useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { conditionsEntityTypes } from 'api/conditions/conditions'
import { conditionsAllowedOperations } from 'api/conditions/conditionsAllowedOperations'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import {
  FINANCING_CUSTOM_FIELDS_READ,
  FINANCING_NON_LOAN_PRODUCTS_READ,
  FINANCING_OTHER_FINANCING_SOURCES_READ,
  FINANCING_TRANCHE_ACCOUNTING_READ,
  FINANCING_TRANCHE_ADDITIONAL_INTEREST_INFORMATION_READ,
  FINANCING_TRANCHE_AMORTIZATION_CONDITIONS_READ,
  FINANCING_TRANCHE_FEE_READ,
  FINANCING_TRANCHE_INTEREST_CONDITIONS_READ,
  FINANCING_TRANCHE_INTEREST_RATE_HEDGING_REQUIREMENT_READ,
  FINANCING_TRANCHE_OPTIONS_READ,
  FINANCING_TRANCHE_PRICING_READ,
  FINANCING_TRANCHE_READ,
} from 'api/deals/financing/allowedOperationsConstants'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { useDealReservationCardData } from 'components/domains/deals/limit-check/reservations/useDealReservationCardData'
import useDealReservationConfigMaps from 'components/domains/deals/limit-check/reservations/useDealReservationConfigMaps'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import NavigationL2 from 'components/ui/navigation/NavigationL2'
import NeedToKnowWrapper from 'components/ui/need-to-know/NeedToKnowWrapper'
import RecentVisitWrapper from 'components/ui/recent-visit/RecentVisitWrapper'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useConditionsAllowedOperations from 'hooks/services/conditions/useConditionsAllowedOperations'
import { useCovenantAllowedOperations } from 'hooks/services/deals/covenants/useCovenantAllowedOperations'
import { useFinancingAllowedOperations } from 'hooks/services/deals/financing/useFinancingAllowedOperations'
import { useDealHeaders } from 'hooks/services/deals/useDealHeaders'
import { useDealPermissions } from 'hooks/services/deals/useDealPermissions'
import useDealNeedToKnow from 'hooks/services/need-to-know/useDealNeedToKnow'
import { CovenantContext } from 'routes/business-partners/CovenantContext'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsNoPermissions from 'routes/deals/DealDetailsNoPermissions'
import { dealDetailPaths, queryParameterNames } from 'routes/deals/DealRoutes'
import ErrorDealDoesNotExist from 'routes/deals/ErrorDealDoesNotExist'
import { useDealDynamicCards } from 'routes/deals/useDealDynamicCards'
import useNavigateToWorkingVersion from 'routes/deals/useNavigateToWorkingVersion'

const buildL2NavigationItems = ({
  hasConditionsReadPermission,
  hasFinancingReadPermission,
  showWorkingVersion,
}) => {
  const allNavItems = [
    {
      // + './' is a workaround for 'showWorkingVersion===true' scenario where we use query params
      // When query params are there, react-router fails with the location.path & link-matching and
      // always marks the overview link as active (e.g. we can have 2 active links in NavigationL2)
      path: dealDetailPaths.overview + './',
      translationToken: 'pages.deals.detail.overview.title',
    },
    {
      path: dealDetailPaths.generalInformation,
      translationToken: 'pages.deals.general-information',
    },
    ...(hasFinancingReadPermission
      ? [
          {
            path: dealDetailPaths.financing,
            translationToken: 'pages.deals.detail.financing.title',
          },
        ]
      : []),
    {
      path: dealDetailPaths.syndication,
      translationToken: 'pages.deals.detail.syndications.title',
    },
    {
      path: dealDetailPaths.financedObjects,
      translationToken: 'pages.deals.detail.properties.title',
    },
    {
      path: dealDetailPaths.cashflows,
      translationToken: 'pages.deals.detail.cashflow.title',
    },
    {
      path: dealDetailPaths.collaterals,
      translationToken: 'pages.deals.detail.collaterals.title',
    },
    ...(hasConditionsReadPermission
      ? [
          {
            path: dealDetailPaths.conditions,
            translationToken: 'pages.conditions.l2-navigation',
          },
        ]
      : []),
    {
      path: dealDetailPaths.covenants,
      translationToken: 'pages.deals.detail.covenants.title',
    },
    ...(!showWorkingVersion
      ? [
          {
            path: dealDetailPaths.kpis,
            translationToken: 'pages.deals.detail.kpis.title',
          },
        ]
      : []),
    {
      path: dealDetailPaths.documents,
      translationToken: 'pages.deals.detail.documents.title',
    },
    {
      path: dealDetailPaths.changeOverview,
      translationToken: 'pages.deals.detail.change-overview.title',
    },
  ]

  if (showWorkingVersion) {
    allNavItems.forEach((item) => {
      item.path += `?${queryParameterNames.workingVersion}=true`
    })
  }
  return allNavItems
}

const DealWrapper = () => {
  // register dynamic cards for deal domain
  useDealDynamicCards()

  const { dealId, covenantId } = useParams()
  const [searchParams] = useSearchParams()
  const switchToWorkingVersion = useNavigateToWorkingVersion({ displayToast: true })

  // read query parameter
  const queryParameterToWorkingVersionType = () =>
    searchParams.get(queryParameterNames.workingVersion) === 'true'
      ? WorkingVersionType.WORKING_VERSION
      : WorkingVersionType.LIVE
  const shouldShowWorkingVersion =
    queryParameterToWorkingVersionType() === WorkingVersionType.WORKING_VERSION

  // fetch deal header
  const {
    isLoading: isLoadingHeaders,
    isFetching: isFetchingHeaders,
    isError: isErrorHeaders,
    data: { live, workingVersion } = {},
  } = useDealHeaders(dealId)

  // doublecheck the requested working version type
  useEffect(() => {
    if (isLoadingHeaders || isFetchingHeaders) return
    if (shouldShowWorkingVersion && !workingVersion) {
      switchToWorkingVersion(WorkingVersionType.LIVE) // the user requested to see the workingversion, but only live is available
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingHeaders, live, workingVersion])

  const currentDeal = useMemo(() => {
    const currentDealHeader = shouldShowWorkingVersion && !!workingVersion ? workingVersion : live
    return {
      displayId: currentDealHeader?.dealId,
      dealUuid: currentDealHeader?.dealUuid,
      name: currentDealHeader?.name,
      status: currentDealHeader?.status,
      workingVersion: currentDealHeader?.workingVersion,
      onHold: currentDealHeader?.onHold,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingHeaders, isFetchingHeaders, shouldShowWorkingVersion, workingVersion, live])

  const {
    isFetching: isFetchingNeedToKnow,
    isError: isErrorNeedToKnow,
    data: { allowed: isDealAccessAllowed } = {},
  } = useDealNeedToKnow(currentDeal.dealUuid)
  const {
    isFetching: isFetchingAllowedOperations,
    isError: isErrorAllowedOperations,
    data: { allowedOperations = [] } = {},
  } = useDealPermissions(currentDeal.dealUuid)
  const {
    isFetching: isConditionsAllowedOperationsFetching,
    isError: isConditionsAllowedOperationsError,
    data: conditionsAllowedOperationsData = {},
  } = useConditionsAllowedOperations()
  const {
    isFetching: isLoadingAllowedOperationsFetching,
    isError: isErrorAllowedOperationsCovenant,
    data: { allowedOperations: covenantAllowedOperations = [] } = {},
  } = useCovenantAllowedOperations(covenantId)

  const hasConditionsReadPermission = useMemo(
    () =>
      !isConditionsAllowedOperationsError &&
      conditionsAllowedOperationsData.allowedOperations?.includes(
        conditionsAllowedOperations.readCondition,
      ),
    [conditionsAllowedOperationsData, isConditionsAllowedOperationsError],
  )
  const isAllowedDealRead = allowedOperations.includes(dealsPermissions.readDeal)

  const {
    isFetching: isFinancingAllowedOperationsFetching,
    isError: isFinancingAllowedOperationsError,
    data: { allowedOperations: financingAllowedOperations = [] } = {},
  } = useFinancingAllowedOperations(currentDeal.dealUuid)

  const hasFinancingReadPermission = useMemo(
    () =>
      !isFinancingAllowedOperationsError &&
      [
        FINANCING_TRANCHE_READ,
        FINANCING_TRANCHE_FEE_READ,
        FINANCING_TRANCHE_INTEREST_CONDITIONS_READ,
        FINANCING_TRANCHE_AMORTIZATION_CONDITIONS_READ,
        FINANCING_TRANCHE_PRICING_READ,
        FINANCING_TRANCHE_INTEREST_RATE_HEDGING_REQUIREMENT_READ,
        FINANCING_TRANCHE_ACCOUNTING_READ,
        FINANCING_TRANCHE_ADDITIONAL_INTEREST_INFORMATION_READ,
        FINANCING_TRANCHE_OPTIONS_READ,
        FINANCING_OTHER_FINANCING_SOURCES_READ,
        FINANCING_CUSTOM_FIELDS_READ,
        FINANCING_NON_LOAN_PRODUCTS_READ,
      ].some((permission) => financingAllowedOperations?.includes(permission)),
    [financingAllowedOperations, isFinancingAllowedOperationsError],
  )

  const { warningMessages } = useDealReservationCardData(currentDeal.dealUuid)
  const { warningConfigMap } = useDealReservationConfigMaps()

  const globalWarningMessages = useMemo(
    () =>
      shouldShowWorkingVersion
        ? warningMessages.filter((warningMessage) =>
            [
              warningConfigMap.MULTI_COUNTRY_OR_PROPERTY_TYPE.message,
              warningConfigMap.NO_RESERVATIONS.message,
              warningConfigMap.STATUS_CALCULATION_NOT_SUPPORTED.message,
            ].every((localWarning) => warningMessage !== localWarning),
          )
        : warningMessages.filter((warningMessage) =>
            [warningConfigMap.MULTI_COUNTRY_OR_PROPERTY_TYPE.message].every(
              (localWarning) => warningMessage !== localWarning,
            ),
          ),
    [shouldShowWorkingVersion, warningConfigMap, warningMessages],
  )

  const navItems = useMemo(
    () =>
      buildL2NavigationItems({
        hasConditionsReadPermission,
        hasFinancingReadPermission,
        showWorkingVersion: shouldShowWorkingVersion,
      }),
    [hasConditionsReadPermission, hasFinancingReadPermission, shouldShowWorkingVersion],
  )

  const renderDeals = useCallback(() => {
    if (!isAllowedDealRead) {
      return <DealDetailsNoPermissions />
    }

    return (
      <DealContext.Provider
        value={{
          deal: currentDeal,
          dealHeaders: { live, workingVersion },
          allowedOperations,
          conditionsAllowedOperations: conditionsAllowedOperationsData.allowedOperations,
          financingAllowedOperations,
          warningMessages: globalWarningMessages,
        }}
      >
        <CovenantContext.Provider value={{ allowedOperations: covenantAllowedOperations }}>
          <ConditionsContext.Provider
            value={{
              entityRef: {
                entityId: currentDeal.dealUuid,
                entityDisplayId: currentDeal.displayId,
                entityType: conditionsEntityTypes.deal,
              },
              allowedOperations: conditionsAllowedOperationsData.allowedOperations,
            }}
          >
            <NavigationL2 navItems={navItems} />
          </ConditionsContext.Provider>
        </CovenantContext.Provider>
      </DealContext.Provider>
    )
  }, [
    allowedOperations,
    conditionsAllowedOperationsData.allowedOperations,
    covenantAllowedOperations,
    currentDeal,
    financingAllowedOperations,
    isAllowedDealRead,
    live,
    navItems,
    workingVersion,
    globalWarningMessages,
  ])
  const isError = (isErrorNeedToKnow || !isDealAccessAllowed) && !!currentDeal?.dealUuid
  return (
    <RecentVisitWrapper entityId={currentDeal.dealUuid} entityType={cwpEntityTypes.DEAL}>
      <NeedToKnowWrapper isError={isError} isLoading={isFetchingNeedToKnow || isLoadingHeaders}>
        <RequestStateResolver
          center
          errorToDisplay={
            currentDeal.dealUuid ? <ErrorDataUnavailableInContent /> : <ErrorDealDoesNotExist />
          }
          isError={isErrorAllowedOperations || isErrorHeaders || isErrorAllowedOperationsCovenant}
          isLoading={
            isFetchingAllowedOperations ||
            isLoadingHeaders ||
            isConditionsAllowedOperationsFetching ||
            isFinancingAllowedOperationsFetching ||
            isLoadingAllowedOperationsFetching
          }
          renderContent={renderDeals}
        />
      </NeedToKnowWrapper>
    </RecentVisitWrapper>
  )
}

export default DealWrapper
