import { useRequest } from 'hooks/services/baseService'

const usePropertyIdForAssignedMarket = (propertyIds, options = {}) => {
  let filterProperties = ''
  propertyIds.map((propertyId) => {
    filterProperties = filterProperties.concat(`&property_id_for_assigned_market=${propertyId}`)
  })
  return useRequest({
    path: `/markets?${filterProperties.slice(1)}`,
    useCache: true,
    translated: true,
    keys: ['markets', `propertyIds=${propertyIds}`],
    options: options,
  })
}

export default usePropertyIdForAssignedMarket
