import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import CardSection from 'components/domains/deals/card/CardSection'
import Card from 'components/ui/card/Card'
import CtrlClickableCardHeader from 'components/ui/card/CtrlClickableCardHeader'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import useCreateNavigateClickHandler from 'components/ui/link/useCreateNavigateClickHandler'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import useChangeCardOverviewTableData from 'hooks/services/deals/change-overview/useChangeCardOverviewTableData'
import { DealContext } from 'routes/deals/DealContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const DealChangeOverviewCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.change-overview',
  })
  const { t: tNoPrefix } = useTranslation()

  // navigation links
  const createClickHandler = useCreateNavigateClickHandler()
  const { deal: { dealUuid, displayId } = {} } = useContext(DealContext)
  const changeOverviewBasePath = `/${paths.deals}/${displayId}/${dealDetailPaths.changeOverview}`
  const onHeaderClick = createClickHandler(changeOverviewBasePath)

  const columnDefinitions = [
    {
      title: t('changed-page-info'),
      columnKey: 'change',
    },
    {
      title: t('changed-at'),
      columnKey: 'changed-at',
    },
    {
      title: t('changed-by'),
      columnKey: 'changed-by',
    },
  ]

  const {
    isLoading,
    isError,
    data: rows,
  } = useChangeCardOverviewTableData({
    dealUuid,
  })

  return (
    <Card
      header={
        <CtrlClickableCardHeader titleText={t('title')} onClick={onHeaderClick} interactive />
      }
    >
      <RequestStateResolver
        isLoading={isLoading}
        isError={isError}
        render
        renderContent={() =>
          rows?.length > 0 ? (
            <>
              <CardSection>
                <SortedTable
                  tableData={rows}
                  columnDefinitions={columnDefinitions}
                  noDataText={tNoPrefix('components.ui.card.empty-card.title')}
                />
              </CardSection>
            </>
          ) : (
            <EmptyCardContent />
          )
        }
      />
    </Card>
  )
}

DealChangeOverviewCard.propTypes = {}
export default DealChangeOverviewCard
