import { cloneElement, useCallback, useContext, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import CreateDealWorkingVersionButton from 'components/domains/deals/deal-adjustment/create/CreateDealWorkingVersionButton'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import ChineseWallToggle from 'components/domains/deals/overview/ChineseWallToggle'
import ConfigurableComponentsPage from 'components/ui/configurable-components-page/ConfigurableComponentsPage'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { availableDynamicPagesEnum } from 'hooks/page-config/useGetPageConfig'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import useAllowedDealAdjustmentWorkingVersion, {
  DealAdjustmentAllowedOperations,
} from 'hooks/services/deals/deal-adjustment/useAllowedDealAdjustmentWorkingVersion'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'

const DealOverviewDynamic = () => {
  const dealContext = useContext(DealContext)

  const {
    deal: { dealUuid, workingVersion },
    allowedOperations,
  } = dealContext

  const location = useLocation()
  const navigate = useNavigate()
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()

  const isAllowedToUpdateDeal = allowedOperations.includes(dealsPermissions.updateDeal)

  const isAllowedToUpdateDealMilestones = allowedOperations.includes(
    dealsPermissions.updateDealMilestones,
  )
  const isAllowedToUpdateDealStatus = allowedOperations.includes(dealsPermissions.updateDealStatus)

  const { data: allowedEventsOperationsData } = useEventsAllowedOperations()

  const displayCreateEventButton = !!allowedEventsOperationsData?.allowed_operations.includes(
    businessEventsAllowedOperations.createEvent,
  )

  const {
    isLoadingAllowed,
    data: { allowedOperations: dealAdjustmentAllowedOperations = [] } = {},
  } = useAllowedDealAdjustmentWorkingVersion(dealUuid)
  const isCreateDealAdjustmentWorkingVersionAllowed = useMemo(
    () =>
      !isLoadingAllowed &&
      dealAdjustmentAllowedOperations.includes(DealAdjustmentAllowedOperations.CREATE),
    [dealAdjustmentAllowedOperations, isLoadingAllowed],
  )

  const isTabLocation = useCallback(
    (tabLocation) => tabLocation === location.hash.substring(1),
    [location.hash],
  )

  useEffect(() => {
    if (location?.pathname.slice(-1) !== '/') {
      navigate({ ...location, pathname: `${location.pathname}/` }, { replace: true })
    }
  }, [location, navigate])

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const isWorkingVersion = useMemo(
    () => workingVersion === WorkingVersionType.WORKING_VERSION,
    [workingVersion],
  )

  const headerActions = useMemo(() => {
    const hasCWToggle = isTabLocation('manager-tab') && !isWorkingVersion

    const leftActions = [toggleWorkingVersionButton, manageWorkingVersionButton]
      .filter(Boolean)
      .map((action, index) => cloneElement(action, { key: `left-action-${index}` }))

    const centerActions = [
      hasCWToggle && (
        <ChineseWallToggle
          key="chinese-wall-toggle"
          dealUuid={dealUuid}
          isAllowedToUpdateDeal={isAllowedToUpdateDeal}
        />
      ),
    ].filter(Boolean)

    const rightActions = [
      <CreateDealEventAction key="create-event-action" />,
      isCreateDealAdjustmentWorkingVersionAllowed && (
        <CreateDealWorkingVersionButton key="create-deal-working-version" />
      ),
      markFavoriteAction,
    ].filter(Boolean)

    return createSegmentedHeaderActions(leftActions, centerActions, rightActions)
  }, [
    dealUuid,
    isCreateDealAdjustmentWorkingVersionAllowed,
    isAllowedToUpdateDeal,
    isTabLocation,
    isWorkingVersion,
    manageWorkingVersionButton,
    markFavoriteAction,
    toggleWorkingVersionButton,
  ])

  // default selected chart values defined in https://fioneer.atlassian.net/browse/CWP-2909
  const PRESELECTED_KPI_CHART = 'DSC_STD'

  return (
    <ConfigurableComponentsPage
      pageData={{
        ...dealContext,
        defaultKpiCode: PRESELECTED_KPI_CHART,
        isAllowedToUpdateDealMilestones,
        isAllowedToUpdateDealStatus,
        displayCreateEventButton,
      }}
      pageCode={availableDynamicPagesEnum.DealsOverview}
      renderContent={({ children, pageConfig }) => (
        <DealDetailsWrapper
          isTabPage
          actions={headerActions}
          additionalProperties={{ includeUrlQuery: true, defaultSectionId: pageConfig.defaultTab }}
        >
          {children}
        </DealDetailsWrapper>
      )}
    />
  )
}

export default DealOverviewDynamic
