import { Button, ButtonDesign, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import OnHoldToggle from 'components/domains/deals/overview/OnHoldToggle'
import ChangeStatusDialog from 'components/domains/deals/overview/status-history/ChangeStatusDialog'
import styles from 'components/domains/deals/overview/status-history/StatusHistory.module.css'
import CardWithDisplayAndEditTable from 'components/ui/tables/display-and-edit-table/CardWithDisplayAndEditTable'
import TextEllipsis from 'components/ui/text/TextEllipsis'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useDealStatusHistory from 'hooks/services/deals/status-history/useDealStatusHistory'

const StatusHistory = ({ pageData }) => {
  const {
    deal: { dealUuid, displayId, status },
    isAllowedToUpdateDealStatus: isAllowedToEdit,
  } = pageData

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.status-history',
  })

  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })

  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false)

  const {
    data: { history = [] } = {},
    isLoading: isStatusHistoryLoading,
    isError: isStatusHistoryError,
  } = useDealStatusHistory({ dealUuid: dealUuid })

  const { lastEditedText } = useLastEditedTextByEmail({
    email: history[0]?.createdBy,
    timestamp: history[0]?.createdAt,
  })

  const handleOnShowChangeStatusDialog = () => {
    setShowChangeStatusDialog(true)
  }

  const handleOnCloseChangeStatusDialog = () => {
    setShowChangeStatusDialog(false)
  }

  const tableData = history.map(({ createdAt, status, comment }) => ({
    rowKey: `${dealUuid} ${createdAt}`,
    status: {
      cellContentReadMode: <Label>{status}</Label>,
    },
    date: {
      cellContentReadMode: <Label>{formatDateAndTime(createdAt)}</Label>,
    },
    comment: {
      cellContentReadMode: <TextEllipsis content={comment} maxShortenedContentDisplaySize={100} />,
      cellReadModeProps: {
        className: styles.commentColumen,
      },
    },
  }))

  const columnDefinitions = [
    {
      columnKey: 'status',
      title: t('status'),
    },
    {
      columnKey: 'date',
      title: t('date'),
      style: styles.tableHeader,
    },
    {
      columnKey: 'comment',
      title: t('comment'),
    },
  ]

  return (
    <>
      <CardWithDisplayAndEditTable
        newRow={{}}
        handleDeleteRow={() => {}}
        columnDefinitions={columnDefinitions}
        tableData={tableData}
        cardTitle={t('title')}
        subTitle={lastEditedText}
        isLoading={isStatusHistoryLoading}
        handleCancelEditRow={() => {}}
        isError={tableData.length === 0 && isStatusHistoryError}
        handleSaveRow={() => {}}
        customActionElements={[
          <OnHoldToggle
            key="on-hold-btn"
            dealUuid={dealUuid}
            dealDisplayId={displayId}
            isAllowedToEdit={isAllowedToEdit}
          />,
          isAllowedToEdit && (
            <Button
              key={'change-status-btn'}
              design={ButtonDesign.Transparent}
              onClick={() => handleOnShowChangeStatusDialog()}
            >
              {t('change-status')}
            </Button>
          ),
        ]}
        additionalTableStyles={styles.table}
      />
      {createPortal(
        <ChangeStatusDialog
          dealUuid={dealUuid}
          dealStatus={status}
          onCloseChangeStatusDialog={handleOnCloseChangeStatusDialog}
          showChangeStatusDialog={showChangeStatusDialog}
        />,
        document.body,
      )}
    </>
  )
}

StatusHistory.propTypes = {
  isAllowedToEdit: PropTypes.bool,
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string,
      displayId: PropTypes.string,
      status: PropTypes.string,
    }),
  }),
}

export default StatusHistory
