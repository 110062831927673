import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useBorrowerUnitsLoader from 'components/domains/deals/borrower-units/useBorrowerUnitsLoader'
import ReferencePopover from 'components/domains/deals/covenants/covenants-table/ReferencePopover'
import styles from 'components/domains/deals/overview/useCounterpartyFields.module.css'
import Entity from 'components/ui/data/Entity'
import paths from 'routes/paths'

const useCounterpartyFields = (borrowerBpId) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.overview.general-information.counterparty',
  })

  const {
    data: { units = [], borrowerBp } = {},
    isLoading,
    isError,
  } = useBorrowerUnitsLoader(borrowerBpId)

  const mapUnitHeadsForReferencePopover = (heads) =>
    heads?.map((head) => ({ id: head.id, name: head.fullName }))

  const unitFields = useMemo(() => {
    const getFieldValue = (unitName, heads) =>
      heads?.length === 1 ? (
        <Entity
          className={styles.entity}
          id={heads[0].id}
          name={heads[0].fullName}
          link={`/${paths.businessPartners}/${heads[0].id}`}
          openInNewTab
        />
      ) : (
        <ReferencePopover
          linkText={unitName}
          listItems={mapUnitHeadsForReferencePopover(heads)}
          linkBasePath={`/${paths.businessPartners}`}
          errorText="error"
        />
      )
    return units.reduce((fields, { id, name, heads }) => {
      if (heads?.length) {
        fields.push({
          label: name,
          name: `${id}-${heads[0].id}`,
          value: getFieldValue(name, heads),
        })
      }
      return fields
    }, [])
  }, [units])

  const counterPartyFields = [
    {
      label: t('borrower'),
      name: 'borrower',
      value: (
        <Entity
          className={styles.entity}
          id={borrowerBp.id}
          name={borrowerBp.fullName}
          link={`/${paths.businessPartners}/${borrowerBpId}`}
          openInNewTab
        />
      ),
    },
    ...unitFields,
  ]

  return { data: counterPartyFields, isLoading, isError }
}

export default useCounterpartyFields
