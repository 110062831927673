import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'

export const useOverviewBusinessEventsDisplayHook = () => {
  const {
    data: allowedEventsOperationsData,
    isLoading: allowedEventsOperationsIsLoading,
    isError: allowedEventsOperationsIsError,
  } = useEventsAllowedOperations()

  const eventReadAllowed = !!allowedEventsOperationsData?.allowed_operations?.includes(
    businessEventsAllowedOperations.readEvent,
  )

  return {
    isLoading: false,
    renderComponent:
      !allowedEventsOperationsIsLoading && !allowedEventsOperationsIsError && eventReadAllowed,
  }
}
