import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useOnHold = (dealUuid) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/on-hold`,
      useCache: true,
      keys: ['deals', dealUuid, 'on-hold'],
      options: { enabled: !!dealUuid },
    }),
  )

export default useOnHold
