import { useContext } from 'react'

import { useTranslation } from 'react-i18next'
import CardSection from 'components/domains/deals/card/CardSection'
import { useGetAndAppendWorkingVersionSearchParam } from 'components/domains/deals/useGetAndAppendWorkingVersionSearchParam'
import Card from 'components/ui/card/Card'
import CtrlClickableCardHeader from 'components/ui/card/CtrlClickableCardHeader'
import LabelValueList from 'components/ui/data/LabelValueList'
import useCreateNavigateClickHandler from 'components/ui/link/useCreateNavigateClickHandler'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import useAggregatedCollateralAgreements from 'hooks/services/collaterals/useAggregatedCollateralAgreements'
import { DealContext } from 'routes/deals/DealContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const DealOverviewCollateralsCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.collaterals',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const createClickHandler = useCreateNavigateClickHandler()
  const { appendWorkingVersionSearchParam } = useGetAndAppendWorkingVersionSearchParam()
  const { deal: { dealUuid, displayId } = {} } = useContext(DealContext)

  const formatCurrency = useCustomizableCurrencyFormatter()

  const { aggregatedCollateralAgreements, isLoading, isError } = useAggregatedCollateralAgreements({
    dealUuid,
  })

  const collateralsBasePath = `/${paths.deals}/${displayId}/${dealDetailPaths.collaterals}`
  const onHeaderClick = createClickHandler(appendWorkingVersionSearchParam(collateralsBasePath))

  const columnDefinitions = [
    {
      title: t('cag-cluster'),
      columnKey: 'cluster',
    },
    {
      title: t('cag-value'),
      columnKey: 'value',
    },
  ]

  const dataWithValue = !aggregatedCollateralAgreements
    ? []
    : Object.keys(aggregatedCollateralAgreements?.cagClusters)
        .filter(
          (clusterId) =>
            aggregatedCollateralAgreements?.cagClusters[clusterId]
              ?.cagAggregatedValueInHeadQuarterCurrency.value >= 1,
        )
        .map((clusterId) => {
          const cluster = aggregatedCollateralAgreements.cagClusters[clusterId]

          return {
            rowKey: clusterId,
            cluster: {
              cellComponent: cluster.cagCluster.name,
            },
            value: {
              cellComponent: formatCurrency(
                cluster?.cagAggregatedValueInHeadQuarterCurrency.value,
                cluster?.cagAggregatedValueInHeadQuarterCurrency.currency,
              ),
            },
          }
        })

  const dataWithoutValue = !aggregatedCollateralAgreements
    ? []
    : Object.keys(aggregatedCollateralAgreements?.cagClusters)
        .filter(
          (clusterId) =>
            aggregatedCollateralAgreements.cagClusters[clusterId]
              .cagAggregatedValueInHeadQuarterCurrency.value < 1,
        )
        .map((clusterId) => {
          const cluster = aggregatedCollateralAgreements.cagClusters[clusterId]

          return {
            rowKey: clusterId,
            label: cluster.cagCluster.name,
            value: Object.keys(cluster.cagTypes)
              .reduce(
                (count, cagTypeId) => cluster.cagTypes[cagTypeId].agreements.length + count,
                0,
              )
              .toString(),
          }
        })

  return (
    <Card
      header={
        <CtrlClickableCardHeader titleText={t('title')} onClick={onHeaderClick} interactive />
      }
    >
      <CardSection>
        <LoadingStateWrapper isLoading={isLoading} isError={isError}>
          <SortedTable
            tableData={dataWithValue}
            columnDefinitions={columnDefinitions}
            noDataText={tNoPrefix('components.ui.card.empty-card.title')}
          />
        </LoadingStateWrapper>
      </CardSection>
      {dataWithoutValue.length > 0 && (
        <CardSection>
          <LabelValueList rows={dataWithoutValue} />
        </CardSection>
      )}
    </Card>
  )
}

export default DealOverviewCollateralsCard
