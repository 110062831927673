import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import useCreateMilestone from 'hooks/services/deals/milestones/useCreateMilestone'
import useDeleteMilestone from 'hooks/services/deals/milestones/useDeleteMilestone'
import useUpdateMilestone from 'hooks/services/deals/milestones/useUpdateMilestone'

const useHandleCrudMilestone = ({ dealUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.milestones',
  })
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()

  // create
  const milestoneCreationSuccess = () => {
    queryClient.invalidateQueries(['deals', dealUuid, 'milestones'])
    showToast({ children: t('add-success') }, document.body)
  }
  const milestoneCreationError = () => {
    showToast({ children: t('add-error') }, document.body)
  }
  const createMilestone = useCreateMilestone({
    onSuccess: milestoneCreationSuccess,
    onError: milestoneCreationError,
  })
  const createAction = (values) => createMilestone.mutateAsync(values)

  // update
  const milestoneUpdateSuccess = () => {
    queryClient.invalidateQueries(['deals', dealUuid, 'milestones'])
    showToast({ children: t('update-success') }, document.body)
  }
  const milestoneUpdateError = () => {
    showToast({ children: t('update-error') }, document.body)
  }
  const updateMilestone = useUpdateMilestone({
    onSuccess: milestoneUpdateSuccess,
    onError: milestoneUpdateError,
  })
  const updateAction = (values) => updateMilestone.mutateAsync(values)

  // delete
  const milestoneDeleteSuccess = () => {
    queryClient.invalidateQueries(['deals', dealUuid, 'milestones'])
    showToast({ children: t('delete-success') }, document.body)
  }
  const milestoneDeleteError = () => {
    showToast({ children: t('delete-error') }, document.body)
  }
  const deleteMilestone = useDeleteMilestone({
    onSuccess: milestoneDeleteSuccess,
    onError: milestoneDeleteError,
  })
  const deleteAction = (milestoneUuid) =>
    deleteMilestone.mutateAsync({ dealUuid: dealUuid, milestoneId: milestoneUuid })

  return {
    updateAction,
    createAction,
    deleteAction,
  }
}

export default useHandleCrudMilestone
