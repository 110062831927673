import {
  Button,
  ButtonDesign,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useSendConflictClearanceEmail from 'hooks/services/deals/conflict-clearance/useSendConflictClearanceEmail'

const resendConflictClearanceButtonProps = {
  dealUuid: PropTypes.string.isRequired,
}

const ResendConflictClearanceButton = ({ dealUuid, ...props }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.internal-team-members.resend-conflict-clearance',
  })
  const { t: tNoPrefix } = useTranslation()
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()

  const showResendEmailSuccess = () => {
    showToast({ children: t('success.text') })
  }

  const showResendEmailError = () => {
    showMessageBox({
      children: t('error.text'),
      actions: [MessageBoxActions.Close],
    })
  }

  const { mutate } = useSendConflictClearanceEmail({
    onSuccess: showResendEmailSuccess,
    onError: showResendEmailError,
  })

  const sendEmail = () => {
    mutate({ dealUuid })
  }
  const openConfirmationModal = () => {
    showMessageBox({
      children: t('confirmation.text'),
      type: MessageBoxTypes.Confirm,
      actions: [
        <Button key="confirm" design={ButtonDesign.Emphasized} onClick={sendEmail}>
          {tNoPrefix('buttons.confirm')}
        </Button>,
        MessageBoxActions.Cancel,
      ],
    })
  }

  return (
    <Button design={ButtonDesign.Transparent} {...props} onClick={openConfirmationModal}>
      {t('button')}
    </Button>
  )
}

ResendConflictClearanceButton.propTypes = resendConflictClearanceButtonProps

export default ResendConflictClearanceButton
