import {
  CASH_FLOW_FILTER,
  CONDITIONS_FILTER,
  COVENANTS_FILTER,
  DOCUMENTS_FILTER,
  FINANCING_FILTER,
  GENERAL_INFORMATION_FILTER,
  PROPERTIES_FILTER,
  SYNDICATION_FILTER,
  convertFilter,
} from 'components/domains/deals/change-overview/change-overview-card/constants'
import useChangeLogs from 'hooks/services/traceability-of-changes/useChangeLogs'

const useLatestChangeOverviewData = ({ dealUuid }) => {
  const sort = { field: 'triggered_at', direction: 'DESC' }

  const pagination = {
    limit: 1,
    offset: 0,
  }

  const {
    isLoading: isLoadingGeneralInformation,
    isError: isErrorGeneralInformation,
    data: generalInformationChangeData,
  } = useChangeLogs({
    dealUuid,
    filter: convertFilter(GENERAL_INFORMATION_FILTER),
    sort: sort,
    pagination: pagination,
  })
  const latestGeneralInformationChange = generalInformationChangeData?.changeLogs?.[0]

  const {
    isLoading: isLoadingFinancingChange,
    isError: isErrorFinancingChange,
    data: financingChangeData,
  } = useChangeLogs({
    dealUuid: dealUuid,
    filter: convertFilter(FINANCING_FILTER),
    sort: sort,
    pagination: pagination,
  })
  const latestFinancingChange = financingChangeData?.changeLogs?.[0]

  const {
    isLoading: isLoadingPropertiesChange,
    isError: isErrorPropertiesChange,
    data: propertiesChangeData,
  } = useChangeLogs({
    dealUuid: dealUuid,
    filter: convertFilter(PROPERTIES_FILTER),
    sort: sort,
    pagination: pagination,
  })
  const latestPropertiesChange = propertiesChangeData?.changeLogs?.[0]

  const {
    isLoading: isLoadingCashFlowChange,
    isError: isErrorCashFlowChange,
    data: cashFlowChangeData,
  } = useChangeLogs({
    dealUuid: dealUuid,
    filter: convertFilter(CASH_FLOW_FILTER),
    sort: sort,
    pagination: pagination,
  })
  const latestCashFlowChange = cashFlowChangeData?.changeLogs?.[0]

  const {
    isLoading: isLoadingConditionsChange,
    isError: isErrorConditionsChange,
    data: conditionsChangeData,
  } = useChangeLogs({
    dealUuid: dealUuid,
    filter: convertFilter(CONDITIONS_FILTER),
    sort: sort,
    pagination: pagination,
  })
  const latestConditionsChange = conditionsChangeData?.changeLogs?.[0]

  const {
    isLoading: isLoadingCovenantsChange,
    isError: isErrorCovenantsChange,
    data: covenantsChangeData,
  } = useChangeLogs({
    dealUuid: dealUuid,
    filter: convertFilter(COVENANTS_FILTER),
    sort: sort,
    pagination: pagination,
  })
  const latestCovenantsChange = covenantsChangeData?.changeLogs?.[0]

  const {
    isLoading: isLoadingSyndicationChange,
    isError: isErrorSyndicationChange,
    data: syndicationChangeData,
  } = useChangeLogs({
    dealUuid: dealUuid,
    filter: convertFilter(SYNDICATION_FILTER),
    sort: sort,
    pagination: pagination,
  })
  const latestSyndicationChange = syndicationChangeData?.changeLogs?.[0]

  const {
    isLoading: isLoadingDocumentsChange,
    isError: isErrorDocumentsChange,
    data: documentsChangeData,
  } = useChangeLogs({
    dealUuid: dealUuid,
    filter: convertFilter(DOCUMENTS_FILTER),
    sort: sort,
    pagination: pagination,
  })
  const latestDocumentsChange = documentsChangeData?.changeLogs?.[0]

  const isLoading =
    isLoadingGeneralInformation ||
    isLoadingFinancingChange ||
    isLoadingPropertiesChange ||
    isLoadingCashFlowChange ||
    isLoadingConditionsChange ||
    isLoadingCovenantsChange ||
    isLoadingSyndicationChange ||
    isLoadingDocumentsChange

  const isError =
    isErrorGeneralInformation ||
    isErrorFinancingChange ||
    isErrorPropertiesChange ||
    isErrorCashFlowChange ||
    isErrorConditionsChange ||
    isErrorCovenantsChange ||
    isErrorSyndicationChange ||
    isErrorDocumentsChange

  const latestChanges = [
    latestGeneralInformationChange,
    latestFinancingChange,
    latestPropertiesChange,
    latestCashFlowChange,
    latestConditionsChange,
    latestCovenantsChange,
    latestSyndicationChange,
    latestDocumentsChange,
  ]

  return {
    isLoading: isLoading,
    isError: isError,
    data: latestChanges.filter((change) => !!change),
  }
}

export default useLatestChangeOverviewData
