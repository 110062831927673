import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import '@ui5/webcomponents-fiori/dist/illustrations/NoTasks'
import '@ui5/webcomponents-fiori/dist/illustrations/sapIllus-Spot-UnableToLoad.js'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import {
  propertyMonitoringPermissions,
  propertyMonitoringStatus,
} from 'api/property/monitoring/propertyMonitoringConfiguration'
import CreatePropertyEventAction from 'components/domains/properties/header-actions/CreatePropertyEventAction'
import PropertyMonitoringExportLogDialog from 'components/domains/properties/monitoring/PropertyMonitoringExportLogDialog'
import PropertyMonitoringSettingsDialogLoadingWrapper from 'components/domains/properties/monitoring/settings/PropertyMonitoringSettingsDialogLoadingWrapper'
import ConfigurableComponentsPage from 'components/ui/configurable-components-page/ConfigurableComponentsPage'
import ErrorDialog from 'components/ui/dialog/ErrorDialog'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { availableDynamicPagesEnum } from 'hooks/page-config/useGetPageConfig'
import usePropertyIdForAssignedMarket from 'hooks/services/markets/usePropertyIdForAssignedMarket'
import { usePropertyMonitoring } from 'hooks/services/properties/monitoring/usePropertyMonitoring'
import usePropertyMonitoringPermissions from 'hooks/services/properties/monitoring/usePropertyMonitoringPermissions'
import useUpdatePropertyMonitoring from 'hooks/services/properties/monitoring/useUpdatePropertyMonitoring'
import PropertyPage from 'routes/properties/PropertyPage'

const PropertyMonitoringDynamic = ({
  propertyDescription,
  propertyId,
  propertyUuid,
  breadcrumbs,
  status,
  additionalStatuses,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property-monitoring' })
  const { t: tCommon } = useTranslation()
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false)
  const [isExportLogDialogOpen, setIsExportLogDialogOpen] = useState(false)

  const { isError: isPropertyMonitoringError, data: propertyMonitoringData = {} } =
    usePropertyMonitoring(propertyUuid)

  const { data: permissions } = usePropertyMonitoringPermissions(propertyUuid)

  const { data: assignedMarketData } = usePropertyIdForAssignedMarket([propertyUuid])

  const assignedMarket = assignedMarketData?.markets?.[0] ?? {}

  const queryClient = useQueryClient()

  const updatePropertyMonitoring = useUpdatePropertyMonitoring({
    onSuccess: () => queryClient.invalidateQueries(['property-monitoring']),
    onError: () => setIsErrorDialogOpen(true),
  })

  const handleOnResumeMonitoring = () => {
    updatePropertyMonitoring.mutate({
      monitoringPropertyId: propertyMonitoringData.id,
      monitoringStatus: propertyMonitoringStatus.activeAutomated,
      baselineDate: propertyMonitoringData.baseline_date,
    })
    setIsErrorDialogOpen(false)
  }

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="property-details-user-favorite-icon"
        entityId={propertyUuid}
        entityType={cwpEntityTypes.PROPERTY}
      />
    ),
    [propertyUuid],
  )

  const eventCreationButton = <CreatePropertyEventAction key="create-event-action" />

  const propertyMonitoringSettingsButton = (
    <Button
      design={ButtonDesign.Default}
      id="properties-monitoring-settings-button"
      key="monitoring-settings"
      onClick={() => setIsSettingsDialogOpen(true)}
    >
      {t('buttons.settings')}
    </Button>
  )

  const exportLogButton = (
    <Button
      design={ButtonDesign.Default}
      onClick={() => {
        setIsExportLogDialogOpen(true)
      }}
      key="export-log"
    >
      {t('buttons.export-log')}
    </Button>
  )

  const showPropertyMonitoringSettingsButton = useMemo(
    () => permissions && permissions.includes(propertyMonitoringPermissions.editSettings),
    [permissions],
  )
  const showPropertyMonitoringExportLogButton = useMemo(
    () => permissions && permissions.includes(propertyMonitoringPermissions.exportLog),
    [permissions],
  )

  const subtitle = <EntityTypeAndIdWithClipboard id={propertyId} />

  return (
    <ConfigurableComponentsPage
      pageData={{
        marketId: assignedMarket.id,
        marketName: assignedMarket.info?.name,
        market: assignedMarket,
        monitoringStatus: propertyMonitoringData.monitoring_status,
        proxySettings: propertyMonitoringData.proxy_settings,
        baselineDate: propertyMonitoringData.baseline_date,
        monitoringDate: propertyMonitoringData.monitoring_date,
        nextManualMonitoringDate: propertyMonitoringData.next_manual_monitoring_date,
        handleOnResumeMonitoring,
        monitoringPermissions: permissions,
        propertyUuid,
        macroProxies: !isNil(assignedMarket.id) ? propertyMonitoringData.macro_proxies : {},
        microProxies: propertyMonitoringData.micro_proxies,
        hasNoMonitoringData: isPropertyMonitoringError,
      }}
      pageCode={availableDynamicPagesEnum.PropertiesMonitoring}
      renderContent={({ children }) => (
        <PropertyPage
          pageTitle={propertyDescription}
          id={propertyId}
          subtitle={subtitle}
          breadcrumbs={breadcrumbs}
          actions={
            <>
              {createSegmentedHeaderActions(
                [showPropertyMonitoringSettingsButton && propertyMonitoringSettingsButton],
                [
                  eventCreationButton,
                  showPropertyMonitoringExportLogButton && exportLogButton,
                  markFavoriteAction,
                ],
              )}
            </>
          }
          status={status}
          additionalStatuses={additionalStatuses}
        >
          {children}
          {showPropertyMonitoringSettingsButton && (
            <PropertyMonitoringSettingsDialogLoadingWrapper
              propertyUuid={propertyUuid}
              setIsDialogOpen={setIsSettingsDialogOpen}
              isDialogOpen={isSettingsDialogOpen}
              monitoringPermissions={permissions}
              isPropertyAssignedToMarket={!isNil(assignedMarket?.id)}
            />
          )}
          {showPropertyMonitoringExportLogButton &&
            createPortal(
              <PropertyMonitoringExportLogDialog
                open={isExportLogDialogOpen}
                setIsOpen={setIsExportLogDialogOpen}
                propertyUuid={propertyUuid}
              />,
              document.body,
            )}
          <ErrorDialog
            isOpen={isErrorDialogOpen}
            setIsOpen={setIsErrorDialogOpen}
            texts={{
              retryButton: tCommon('buttons.try-again'),
              cancelButton: tCommon('buttons.cancel'),
              title: t('error.title'),
              description: t('error.description'),
            }}
            onRetry={handleOnResumeMonitoring}
          />
        </PropertyPage>
      )}
    />
  )
}

PropertyMonitoringDynamic.propTypes = {
  propertyDescription: PropTypes.string,
  propertyId: PropTypes.string.isRequired,
  propertyUuid: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ).isRequired,
  status: PropTypes.shape({
    text: PropTypes.string.isRequired,
    valueState: PropTypes.string,
  }).isRequired,
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string.isRequired,
      valueState: PropTypes.string,
    }),
  ),
}

export default PropertyMonitoringDynamic
