import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateDealTeamMember = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, dealTeamMember }) =>
      await post({
        path: `/deals/${dealUuid}/deal-teams`,
        body: snakecaseKeys(dealTeamMember),
      }),
    { onSuccess, onError },
  )
}

export default useCreateDealTeamMember
