import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDealStatusHistory = ({ dealUuid, options = {} }) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/status-history`,
      useCache: true,
      keys: ['deals', dealUuid, 'status-history'],
      options,
    }),
  )

export default useDealStatusHistory
