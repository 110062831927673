import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDealRelations = (options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/configurations/f4-relations`,
      useCache: true,
      translated: true,
      keys: ['deals', 'f4-relations'],
      options: {
        ...options,
      },
    }),
  )

export default useDealRelations
