import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Label,
  Text,
  Table,
  TableCell,
  TableRow,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import CreateEditMilestoneDialog from 'components/domains/deals/overview/milestones/CreateEditMilestoneDialog'
import DeleteMilestoneDialog from 'components/domains/deals/overview/milestones/DeleteMilestoneDialog'
import tableStyles from 'components/domains/deals/overview/milestones/MilestonesTable.module.css'
import statusHistoryStyles from 'components/domains/deals/overview/status-history/StatusHistory.module.css'
import createColumns from 'components/ui/tables/sorted-tables/createColumns'
import DisplayAndEditTableToolbar from 'components/ui/tables/toolbar/DisplayAndEditTableToolbar'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { DealContext } from 'routes/deals/DealContext'

const MilestonesTable = ({ options, milestones, userIsAllowedToEdit, setIsAddDialogOpen }) => {
  const { deal: { dealUuid } = {} } = useContext(DealContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.milestones',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const { format: dateFormat } = useShortDateFormatter()
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [milestoneToBeEdited, setMilestoneToBeEdited] = useState({})
  const [milestoneIdToBeDeleted, setMilestoneIdToBeDeleted] = useState('')

  const findCategoryNameByCode = (code) =>
    options.find(({ categoryCode }) => categoryCode === code)?.categoryName ?? code

  const findMilestoneNameByCode = (typeCode) =>
    options
      .map((option) => option?.types)
      .flat(1)
      .find(({ code }) => code === typeCode)?.name ?? typeCode

  const handleEditClick = (milestone) => {
    setMilestoneToBeEdited(milestone)
    setIsEditDialogOpen(true)
  }

  const handleDeleteClick = (milestoneId) => {
    setMilestoneIdToBeDeleted(milestoneId)
    setIsDeleteDialogOpen(true)
  }

  const columnDefinitions = [
    {
      columnKey: 'milestone',
      title: t('milestone'),
    },
    {
      columnKey: 'category',
      title: t('category'),
    },
    {
      columnKey: 'targetDate',
      title: t('targetDate'),
      alignment: FlexBoxAlignItems.End,
    },
    { title: '', columnKey: 'actions', sortingDisabled: true, alignment: FlexBoxAlignItems.End },
  ]

  const tableData = milestones.map(
    ({ milestoneUuid, categoryCode, customTypeName, typeCode, targetDate }) => ({
      rowKey: milestoneUuid ? `milestone-${milestoneUuid}` : `milestone-${typeCode}`,
      milestone: {
        cellComponent: <Label>{customTypeName ?? findMilestoneNameByCode(typeCode)}</Label>,
      },
      category: {
        cellComponent: <Label>{findCategoryNameByCode(categoryCode)}</Label>,
      },
      targetDate: {
        cellComponent: <Text>{dateFormat(targetDate)}</Text>,
      },
      actions: {
        cellComponent: userIsAllowedToEdit && milestoneUuid && (
          <FlexBox justifyContent={FlexBoxJustifyContent.End}>
            <Button
              id="edit-btn"
              design={ButtonDesign.Transparent}
              icon="edit"
              onClick={() =>
                handleEditClick({
                  milestoneUuid,
                  categoryCode,
                  customTypeName,
                  typeCode,
                  targetDate,
                })
              }
            />
            <Button
              id="delete-btn"
              design={ButtonDesign.Transparent}
              icon="delete"
              onClick={() => handleDeleteClick(milestoneUuid)}
            />
          </FlexBox>
        ),
      },
    }),
  )

  const alignContent = (content, alignment) =>
    alignment ? <FlexBox justifyContent={alignment}>{content}</FlexBox> : content

  const renderCells = (tableRow) =>
    columnDefinitions.map((colDef) => (
      <TableCell
        key={tableRow.rowKey + '-' + colDef.columnKey}
        {...tableRow[colDef.columnKey].cellReadModeProps}
      >
        {alignContent(tableRow[colDef.columnKey].cellComponent, colDef.alignment)}
      </TableCell>
    ))

  const mapTableRows = () =>
    tableData.map((tableRow) => <TableRow key={tableRow.rowKey}>{renderCells(tableRow)}</TableRow>)

  return (
    <>
      <DisplayAndEditTableToolbar
        title={t('title')}
        nrOfEntries={milestones.length}
        className={tableStyles.additionalCardHeaderToolbar}
        toolbarActions={
          userIsAllowedToEdit
            ? [
                <Button
                  key="addButton"
                  id="add-btn"
                  design={ButtonDesign.Transparent}
                  onClick={() => setIsAddDialogOpen(true)}
                >
                  {tNoPrefix('buttons.add')}
                </Button>,
              ]
            : []
        }
      />
      <FlexBox fitContainer>
        <Table
          columns={createColumns(columnDefinitions)}
          className={`${tableStyles.table} ${statusHistoryStyles.table}`}
          noDataText={tNoPrefix('components.table.no-data')}
        >
          {mapTableRows()}
        </Table>
      </FlexBox>
      <CreateEditMilestoneDialog
        isOpen={isEditDialogOpen}
        setIsOpen={setIsEditDialogOpen}
        prefillValues={milestoneToBeEdited}
        isEdit={true}
      />
      {createPortal(
        <DeleteMilestoneDialog
          dealUuid={dealUuid}
          setIsOpen={setIsDeleteDialogOpen}
          milestoneUuid={milestoneIdToBeDeleted}
          isOpen={isDeleteDialogOpen}
        />,
        document.body,
      )}
    </>
  )
}

MilestonesTable.propTypes = {
  options: PropTypes.array.isRequired,
  milestones: PropTypes.array.isRequired,
  userIsAllowedToEdit: PropTypes.bool.isRequired,
  setIsAddDialogOpen: PropTypes.func.isRequired,
}

export default MilestonesTable
