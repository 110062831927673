import { registerCards as registerCwpCustomCards } from '@fioneer/cl-cwp-pbb-frontend-custom'
import { useEffect } from 'react'
import DealChangeOverviewCard from 'components/domains/deals/change-overview/change-overview-card/DealChangeOverviewCard'
import CovenantCard from 'components/domains/deals/covenants/covenant-card/CovenantCard'
import FinancingCard from 'components/domains/deals/financing/financing-card/FinancingCard'
import DealOverviewInformationCard from 'components/domains/deals/overview/DealOverviewInformationCard'
import BusinessEventsSummaryWrapper from 'components/domains/deals/overview/business-events-summary/BusinessEventsSummaryWrapper'
import DealOverviewCashflowMainScenarioChartCard from 'components/domains/deals/overview/cashflow/DealOverviewCashflowMainScenarioChartCard'
import DealOverviewCollateralsCard from 'components/domains/deals/overview/collaterals/DealOverviewCollateralsCard'
import DealOverviewConditionsCard from 'components/domains/deals/overview/conditions/DealOverviewConditionsCard'
import ExternalTeamMembers from 'components/domains/deals/overview/external-team-members/ExternalTeamMembers'
import InternalTeamMembers from 'components/domains/deals/overview/internal-team-members/InternalTeamMembers'
import MilestonesCard from 'components/domains/deals/overview/milestones/MilestonesCard'
import DealPropertiesOverviewInformationCard from 'components/domains/deals/overview/properties/DealPropertiesOverviewInformationCard'
import StatusHistory from 'components/domains/deals/overview/status-history/StatusHistory'
import DealOverviewSyndicationCard from 'components/domains/deals/overview/syndication/DealOverviewSyndicationCard'
import { useGetComponentRegistry } from 'components/ui/configurable-components-page/useComponentRegistry'
import i18next from 'i18n/setupI18n'
import { useOverviewBusinessEventsDisplayHook } from 'routes/deals/useDisplayHooks'

/**
 * @enum
 * @type {{DealOverviewGeneralInformation: string}}
 * @description Deal card codes
 * @readonly
 */
export const DealCardCodesEnum = {
  DealOverviewGeneralInformation: 'DEAL_GENERAL_INFORMATION',
  DealOverviewFinancing: 'DEAL_FINANCING',
  DealOverviewCashflow: 'DEAL_CASH_FLOW',
  DealOverviewConditions: 'DEAL_CONDITIONS',
  DealOverviewFinancedObjects: 'DEAL_FINANCED_OBJECTS',
  DealOverviewCollaterals: 'DEAL_COLLATERALS',
  DealOverviewSyndication: 'DEAL_SYNDICATION',
  DealOverviewCovenants: 'DEAL_COVENANTS',
  DealOverviewChangeOverview: 'DEAL_CHANGE_OVERVIEW',
  DealOverviewStatusHistory: 'DEAL_STATUS_HISTORY',
  DealOverviewMilestones: 'DEAL_MILESTONES',
  DealOverviewBusinessEvents: 'BUSINESS_EVENTS_EVENTS_SUMMARY',
  DealOverviewInternalTeamMembers: 'DEAL_INTERNAL_TEAM_MEMBERS',
  DealOverviewExternalTeamMembers: 'DEAL_EXTERNAL_TEAM_MEMBERS',
}

export const useDealDynamicCards = () => {
  const registry = useGetComponentRegistry()

  useEffect(() => {
    // register custom cards package
    registerCwpCustomCards(registry, i18next)

    // Overview
    registry.register(DealCardCodesEnum.DealOverviewGeneralInformation, {
      Component: DealOverviewInformationCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewFinancing, {
      Component: FinancingCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewCashflow, {
      Component: DealOverviewCashflowMainScenarioChartCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewConditions, {
      Component: DealOverviewConditionsCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewFinancedObjects, {
      Component: DealPropertiesOverviewInformationCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewCollaterals, {
      Component: DealOverviewCollateralsCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewSyndication, {
      Component: DealOverviewSyndicationCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewCovenants, {
      Component: CovenantCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewChangeOverview, {
      Component: DealChangeOverviewCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewStatusHistory, {
      Component: StatusHistory,
    })
    registry.register(DealCardCodesEnum.DealOverviewMilestones, {
      Component: MilestonesCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewBusinessEvents, {
      Component: BusinessEventsSummaryWrapper,
      componentDisplayHook: useOverviewBusinessEventsDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealOverviewInternalTeamMembers, {
      Component: InternalTeamMembers,
    })
    registry.register(DealCardCodesEnum.DealOverviewExternalTeamMembers, {
      Component: ExternalTeamMembers,
    })
  }, [registry])
}
