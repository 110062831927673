import PropTypes from 'prop-types'
import { entityTypes } from 'api/events/status'
import EventsSummaryCardWrapper from 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCardWrapper'

const BusinessEventsSummaryWrapper = ({ pageData }) => {
  const {
    deal: { dealUuid, displayId },
    displayCreateEventButton,
  } = pageData

  return (
    <EventsSummaryCardWrapper
      entity={{
        entityDisplayId: displayId,
        entityType: entityTypes.deal,
        entityName: 'propertyName',
        entityId: dealUuid,
      }}
      entityType={entityTypes.property}
      displayCreateEventButton={displayCreateEventButton}
    />
  )
}

BusinessEventsSummaryWrapper.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string.isRequired,
      displayId: PropTypes.string.isRequired,
    }),
    displayCreateEventButton: PropTypes.bool,
  }).isRequired,
}

export default BusinessEventsSummaryWrapper
