import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useTranches = ({ dealUuid, dataSource, options = {} }) => {
  const urlSearchParams = new URLSearchParams()

  if (dataSource) {
    urlSearchParams.append('dataSource', dataSource)
  }

  return useCamelizedResponse(
    useRequest({
      path: `/financing-structures/deals/${dealUuid}/tranches?${urlSearchParams.toString()}`,
      translated: true,
      keys: ['deals', dealUuid, 'tranches', 'dataSource', urlSearchParams.toString()],
      options: {
        enabled: !!dealUuid,
        ...options,
      },
    }),
  )
}

export default useTranches
