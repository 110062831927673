import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCashflowMainScenario = (dealUuid) => {
  const queryParams = new URLSearchParams({ main_scenario_only: true })
  return useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/cashflow-scenarios?${queryParams.toString()}`,
      translated: true,
      useCache: true,
      keys: ['deals', dealUuid, 'cashflow-scenarios', queryParams.toString()],
    }),
  )
}

export default useCashflowMainScenario
