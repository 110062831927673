import { LinkDesign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import DealChangeOverviewChangedInformationCell from 'components/domains/deals/change-overview/DealChangeOverviewChangedInformationCell'
import StaffMemberByObjectIdOrEmail from 'components/ui/staff-member/StaffMemberByObjectIdOrEmail'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import styles from 'hooks/services/deals/change-overview/useChangeCardOverviewTableData.module.css'
import useLatestChangeOverviewData from 'hooks/services/deals/change-overview/useLatestChangeOverviewData'

const useChangeCardOverviewTableData = ({ dealUuid }) => {
  const { isLoading, isError, data: latestChanges } = useLatestChangeOverviewData({ dealUuid })

  const { format: formatDate } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })

  const rows = useMemo(
    () =>
      latestChanges
        .filter((changeItem) => changeItem && typeof changeItem === 'object')
        .map((changeLog, index) => ({
          rowKey: `${index}`,
          rowProperties: {
            className: styles.defaultCursor,
          },
          change: {
            cellComponent: (
              <DealChangeOverviewChangedInformationCell
                changeLog={changeLog}
                renderPageLinkOnly={true}
                renderPagePrefix={false}
                linkDesign={LinkDesign.Emphasized}
              />
            ),
          },
          'changed-at': {
            cellComponent: formatDate(changeLog.triggeredAt),
          },
          'changed-by': {
            cellComponent: <StaffMemberByObjectIdOrEmail objectIdOrEmail={changeLog.triggeredBy} />,
          },
        })),
    [latestChanges, formatDate],
  )

  return {
    isLoading,
    isError,
    data: rows,
  }
}

export default useChangeCardOverviewTableData
