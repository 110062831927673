import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/financing/financing-card/FinancingValue.module.css'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'

const FinancingValue = ({
  summaryValue,
  summaryCurrency,
  headquarterValue,
  headquarterCurrency,
  id,
}) => {
  const { t } = useTranslation('translation')
  const formatCurrency = useCustomizableCurrencyFormatter()

  if (!summaryValue && !headquarterValue) {
    return <div id={id}>{t('components.deals.card-show-item.empty-value')}</div>
  }

  if (!summaryValue && headquarterValue) {
    return <div id={id}>{formatCurrency(headquarterValue, headquarterCurrency)}</div>
  }

  if (summaryCurrency === headquarterCurrency) {
    return <div id={id}>{formatCurrency(headquarterValue, headquarterCurrency)}</div>
  }

  return (
    <div id={id}>
      <Text>{formatCurrency(summaryValue, summaryCurrency)}</Text>

      <Text className={styles.headQuarterValue}>
        {formatCurrency(headquarterValue, headquarterCurrency)}
      </Text>
    </div>
  )
}

FinancingValue.propTypes = {
  id: PropTypes.string,
  summaryValue: PropTypes.number,
  summaryCurrency: PropTypes.string,
  headquarterValue: PropTypes.number,
  headquarterCurrency: PropTypes.string,
}

export default FinancingValue
