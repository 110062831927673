import {
  Input,
  Text,
  Label,
  ObjectStatus,
  Switch,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import uniq from 'lodash.uniq'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import { Contact } from 'components/domains/business-partners/tile/general-information/allowedOperations'
import ResendConflictClearanceButton from 'components/domains/deals/conflict-clearance/ResendConflictClearanceButton'
import styles from 'components/domains/deals/overview/internal-team-members/InternalTeamMembers.module.css'
import useCalculateLeaderFlags from 'components/domains/deals/overview/internal-team-members/useCalculateLeaderFlags'
import useHandleExternalCommunication from 'components/domains/deals/overview/internal-team-members/useHandleExternalCommunication'
import useHandleFormState from 'components/domains/deals/overview/internal-team-members/useHandleFormState'
import StaffMemberAutocompleteInput from 'components/ui/input/StaffMemberAutocompleteInput'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import CardWithDisplayAndEditTable from 'components/ui/tables/display-and-edit-table/CardWithDisplayAndEditTable'
import { defaultPopoverStaticConfig } from 'components/ui/tables/display-and-edit-table/DisplayAndEditTablePopover'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import useDealRelations from 'hooks/services/deals/deal-team/useDealRelations'
import { DealContext } from 'routes/deals/DealContext'

const InternalTeamMembers = () => {
  const { deal: { dealUuid: dealUuid } = {}, allowedOperations = [] } = useContext(DealContext)
  const { t: tNoPrefix } = useTranslation('translation')
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.internal-team-members',
  })
  const showMessageBox = useShowMessageBox()

  const {
    createDealTeamMember,
    updateDealTeamMember,
    deleteDealTeamMember,
    businessPartnerAllowedOperations,
    staffMembers,
    isDealTeamError,
    isDealTeamLoading,
    getUserById,
    isUsersLoading,
  } = useHandleExternalCommunication({ dealUuid })

  const { formState, findRow, updateRow, removeChangeFromFormState } = useHandleFormState({
    staffMembers,
  })
  const { leaders } = useCalculateLeaderFlags({ formState, staffMembers })

  const isAllowedToEdit =
    allowedOperations.includes(dealsPermissions.updateDealTeamInternal) &&
    businessPartnerAllowedOperations.includes(Contact.write)

  const handleSave = (rowKey) => {
    const row = findRow(rowKey)
    const leaderForRow = leaders.find((leader) => leader.virtualId === rowKey)
    const dealTeamMember = {
      userId: row.userId,
      leader: leaderForRow.isLeader,
      relation: { sapRelationId: row.sapRelationId },
    }
    if (rowKey === rowKeyNewRow) {
      createDealTeamMember.mutate({ dealUuid, dealTeamMember })
    } else {
      updateDealTeamMember.mutate({ dealUuid, virtualId: rowKey, dealTeamMember })
    }
    removeChangeFromFormState(rowKey)
  }

  const handleDelete = (rowKey) => {
    deleteDealTeamMember.mutate({ dealUuid, virtualId: rowKey })
  }

  const [duplicateRows, setDuplicateRows] = useState([])
  const detectDuplicates = (rows = []) => {
    const duplicateRowIds = rows.reduce((acc, row) => {
      const currentRowDuplicates = rows.filter(
        ({ virtualId, userId, sapRelationId }) =>
          virtualId !== row.virtualId &&
          userId &&
          userId === row.userId &&
          sapRelationId &&
          sapRelationId === row.sapRelationId,
      )
      return [...currentRowDuplicates.map(({ virtualId }) => virtualId), ...acc]
    }, [])
    setDuplicateRows(uniq(duplicateRowIds))
  }
  const hasDuplicates = (rowKey) => duplicateRows.includes(rowKey)

  const isRowValid = (rowKey, originalUserId, originalSapRelationId) => {
    if (rowKey === rowKeyNewRow) {
      const changedRow = findRow(rowKey)
      return !!(changedRow?.userId && changedRow?.sapRelationId && !hasDuplicates(rowKey))
    }

    const originalStaffMember = staffMembers.find(
      ({ userId, relation: { sapRelationId } = {} }) =>
        userId === originalUserId && sapRelationId === originalSapRelationId,
    )
    const changedRow = {
      userId: originalStaffMember.userId,
      sapRelationId: originalStaffMember.relation.sapRelationId,
      ...findRow(rowKey),
    }

    return !!(changedRow?.userId && changedRow?.sapRelationId && !hasDuplicates(rowKey))
  }

  const checkIsValidReturnErrorMessage = (rowKey) => {
    const rowToSave = findRow(rowKey)
    if (isEmpty(rowToSave) && rowKey !== rowKeyNewRow) {
      return { isError: true, errorMessage: t('no-changes') }
    }
    return { isError: false }
  }

  const renderStaffMemberAutocompleteInput = (rowKey, staffMemberName, disabled) => {
    const additionalProps = hasDuplicates(rowKey)
      ? {
          valueState: ValueState.Error,
          valueStateMessage: <Text wrapping>{t('validation.duplicate-row')}</Text>,
        }
      : {}
    return (
      <StaffMemberAutocompleteInput
        staffMemberName={staffMemberName ?? ''}
        className={styles['input-inside-cell']}
        onInput={() => updateRow(rowKey, { userId: null })}
        onBlur={() => detectDuplicates(formState)}
        onStaffMemberSelect={(event) => {
          const updatedRows = updateRow(rowKey, { userId: event?.id })
          detectDuplicates(updatedRows)
        }}
        required
        disabled={disabled}
        {...additionalProps}
      />
    )
  }

  const renderDisabledTeamInput = (team) => (
    <Input className={styles['input-inside-cell']} disabled>
      {team}
    </Input>
  )

  const [dirtyRelationRows, setDirtyRelationRows] = useState([])
  const renderRelationSelect = (rowKey, selectedKey, disabled) => (
    <LoadingSelect
      className={styles['input-inside-cell']}
      loadingHook={useDealRelations}
      id="user-functions-dropdown"
      onChange={(event) => {
        setDirtyRelationRows((prevRelations) => uniq([...prevRelations, rowKey]))
        const updatedRows = updateRow(rowKey, {
          sapRelationId: event.detail.selectedOption.dataset.id,
        })
        detectDuplicates(updatedRows)
      }}
      selectionName="functions"
      optionDisplayName="sapRelationName"
      optionKeyName="sapRelationId"
      selectedKey={selectedKey ?? ''}
      required
      disabled={disabled}
      isErrorState={
        (dirtyRelationRows.includes(rowKey) && !findRow(rowKey)?.sapRelationId) ||
        hasDuplicates(rowKey)
      }
      valueStateMessage={
        <Text wrapping>
          {hasDuplicates(rowKey) ? t('validation.duplicate-row') : t('validation.function')}
        </Text>
      }
    />
  )

  const renderLeaderSwitch = (rowKey) => {
    const leaderForRowKey = leaders.find((leader) => leader.virtualId === rowKey)
    return (
      <Switch
        className={styles['input-inside-cell']}
        checked={leaderForRowKey?.isLeader}
        disabled={!leaderForRowKey?.isLeaderEditable}
        onChange={(event) => {
          const checked = event.target._state.checked
          if (checked) {
            showMessageBox({
              type: MessageBoxTypes.Information,
              children: t('leader-change-information'),
            })
          }
          updateRow(rowKey, { leader: checked })
        }}
      />
    )
  }

  const tableData = staffMembers.map(
    ({
      virtualId,
      userId,
      relation: { sapRelationId, sapRelationName, modifiable } = {},
      leader,
    }) => {
      const userForCurrentRow = getUserById(userId)
      return {
        rowKey: virtualId,
        isValid: isRowValid(virtualId, userId, sapRelationId),
        name: {
          cellContentReadMode: (
            <Label>
              <b>{userForCurrentRow?.fullName}</b>
            </Label>
          ),
          cellContentEditMode: renderStaffMemberAutocompleteInput(
            virtualId,
            userForCurrentRow?.fullName,
            !modifiable,
          ),
        },
        team: {
          cellContentReadMode: <Label>{userForCurrentRow?.team}</Label>,
          cellContentEditMode: renderDisabledTeamInput(userForCurrentRow?.team),
        },
        userFunction: {
          cellContentReadMode: <Label>{sapRelationName}</Label>,
          cellContentEditMode: renderRelationSelect(virtualId, sapRelationId, !modifiable),
        },
        leader: {
          cellContentReadMode: (
            <>
              {leader && (
                <ObjectStatus inverted state={ValueState.Information}>
                  {t('leading')}
                </ObjectStatus>
              )}
            </>
          ),
          cellContentEditMode: renderLeaderSwitch(virtualId),
        },
      }
    },
  )

  const newRow = {
    rowKey: rowKeyNewRow,
    isValid: isRowValid(rowKeyNewRow),
    name: { cellContentEditMode: renderStaffMemberAutocompleteInput(rowKeyNewRow) },
    team: { cellContentEditMode: renderDisabledTeamInput() },
    userFunction: { cellContentEditMode: renderRelationSelect(rowKeyNewRow) },
    leader: { cellContentEditMode: renderLeaderSwitch(rowKeyNewRow) },
  }

  const columnDefinitions = [
    {
      columnKey: 'name',
      title: t('name'),
    },
    {
      columnKey: 'team',
      title: t('team'),
    },
    {
      columnKey: 'userFunction',
      title: t('user-function'),
    },
    {
      columnKey: 'leader',
      title: t('leading'),
    },
  ]

  const resendConflictClearanceButton = useMemo(
    () =>
      allowedOperations.includes(dealsPermissions.updateDeal)
        ? [
            <span key="separator" className={styles['button-separator']} />,
            <ResendConflictClearanceButton key="resend-conflict-clearance" dealUuid={dealUuid} />,
          ]
        : [],
    [allowedOperations, dealUuid],
  )

  return (
    <CardWithDisplayAndEditTable
      cardTitle={t('title', { teamMemberCount: staffMembers.length })}
      isLoading={isDealTeamLoading || isUsersLoading}
      isError={isDealTeamError}
      userIsAllowedToEdit={isAllowedToEdit}
      columnDefinitions={columnDefinitions}
      tableData={tableData}
      newRow={newRow}
      handleSaveRow={handleSave}
      handleDeleteRow={handleDelete}
      handleCancelEditRow={(rowKey) => {
        const updatedRows = removeChangeFromFormState(rowKey)
        detectDuplicates(updatedRows)
      }}
      checkIsValidReturnErrorMessage={checkIsValidReturnErrorMessage}
      customActionElements={resendConflictClearanceButton}
      className={styles['default-cursor']}
      popoverStaticConfig={{
        ...defaultPopoverStaticConfig,
        delete: {
          message: t('delete-row'),
          button: tNoPrefix('buttons.remove'),
        },
      }}
    />
  )
}

export default InternalTeamMembers
