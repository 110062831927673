import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import CardSection from 'components/domains/deals/card/CardSection'
import styles from 'components/domains/deals/overview/DealOverviewInformationCard.module.css'
import useCounterpartyFields from 'components/domains/deals/overview/useCounterpartyFields'
import useGeneralInformationFields from 'components/domains/deals/overview/useGeneralInformationFields'
import usePricingFields from 'components/domains/deals/overview/usePricingFields'
import { useGetAndAppendWorkingVersionSearchParam } from 'components/domains/deals/useGetAndAppendWorkingVersionSearchParam'
import DisplayCardView from 'components/ui/card/DisplayCardView'
import useCreateNavigateClickHandler from 'components/ui/link/useCreateNavigateClickHandler'
import useDeal from 'hooks/services/deals/useDeal'
import { DealContext } from 'routes/deals/DealContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const addSectionConfig = (sectionTitle, fields) => (fields.length ? [sectionTitle, ...fields] : [])

const DealOverviewInformationCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals',
  })
  const {
    deal: { dealUuid, displayId },
  } = useContext(DealContext)
  const { isError: isDealError, isLoading: isDealLoading, data: deal = {} } = useDeal(dealUuid)

  const {
    isLoading: isLoadingInformationFields,
    isError: isErrorInformationFields,
    data: informationFields,
  } = useGeneralInformationFields(deal)
  const {
    isLoading: isLoadingCounterpartyFields,
    isError: isErrorCounterpartyFields,
    data: counterpartyFields,
  } = useCounterpartyFields(deal.borrowerBpId)
  const {
    isLoading: isLoadingPricingFields,
    isError: isErrorPricingFields,
    data: pricingFields,
  } = usePricingFields(deal.dealUuid)

  const createClickHandler = useCreateNavigateClickHandler()
  const { appendWorkingVersionSearchParam } = useGetAndAppendWorkingVersionSearchParam()

  const basePath = `/${paths.deals}/${displayId}/${dealDetailPaths.generalInformation}`
  const generalInformationPath = appendWorkingVersionSearchParam(basePath)
  const handleHeaderClick = createClickHandler(generalInformationPath)

  return (
    <DisplayCardView
      isEditable={false}
      setEditMode={() => {}}
      cardHeaderTitle={t('detail.overview.information-card.title')}
      fieldDefinitions={[
        ...addSectionConfig(
          {
            name: 'information',
            customInfoComponent: (
              <CardSection
                className={styles.displaySection}
                title={t('overview.general-information.information')}
              />
            ),
          },
          informationFields,
        ),
        ...addSectionConfig(
          {
            name: 'counterparty',
            customInfoComponent: (
              <CardSection
                className={`${styles.displaySection} ${styles.sectionBorder}`}
                title={t('overview.general-information.counterparty')}
              />
            ),
          },
          counterpartyFields,
        ),
        ...addSectionConfig(
          {
            name: 'pricing',
            customInfoComponent: (
              <CardSection
                className={`${styles.displaySection} ${styles.sectionBorder}`}
                title={t('overview.general-information.pricing')}
              />
            ),
          },
          pricingFields,
        ),
      ]}
      onHeaderClick={handleHeaderClick}
      isLoading={
        isDealLoading ||
        isLoadingInformationFields ||
        isLoadingCounterpartyFields ||
        isLoadingPricingFields
      }
      isError={
        isDealError || isErrorInformationFields || isErrorCounterpartyFields || isErrorPricingFields
      }
      fillEmptyValuesWithPlaceholder
    />
  )
}

export default DealOverviewInformationCard
