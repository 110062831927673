import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  MultiInput,
  Token,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import uniqBy from 'lodash.uniqby'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BcaFilterBar,
  BcaFilterKeys,
} from 'components/domains/deals/financing/bank-customer-accounts/bca-search-dialog/BcaFilterBar'
import styles from 'components/domains/deals/financing/bank-customer-accounts/bca-search-dialog/BcaSearchDialog.module.css'
import BcaSearchTable, {
  BcaSearchTableColumn,
  BcaSearchTableMode,
} from 'components/domains/deals/financing/bank-customer-accounts/bca-search-dialog/BcaSearchTable'
import useBankCustomerAccountsTableData from 'components/domains/deals/financing/bank-customer-accounts/overview/useBankCustomerAccountsTableData'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'

const BcaSearchDialog = ({
  isOpen,
  setIsOpen,
  onClose = () => {},
  onSelected,
  isMultiSelect,
  columnsRestrictedTo,
  initialFilterValues: passedInitialFilterValues = {},
  visibleFilters,
  readOnlyFilters = [],
  initiallySelectedBcas = [],
  lockedSelectedBcas = [],
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.bank-customer-accounts.bca-search',
  })
  const { t: tNoPrefix } = useTranslation()

  const [filterParams, setFilterParams] = useState(passedInitialFilterValues)
  const [isInitialState, setIsInitialState] = useState(true)
  const [isFilterTriggered, setIsFilterTriggered] = useState(false)

  const [selectedDialogBcas, setSelectedDialogBcas] = useState(initiallySelectedBcas)
  const selectedDialogBcaIds = useMemo(
    () => selectedDialogBcas.map((bca) => bca.bcaId),
    [selectedDialogBcas],
  )

  const {
    isFetching: isFetchingBcas,
    isLoading: isLoadingBcas,
    isError: isErrorBcas,
    data: tableData = [],
  } = useBankCustomerAccountsTableData(filterParams[BcaFilterKeys.Deal]?.id, null, true)

  useEffect(() => {
    if (isInitialState && isFilterTriggered && !isLoadingBcas) {
      setIsInitialState(false)
    }
  }, [isFilterTriggered, isInitialState, isLoadingBcas])

  const updateFilters = (_filterParams) => {
    setFilterParams(_filterParams)
  }

  const handleOnClose = () => {
    setIsInitialState(true)
    setIsFilterTriggered(false)
    setIsOpen(false)
    setFilterParams(passedInitialFilterValues)
    onClose()
  }
  const handleOnOkButton = () => {
    onSelected(selectedDialogBcas)
    handleOnClose()
  }
  const isAnyBcaSelected = () => selectedDialogBcaIds.length > 0

  const handleFilterbarGo = (newFilters) => {
    setIsFilterTriggered(true)
    updateFilters(newFilters)
  }

  const isBcaLockedByBankAreaAndAccountNumber = useCallback(
    (bankArea, accountNumber) =>
      lockedSelectedBcas?.some(
        ({ bankArea: lockedBankArea, accountNumber: lockedAccountNumber } = {}) =>
          lockedBankArea &&
          bankArea &&
          lockedAccountNumber &&
          accountNumber &&
          lockedBankArea === bankArea &&
          lockedAccountNumber === accountNumber,
      ),
    [lockedSelectedBcas],
  )

  const isBcaByLockedBcaId = useCallback(
    (bcaId) =>
      lockedSelectedBcas?.some(
        ({ bcaId: lockedBcaId } = {}) => lockedBcaId && bcaId && lockedBcaId === bcaId,
      ),
    [lockedSelectedBcas],
  )

  const isBcaLocked = useCallback(
    (bca = {}) => {
      const { bcaId, bankArea, accountNumber } = bca
      return (
        isBcaLockedByBankAreaAndAccountNumber(bankArea, accountNumber) || isBcaByLockedBcaId(bcaId)
      )
    },
    [isBcaByLockedBcaId, isBcaLockedByBankAreaAndAccountNumber],
  )
  const onSelectionChanged = (selectionEvent, toggleSingleBca) => {
    const selectedTableRows = selectionEvent.detail.selectedRows
    const selectedTableBcas = selectedTableRows
      .map(({ dataset: bca }) => ({
        ...bca,
      }))
      ?.filter((bca) => !isBcaLocked(bca))

    if (toggleSingleBca) {
      const selectedBcaId = selectedTableBcas?.[0]?.bcaId
      if (selectedDialogBcaIds.includes(selectedBcaId)) {
        setSelectedDialogBcas((previous) => previous.filter(({ bcaId }) => bcaId !== selectedBcaId))
      } else {
        setSelectedDialogBcas((previous) => uniqBy([...previous, selectedTableBcas[0]], 'bcaId'))
      }
      return
    }

    const currentlyVisibleBcaIds = tableData.map((bca) => bca.bcaId)
    const previouslySelectedBcas = selectedDialogBcas.filter(
      (selectedBca) => !currentlyVisibleBcaIds.includes(selectedBca.bcaId),
    )
    const updatedSelectedBcas = [
      ...lockedSelectedBcas,
      ...previouslySelectedBcas,
      ...selectedTableBcas,
    ]

    setSelectedDialogBcas(updatedSelectedBcas)

    // Select the last added bca and close the dialog in single select case
    if (!isMultiSelect) {
      onSelected(selectedTableBcas)
      handleOnClose()
    }
  }

  const handleFooterTokenDelete = useCallback(
    (deleteEvent) => {
      const deleteBca = deleteEvent.detail.token.dataset
      if (isBcaLocked(deleteBca)) {
        return
      }

      setSelectedDialogBcas((prev) => [...prev.filter(({ bcaId }) => bcaId !== deleteBca?.bcaId)])
    },
    [isBcaLocked],
  )

  const handleFooterTokenClearAll = useCallback(() => {
    setSelectedDialogBcas(lockedSelectedBcas ?? [])
  }, [lockedSelectedBcas])

  const renderTable = () => (
    <BcaSearchTable
      bcas={tableData}
      mode={isMultiSelect ? BcaSearchTableMode.MultiSelect : BcaSearchTableMode.SingleSelect}
      onSelectionChanged={onSelectionChanged}
      columnsRestrictedTo={columnsRestrictedTo}
      isError={isErrorBcas}
      isLoading={isFetchingBcas}
      additionalToolbarProperties={{ showColumnSelection: false, sorting: null }}
      showInitPlaceholder={isInitialState && !isLoadingBcas}
      filterParams={filterParams}
      selectedBcaIds={selectedDialogBcaIds}
      lockedSelectedDeals={lockedSelectedBcas}
    />
  )

  const renderFooter = () =>
    isMultiSelect && !isErrorBcas ? (
      <>
        <Label className={styles.titleLabel}>
          {t('selected-bcas', { count: selectedDialogBcas.length })}
        </Label>
        <FlexBox className={styles.selectionRow} alignItems={FlexBoxAlignItems.Center}>
          <MultiInput
            maxlength={0}
            onTokenDelete={handleFooterTokenDelete}
            className={styles.footerInput}
            tokens={
              <>
                {selectedDialogBcas.map((bca) => {
                  if (isBcaLocked(bca)) {
                    // remove deletion options for locked selected bcas
                    return (
                      <Token
                        key={bca.bcaId}
                        text={bca.accountNumber}
                        data-bca-id={bca.bcaId}
                        data-bank-area={bca.bankArea}
                        data-account-number={bca.accountNumber}
                        closeIcon={<div className={styles.emptyIcon} />}
                      />
                    )
                  }
                  return (
                    <Token
                      key={bca.bcaId}
                      text={bca.accountNumber}
                      data-bca-id={bca.bcaId}
                      data-bank-area={bca.bankArea}
                      data-account-number={bca.accountNumber}
                    />
                  )
                })}
              </>
            }
          />
          <Button
            icon="decline"
            design={ButtonDesign.Transparent}
            onClick={handleFooterTokenClearAll}
          />
        </FlexBox>
      </>
    ) : (
      <></>
    )

  return (
    <Dialog
      open={isOpen}
      size={DialogSize.XL}
      className={styles.bcaSearchDialog}
      headerText={t('title')}
      primaryButton={
        isMultiSelect && (
          <DialogPrimaryButton
            onClick={handleOnOkButton}
            design={ButtonDesign.Emphasized}
            disabled={!isAnyBcaSelected()}
          >
            {tNoPrefix('buttons.ok')}
          </DialogPrimaryButton>
        )
      }
      onBeforeClose={(e) => e?.detail?.escPressed && handleOnClose()}
      closeButton={
        <DialogSecondaryButton onClick={handleOnClose}>
          {tNoPrefix('buttons.cancel')}
        </DialogSecondaryButton>
      }
    >
      <FlexBox direction={FlexBoxDirection.Column} className={styles.dialogContentWrapper}>
        <BcaFilterBar
          onGo={handleFilterbarGo}
          isOpen={isOpen}
          initialValues={filterParams}
          visibleFilters={visibleFilters}
          readOnlyFilters={readOnlyFilters}
          additionalFilterBarProperties={{ hideFilterConfiguration: true }}
        />
        <div
          className={compact([styles.tableWrapper, !isInitialState && styles.fixTableHeight]).join(
            ' ',
          )}
        >
          {renderTable()}
        </div>
        <FlexBox
          direction={FlexBoxDirection.Column}
          justifyContent={FlexBoxJustifyContent.End}
          className={styles.footerWrapper}
        >
          {renderFooter()}
        </FlexBox>
      </FlexBox>
    </Dialog>
  )
}

BcaSearchDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onSelected: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  columnsRestrictedTo: PropTypes.arrayOf(PropTypes.oneOf(Object.values(BcaSearchTableColumn))),
  // eslint-disable-next-line react/forbid-prop-types
  initialFilterValues: PropTypes.object,
  visibleFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(BcaFilterKeys))),
  readOnlyFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(BcaFilterKeys))),
  // eslint-disable-next-line react/forbid-prop-types
  lockedSelectedBcas: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  initiallySelectedBcas: PropTypes.arrayOf(PropTypes.object),
}

export default BcaSearchDialog
