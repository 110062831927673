import { FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import { DateTime } from 'luxon'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import styles from 'components/domains/deals/covenants/covenant-card/CovenantCard.module.css'
import LimitCell from 'components/domains/deals/covenants/shared/LimitCell'
import { useGetAndAppendWorkingVersionSearchParam } from 'components/domains/deals/useGetAndAppendWorkingVersionSearchParam'
import Card from 'components/ui/card/Card'
import CtrlClickableCardHeader from 'components/ui/card/CtrlClickableCardHeader'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import Entity from 'components/ui/data/Entity'
import ReferenceEntities from 'components/ui/data/ReferenceEntities'
import useCreateNavigateClickHandler from 'components/ui/link/useCreateNavigateClickHandler'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import { useCovenantsWithFrontendPagination } from 'hooks/services/deals/covenants/useCovenants'
import { DealContext } from 'routes/deals/DealContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const ACTIVE = 'ACTIVE'
const FINANCIAL = 'FINANCIAL'

const CovenantCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.covenant-card',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const createClickHandler = useCreateNavigateClickHandler()
  const { appendWorkingVersionSearchParam } = useGetAndAppendWorkingVersionSearchParam()
  const { deal: { dealUuid, displayId } = {} } = useContext(DealContext)

  const validAt = useMemo(() => DateTime.now().toISODate(), [])
  const {
    data: { covenants } = {},
    isLoading,
    isError,
  } = useCovenantsWithFrontendPagination({
    mainEntityId: dealUuid,
    mainEntityType: mainEntityTypes.DEAL,
    validAt,
  })

  const covenantsBasePath = `/${paths.deals}/${displayId}/${dealDetailPaths.covenants}`
  const onHeaderClick = createClickHandler(appendWorkingVersionSearchParam(covenantsBasePath))

  const activeCovenants = covenants.filter(({ status }) => status?.toUpperCase() === ACTIVE)

  const activeFinancialCovenants = activeCovenants.filter(
    ({ covenantDefinition = {} }) => covenantDefinition?.type?.toUpperCase() === FINANCIAL,
  )
  const activeNonFinancialCovenantsCount = activeCovenants.filter(
    ({ covenantDefinition = {} }) => covenantDefinition?.type?.toUpperCase() !== FINANCIAL,
  ).length

  const columnDefinitions = [
    {
      title: tNoPrefix('pages.deals.covenants.covenant'),
      columnKey: 'covenant',
    },
    {
      title: tNoPrefix('pages.deals.covenants.reference-entity'),
      columnKey: 'reference-entity',
    },
    {
      title: tNoPrefix('pages.deals.covenants.monitoring-entity'),
      columnKey: 'monitoring-entity',
      isVisible: false,
    },
    {
      title: tNoPrefix('pages.deals.covenants.last-checked'),
      columnKey: 'last-checked',
      isVisible: false,
    },
    {
      title: tNoPrefix('pages.deals.covenants.soft-limit'),
      columnKey: 'soft-limit',
      alignment: FlexBoxAlignItems.End,
    },
    {
      title: tNoPrefix('pages.deals.covenants.hard-limit'),
      columnKey: 'hard-limit',
      alignment: FlexBoxAlignItems.End,
    },
  ]

  const tableData = activeFinancialCovenants?.map(
    ({
      covenantUuid,
      covenantDefinition = {},
      referenceEntity = {},
      softLimit = {},
      hardLimit = {},
    }) => ({
      rowKey: `${covenantUuid}`,
      rowProperties: {
        className: styles.defaultCursor,
      },
      covenant: {
        cellComponent: (
          <Entity
            name={covenantDefinition?.name}
            link={`${covenantsBasePath}/${covenantUuid}`}
            openInNewTab
          />
        ),
      },
      'reference-entity': {
        cellComponent: <ReferenceEntities type={referenceEntity.type} ids={referenceEntity.ids} />,
      },
      'monitoring-entity': {
        cellComponent: <span />,
      },
      'last-checked': {
        cellComponent: <span />,
      },
      'soft-limit': {
        cellComponent: (
          <LimitCell
            unit={softLimit?.unit}
            value={softLimit?.value}
            currencyCode={softLimit?.currencyCode}
            threshold={softLimit?.threshold}
          />
        ),
      },
      'hard-limit': {
        cellComponent: (
          <LimitCell
            unit={hardLimit?.unit}
            value={hardLimit?.value}
            currencyCode={hardLimit?.currencyCode}
            threshold={hardLimit?.threshold}
          />
        ),
      },
    }),
  )

  return (
    <Card
      header={
        <CtrlClickableCardHeader
          action={<span>{t('active-financial-covenants')}</span>}
          titleText={t('title')}
          onClick={onHeaderClick}
          interactive
        />
      }
    >
      <RequestStateResolver
        isLoading={isLoading}
        isError={isError}
        render
        renderContent={() =>
          !activeCovenants?.length ? (
            <EmptyCardContent />
          ) : (
            <>
              <CardSection>
                <SortedTable
                  tableData={tableData}
                  columnDefinitions={columnDefinitions}
                  noDataText={tNoPrefix('components.ui.card.empty-card.title')}
                  additionalTableProperties={{ className: styles.innerTable }}
                />
              </CardSection>
              <CardSection>
                <CardShowItem
                  label={t('active-non-financial-covenants')}
                  value={activeNonFinancialCovenantsCount}
                />
              </CardSection>
            </>
          )
        }
      />
    </Card>
  )
}

CovenantCard.propTypes = {}

export default CovenantCard
