import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'
/**
 @returns {*|(*&{data: *|*[]})}
 */
const useUnitRelationships = (options) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/configurations/businesspartner-unit-relationships`,
      useCache: true,
      keys: ['deals', 'configurations', 'businesspartner-unit-relationships'],
      options,
    }),
  )

export default useUnitRelationships
