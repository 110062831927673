import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  MultiInput,
  Token,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import uniq from 'lodash.uniq'
import uniqBy from 'lodash.uniqby'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FINANCING_TRANCHE_READ } from 'api/deals/financing/allowedOperationsConstants'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import useTranches from 'hooks/services/deals/covenants/useTranches'
import { useFinancingAllowedOperations } from 'hooks/services/deals/financing/useFinancingAllowedOperations'
import useDealMini from 'hooks/services/deals/useDealMini'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import {
  TrancheFilterBar,
  TrancheFilterKeys,
} from 'routes/deals/financing/shared-components/tranches-search-dialog/TrancheFilterBar'
import styles from 'routes/deals/financing/shared-components/tranches-search-dialog/TrancheSearchDialog.module.css'
import TrancheSearchTable, {
  TrancheSearchTableColumns,
  TrancheSearchTableMode,
} from 'routes/deals/financing/shared-components/tranches-search-dialog/TrancheSearchTable'

const TrancheSearchDialog = ({
  isOpen,
  setIsOpen,
  onClose = () => {},
  onSelected,
  isMultiSelect,
  columnsRestrictedTo,
  disabledTrancheIds,
  initialFilterValues: passedInitialFilterValues = {},
  disabledFilterValues,
  initiallySelectedTranches = [],
  lockedSelectedTranches = [],
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.tranche-search-dialog',
  })
  const { t: tNoPrefix } = useTranslation()

  const uniqueSelectedTranches = useMemo(
    () =>
      uniqBy(
        [...(initiallySelectedTranches ?? []), ...(lockedSelectedTranches ?? [])],
        'trancheId',
      ) ?? [],
    [initiallySelectedTranches, lockedSelectedTranches],
  )

  const [filterParams, setFilterParams] = useState(passedInitialFilterValues)
  const [isInitialState, setIsInitialState] = useState(true)
  const [isFilterTriggered, setIsFilterTriggered] = useState(false)

  const [selectedDialogTranches, setSelectedDialogTranches] = useState(uniqueSelectedTranches ?? [])

  const selectedTrancheDealIds = useMemo(
    () => selectedDialogTranches.map((tranche) => tranche.trancheId),
    [selectedDialogTranches],
  )

  const isTrancheLocked = useCallback(
    (trancheId) =>
      lockedSelectedTranches?.some(
        ({ trancheId: lockedTrancheId } = {}) => lockedTrancheId === trancheId,
      ),
    [lockedSelectedTranches],
  )

  useEffect(() => {
    if (isOpen) {
      setSelectedDialogTranches(uniqueSelectedTranches)
    }
    // since this dialog is not always rendered conditionally we need to trigger this effect
    // when the open flag changes but not necessarily when the
    // initialBusinessPartners change (that would magically select or deselect BPs without the user clicking somewhere)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const dealIdFilter = filterParams[TrancheFilterKeys.Deal]
  const businessTypeFilter = filterParams[TrancheFilterKeys.BusinessType]

  const {
    isFetching: isFetchingAllowedOperations,
    isError: isErrorAllowedOperations,
    data: { allowedOperations = [] } = {},
  } = useFinancingAllowedOperations(dealIdFilter)
  const { data: deal, isFetching: isFetchingDeal, isError: isErrorDeal } = useDealMini(dealIdFilter)

  const isAllowedToReadTranche = allowedOperations.includes(FINANCING_TRANCHE_READ)

  // table deals: deals currently visible in the search results
  const {
    isFetching: isFetchingTranches,
    isLoading: isLoadingTranches,
    isError: isErrorTranches,
    data: tranchesData,
  } = useTranches({
    dealUuid: dealIdFilter,
    dataSource: businessTypeFilter,
    options: {
      enabled: !!(dealIdFilter && businessTypeFilter),
    },
  })
  const tableTranches = useMemo(() => tranchesData?.tranches ?? [], [tranchesData?.tranches])

  useEffect(() => {
    if (isInitialState && isFilterTriggered && !isLoadingTranches) {
      setIsInitialState(false)
    }
  }, [isFilterTriggered, isInitialState, isLoadingTranches])

  const updateFilters = (_filterParams) => {
    setFilterParams(_filterParams)
  }

  const handleOnClose = () => {
    setIsInitialState(true)
    setIsFilterTriggered(false)
    setIsOpen(false)
    onClose()
  }
  const handleOnOkButton = () => {
    onSelected(selectedDialogTranches)
    handleOnClose()
  }
  const isAnyTrancheSelected = selectedTrancheDealIds.length > 0

  const handleFilterbarGo = (newFilters) => {
    setIsFilterTriggered(true)
    updateFilters(newFilters)
  }

  const onSelectionChanged = (selectionEvent, toggleSingleTranche) => {
    const selectedTableRows = selectionEvent.detail.selectedRows
    const selectedTableTranches = selectedTableRows
      .map(({ dataset: dataset }) => {
        const {
          receivableId,
          trancheId,
          displayId,
          dealId,
          dealDisplayId,
          dealName,
          dealStatus,
          trancheName,
          dataSource,
        } = dataset
        return {
          receivableId,
          displayId,
          trancheId,
          trancheName,
          dataSource,
          deal: {
            id: dealId,
            displayId: dealDisplayId,
            name: dealName,
            status: dealStatus,
          },
        }
      })
      ?.filter(({ trancheId } = {}) => !isTrancheLocked(trancheId))
      ?.filter(({ trancheId } = {}) => !(disabledTrancheIds ?? []).includes(trancheId))

    if (toggleSingleTranche) {
      const selectedTrancheId = selectedTableTranches?.[0]?.trancheId
      if (selectedTrancheDealIds.includes(selectedTrancheId)) {
        setSelectedDialogTranches((previous) =>
          previous.filter(({ trancheId }) => trancheId !== selectedTrancheId),
        )
      } else {
        // the selected tranches need to be checked for uniqueness since a click on the select box (not the row) triggers both cases
        setSelectedDialogTranches((previous) =>
          uniqBy([...previous, selectedTableTranches[0]], 'trancheId'),
        )
      }
      return
    }

    const currentlyVisibleTrancheIds = uniq(
      compact(
        tableTranches.map((d) => {
          if (businessTypeFilter === DATA_SOURCES.EXISTING_BUSINESS) {
            return d.externalContractId?.[0]
          } else if (businessTypeFilter === DATA_SOURCES.NEW_BUSINESS) {
            return d.trancheId
          }
        }),
      ),
    )
    const previouslySelectedTranches = selectedDialogTranches.filter(
      (selectedTranche) => !currentlyVisibleTrancheIds.includes(selectedTranche.trancheId),
    )
    const updatedSelectedTranches = uniqBy(
      [...lockedSelectedTranches, ...previouslySelectedTranches, ...selectedTableTranches],
      'trancheId',
    )
    setSelectedDialogTranches((_) => [...updatedSelectedTranches])

    if (!isMultiSelect && selectedTableTranches.length > 0) {
      onSelected(selectedTableTranches)
      handleOnClose()
    }
  }

  const handleFooterTokenDelete = useCallback(
    (deleteEvent) => {
      const deleteTrancheId = deleteEvent.detail.token.dataset.trancheId
      // do not delete locked selected tranches
      if (isTrancheLocked(deleteTrancheId)) {
        return
      }

      setSelectedDialogTranches((prev) => [
        ...prev.filter(({ trancheId }) => trancheId !== deleteTrancheId),
      ])
    },
    [isTrancheLocked],
  )

  const handleFooterTokenClearAll = useCallback(() => {
    // reset selected deals to locked selected deals (since they should never be removed)
    setSelectedDialogTranches(lockedSelectedTranches ?? [])
  }, [lockedSelectedTranches])

  const isError = isErrorTranches || isErrorAllowedOperations || isErrorDeal
  const isFetching = isFetchingTranches || isFetchingAllowedOperations || isFetchingDeal

  const renderTable = () => (
    <TrancheSearchTable
      deal={deal}
      tranches={tableTranches}
      mode={
        isMultiSelect ? TrancheSearchTableMode.MultiSelect : TrancheSearchTableMode.SingleSelect
      }
      onSelectionChanged={onSelectionChanged}
      selectedTrancheIds={selectedTrancheDealIds}
      columnsRestrictedTo={columnsRestrictedTo}
      isError={isError}
      isLoading={isFetching}
      isAllowedToReadTranche={isAllowedToReadTranche}
      additionalToolbarProperties={{ showColumnSelection: false, sorting: null }}
      showInitPlaceholder={isInitialState && !isError}
      lockedSelectedTranches={lockedSelectedTranches}
      disabledTrancheIds={disabledTrancheIds}
    />
  )

  const readOnlyFilters = useMemo(
    () =>
      Object.entries(disabledFilterValues)
        .filter(([_key, value]) => !!value)
        .map(([key]) => key),
    [disabledFilterValues],
  )

  const renderFooter = () =>
    isMultiSelect && !isErrorTranches && !isErrorAllowedOperations ? (
      <>
        <Label className={styles.titleLabel}>
          {t('selected-tranches', { count: selectedDialogTranches.length })}
        </Label>
        <FlexBox className={styles.selectionRow} alignItems={FlexBoxAlignItems.Center}>
          <MultiInput
            maxlength={0}
            onTokenDelete={handleFooterTokenDelete}
            className={styles.footerInput}
            tokens={
              <>
                {selectedDialogTranches.map((tranche) => {
                  let trancheIdToDisplay = null
                  if (tranche.dataSource === DATA_SOURCES.NEW_BUSINESS) {
                    trancheIdToDisplay = tranche.displayId ?? tranche.trancheId
                  } else if (tranche.dataSource === DATA_SOURCES.EXISTING_BUSINESS) {
                    trancheIdToDisplay = tranche.trancheId
                  }
                  const tokenText = trancheIdToDisplay
                    ? `${tranche.trancheName} (${trancheIdToDisplay})`
                    : tranche.trancheName
                  if (
                    lockedSelectedTranches?.some(
                      ({ trancheId } = {}) => trancheId === tranche.trancheId,
                    )
                  ) {
                    // remove deletion options for locked selected deals
                    return (
                      <Token
                        key={tranche.trancheId}
                        text={tokenText}
                        data-tranche-id={tranche.trancheId}
                        closeIcon={<div className={styles.emptyIcon} />}
                      />
                    )
                  }
                  return (
                    <Token
                      key={tranche.trancheId}
                      text={tokenText}
                      data-tranche-id={tranche.trancheId}
                    />
                  )
                })}
              </>
            }
          />
          <Button
            icon="decline"
            design={ButtonDesign.Transparent}
            onClick={handleFooterTokenClearAll}
          />
        </FlexBox>
      </>
    ) : (
      <></>
    )

  const noPermissionsIllustratedMessage = (
    <CenteredIllustratedMessage
      name="UnableToLoad"
      size="Spot"
      titleText={t('not-allowed.title')}
      subtitleText={t('not-allowed.subtitle')}
    />
  )
  return (
    <Dialog
      open={isOpen}
      size={DialogSize.XL}
      className={styles.tranacheSearchDialog}
      headerText={t('title')}
      primaryButton={
        isMultiSelect && (
          <DialogPrimaryButton
            onClick={handleOnOkButton}
            design={ButtonDesign.Emphasized}
            disabled={!isAnyTrancheSelected}
          >
            {tNoPrefix('buttons.ok')}
          </DialogPrimaryButton>
        )
      }
      onBeforeClose={(e) => e?.detail?.escPressed && handleOnClose()}
      closeButton={
        <DialogSecondaryButton onClick={handleOnClose}>
          {tNoPrefix('buttons.cancel')}
        </DialogSecondaryButton>
      }
    >
      {!isFetchingAllowedOperations && !isAllowedToReadTranche && dealIdFilter ? (
        noPermissionsIllustratedMessage
      ) : (
        <FlexBox direction={FlexBoxDirection.Column} className={styles.dialogContentWrapper}>
          <TrancheFilterBar
            onGo={handleFilterbarGo}
            isOpen={isOpen}
            initialValues={filterParams}
            readOnlyFilters={readOnlyFilters}
            additionalFilterBarProperties={{ hideFilterConfiguration: true }}
          />
          <div
            className={compact([
              styles.tableWrapper,
              !isInitialState && styles.fixTableHeight,
            ]).join(' ')}
          >
            {renderTable()}
          </div>
          <FlexBox
            direction={FlexBoxDirection.Column}
            justifyContent={FlexBoxJustifyContent.End}
            className={styles.footerWrapper}
          >
            {renderFooter()}
          </FlexBox>
        </FlexBox>
      )}
    </Dialog>
  )
}

TrancheSearchDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onSelected: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  disabledTrancheIds: PropTypes.arrayOf(PropTypes.string),
  columnsRestrictedTo: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TrancheSearchTableColumns))),
  // eslint-disable-next-line react/forbid-prop-types
  initialFilterValues: PropTypes.object,
  disabledFilterValues: PropTypes.objectOf(PropTypes.bool),
  initiallySelectedTranches: PropTypes.arrayOf(
    PropTypes.shape({
      trancheId: PropTypes.string.isRequired,
      trancheName: PropTypes.string.isRequired,
      displayId: PropTypes.string.isRequired,
    }),
  ),
  lockedSelectedTranches: PropTypes.arrayOf(
    PropTypes.shape({
      trancheId: PropTypes.string.isRequired,
      trancheName: PropTypes.string.isRequired,
      displayId: PropTypes.string.isRequired,
    }),
  ),
}

export default TrancheSearchDialog
