import {
  Card,
  IllustratedMessage,
  IllustrationMessageType,
  MessageStrip,
  MessageStripDesign,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/overview/syndication/DealOverviewSyndicationCard.module.css'
import DealOverviewSyndicationExistingBusinessContent from 'components/domains/deals/overview/syndication/DealOverviewSyndicationExistingBusinessContent'
import DealOverviewSyndicationNewBusinessContent from 'components/domains/deals/overview/syndication/DealOverviewSyndicationNewBusinessContent'
import { SyndicationStructureColorContext } from 'components/domains/deals/overview/syndication/SyndicationStructureColorContext'
import { useGetAndAppendWorkingVersionSearchParam } from 'components/domains/deals/useGetAndAppendWorkingVersionSearchParam'
import CtrlClickableCardHeader from 'components/ui/card/CtrlClickableCardHeader'
import useCreateNavigateClickHandler from 'components/ui/link/useCreateNavigateClickHandler'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useSyndicationsExistingBusiness from 'hooks/services/deals/syndication/useSyndicationsExistingBusiness'
import useDealMini from 'hooks/services/deals/useDealMini'
import { DealContext } from 'routes/deals/DealContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import { DEAL_STATUS_RANKS } from 'routes/deals/financing/financingConstants'
import paths from 'routes/paths'

const COLOR_OWN_SHARE = '#2531D4'
const COLORS_COUNTERPARTIES = [
  '#8746CE',
  '#525DF4',
  '#A97DD8',
  '#828AF7',
  '#D3BDEB',
  '#99A0F9',
  '#1866B4',
  '#245A8E',
  '#5899DA',
  '#4D78A2',
  '#93BFEB',
  '#85A1BB',
  '#B2D4F5',
]
const TOOLIP_MAX_TRANCHE_NAME_LENGTH = 20
const TOOLIP_CUTTED_TRANCHE_NAME_LENGTH = 17

const DealOverviewSyndicationCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.syndication',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const { deal: { displayId, dealUuid } = {} } = useContext(DealContext)
  const { data: dealMini, isFetching: isFetchingDeal, isError: isErrorDeal } = useDealMini(dealUuid)
  const {
    data: syndicationDataExistingBusiness,
    isFetching: isFetchingSyndicationsEb,
    isError: isErrorSyndicationsEb,
  } = useSyndicationsExistingBusiness(dealUuid)

  const isFetching = isFetchingDeal || isFetchingSyndicationsEb
  const isError = isErrorDeal || isErrorSyndicationsEb

  const existingBusinessSyndicationExists = useMemo(
    () => !isEmpty(syndicationDataExistingBusiness),
    [syndicationDataExistingBusiness],
  )
  const displayExistingBusinessSyndication = useMemo(
    () => dealMini?.rank >= DEAL_STATUS_RANKS.SIGNED,
    [dealMini?.rank],
  )

  const createClickHandler = useCreateNavigateClickHandler()
  const { appendWorkingVersionSearchParam } = useGetAndAppendWorkingVersionSearchParam()
  const businessParam = new URLSearchParams()
  displayExistingBusinessSyndication && existingBusinessSyndicationExists
    ? businessParam.set('dataSource', 'existingBusiness')
    : businessParam.set('dataSource', 'newBusiness')

  const syndicationBasePath = `/${paths.deals}/${displayId}/${
    dealDetailPaths.syndication
  }?${businessParam.toString()}`

  const getNavigationPath = () => {
    const constructedPath = appendWorkingVersionSearchParam(syndicationBasePath)
    // its possible that there are multiple question marks in the URL (working version / data source)
    const parts = constructedPath.split('?')
    return parts.length <= 1 ? constructedPath : parts[0] + '?' + parts.slice(1).join('&')
  }

  const onHeaderClick = createClickHandler(getNavigationPath())

  const renderContent = () => {
    if (isFetching || isError) return <></>
    if (displayExistingBusinessSyndication && existingBusinessSyndicationExists) {
      return <DealOverviewSyndicationExistingBusinessContent dealUuid={dealUuid} />
    } else {
      return <DealOverviewSyndicationNewBusinessContent dealUuid={dealUuid} />
    }
  }

  const renderMessageStrip = () =>
    displayExistingBusinessSyndication &&
    !existingBusinessSyndicationExists && (
      <div className={styles.messageStrip}>
        <MessageStrip design={MessageStripDesign.Warning} hideCloseButton>
          {t('message-strip')}
        </MessageStrip>
      </div>
    )

  return (
    <Card
      header={
        <CtrlClickableCardHeader titleText={t('title')} onClick={onHeaderClick} interactive />
      }
    >
      <SyndicationStructureColorContext.Provider
        value={{
          colorOwnShare: COLOR_OWN_SHARE,
          colorsCounterparties: COLORS_COUNTERPARTIES,
          tooltipMaxTrancheNameLength: TOOLIP_MAX_TRANCHE_NAME_LENGTH,
          tooltipCuttedTrancheNameLength: TOOLIP_CUTTED_TRANCHE_NAME_LENGTH,
        }}
      >
        <div className={compact([isFetching && styles.loadingWrapper]).join(' ')}>
          <RequestStateResolver
            isLoading={isFetching}
            isError={isError}
            errorToDisplay={
              <IllustratedMessage
                name={IllustrationMessageType.UnableToLoad}
                titleText={tNoPrefix('components.ui.card.empty-card.title')}
                subtitleText={tNoPrefix('components.ui.card.empty-card.subtitle')}
              />
            }
            center
            renderContent={() => (
              <>
                {renderMessageStrip()}
                {renderContent()}
              </>
            )}
          />
        </div>
      </SyndicationStructureColorContext.Provider>
    </Card>
  )
}

export default DealOverviewSyndicationCard
