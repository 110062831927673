import { IllustrationMessageSize, IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import CardSection from 'components/domains/deals/card/CardSection'
import { CONFIG } from 'components/domains/deals/deal-cashflow/result-charts/DealCashflowFinancialRatioCard'
import styles from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowChartWrapper.module.css'
import { DealCashflowLineChart } from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowLineChart'
import { DealOverviewCashflowMainScenarioChartHeader } from 'components/domains/deals/overview/cashflow/DealOverviewCashflowMainScenarioChartHeader'
import useDealCashflowKpiSelect from 'components/domains/deals/overview/cashflow/useDealCashflowKpiSelect'
import { useGetAndAppendWorkingVersionSearchParam } from 'components/domains/deals/useGetAndAppendWorkingVersionSearchParam'
import Card from 'components/ui/card/Card'
import CtrlClickableCardHeader from 'components/ui/card/CtrlClickableCardHeader'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import LabelValueList from 'components/ui/data/LabelValueList'
import useCreateNavigateClickHandler from 'components/ui/link/useCreateNavigateClickHandler'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useBooleanToTextFormatter } from 'hooks/i18n/useI18n'
import useCashflowMainScenario from 'hooks/services/deals/cashflow/useCashflowMainScenario'

const DealOverviewCashflowMainScenarioChartCard = ({ pageData }) => {
  const {
    deal: { dealUuid },
    defaultKpiCode,
    defaultCategory,
  } = pageData

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.cashflow-card',
  })

  const formatBoolean = useBooleanToTextFormatter()

  const {
    data: { cashflowScenarios = [] } = {},
    isLoading: isLoadingCashflowMainScenario,
    isError: isErrorCashflowMainScenario,
  } = useCashflowMainScenario(dealUuid)

  const mainScenario = cashflowScenarios[0]
  const cashflowScenarioUuid = mainScenario?.cashflowScenarioUuid

  const {
    selectedData,
    isLoading: isDealCashflowLineChartLoading,
    isError: isDealCashflowLineChartError,
    maturityData,
    handleOnChangeSelectedCategory,
    categories,
    cashflowKpis,
    setSelectedCashflowKpi,
    selectedCashflowKpi,
    selectedCategory,
  } = useDealCashflowKpiSelect({ dealUuid, cashflowScenarioUuid, defaultKpiCode, defaultCategory })

  const isLoading = isLoadingCashflowMainScenario || isDealCashflowLineChartLoading
  const isError =
    !cashflowScenarioUuid || isErrorCashflowMainScenario || isDealCashflowLineChartError

  const headerProps = {
    selectedData,
    maturityData,
    handleOnChangeSelectedCategory,
    categories,
    cashflowKpis,
    setSelectedCashflowKpi,
    selectedCashflowKpi,
    selectedCategory,
    title: `title.financial-ratios`,
  }

  const { appendWorkingVersionSearchParam } = useGetAndAppendWorkingVersionSearchParam()
  const { pathname } = useLocation()
  const createClickHandler = useCreateNavigateClickHandler()
  const routeToCashflow = pathname.endsWith('/') ? `${pathname}cashflows` : `${pathname}/cashflows`

  const mainScenarioAttributes = [
    {
      label: t('scenarioName'),
      value: mainScenario?.name,
      linkTo: appendWorkingVersionSearchParam(`${routeToCashflow}/${cashflowScenarioUuid}`),
    },
    { label: t('frozen'), value: formatBoolean(mainScenario?.frozen) },
  ]

  const handleHeaderClick = createClickHandler(routeToCashflow)

  const isMainScenarioMissing =
    !isLoadingCashflowMainScenario && !isErrorCashflowMainScenario && !mainScenario

  return (
    <Card
      header={
        <CtrlClickableCardHeader
          titleText={t('title')}
          interactive={true}
          onClick={handleHeaderClick}
        />
      }
    >
      <RequestStateResolver
        isLoading={isLoading}
        isError={isError}
        errorToDisplay={
          isMainScenarioMissing && (
            <EmptyCardContent
              illustrationName={IllustrationMessageType.UnableToLoad}
              size={IllustrationMessageSize.Scene}
            />
          )
        }
        renderContent={() => (
          <>
            <CardSection>
              <DealOverviewCashflowMainScenarioChartHeader {...headerProps} />
              <div className={styles.chartWrapper}>
                {selectedData ? (
                  <DealCashflowLineChart
                    data={selectedData}
                    maturityData={maturityData}
                    config={CONFIG}
                  />
                ) : (
                  <EmptyCardContent illustrationName={IllustrationMessageType.UnableToLoad} />
                )}
              </div>
            </CardSection>
            <CardSection>
              <LabelValueList rows={mainScenarioAttributes} />
            </CardSection>
          </>
        )}
      />
    </Card>
  )
}

DealOverviewCashflowMainScenarioChartCard.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string,
    }),
    defaultKpiCode: PropTypes.string,
    defaultCategory: PropTypes.string,
  }),
}

export default DealOverviewCashflowMainScenarioChartCard
