import { useCallback, useMemo } from 'react'
import useTranches from 'hooks/services/deals/covenants/useTranches'
import useBankCustomerAccounts from 'hooks/services/deals/financing/bank-customer-accounts/useBankCustomerAccounts'
import useBankCustomerAccountsAmounts from 'hooks/services/deals/financing/bank-customer-accounts/useBankCustomerAccountsAmounts'

const useFinancingCardData = ({ dealUuid }) => {
  const {
    isFetching: isFetchingTranches,
    isError: isErrorTranches,
    data: { summary = {}, summaryHeadquarter = {}, tranches = [] } = {},
  } = useTranches({ dealUuid })

  const {
    data: { bankCustomerAccounts: bcaData = [] } = {},
    isFetching: isFetchingBca,
    isError: isErrorBca,
  } = useBankCustomerAccounts({
    dealUuid,
    includeCreditAccounts: true,
  })
  const bcaIds = useMemo(() => bcaData?.map((bca) => bca?.id)?.filter(Boolean), [bcaData])
  const {
    data: { data: { bcaAmounts: bcaAmountsData = [] } = {} } = {},
    isFetching: isFetchingBcaAmounts,
    isError: isErrorBcaAmounts,
  } = useBankCustomerAccountsAmounts({ bcaIds: bcaIds })

  const trancheMaturityDates = tranches.map(({ maturity }) => maturity).filter((date) => !!date)
  const accountMaturityDates = bcaData.map(({ maturity }) => maturity).filter((date) => !!date)
  const earliestMaturityDate = [...trancheMaturityDates, ...accountMaturityDates].sort(
    (a, b) => new Date(a) - new Date(b),
  )?.[0]

  const limitsHaveSameCurrency = useMemo(() => {
    const currency = bcaAmountsData?.[0]?.externalLimit?.currency
    return !bcaAmountsData?.some(({ externalLimit }) => externalLimit.currency !== currency)
  }, [bcaAmountsData])
  const bookBalancesHaveSameCurrency = useMemo(() => {
    const currency = bcaAmountsData?.[0]?.bookBalance?.currency
    return !bcaAmountsData?.some(({ bookBalance }) => bookBalance.currency !== currency)
  }, [bcaAmountsData])

  const getValueOrZero = useCallback((value) => value ?? 0, [])

  const { externalLimitSummary, bookBalanceSummary } = useMemo(() => {
    const summaries =
      bcaAmountsData?.reduce(
        (acc, curr) => ({
          externalLimitSummary: {
            value: acc.externalLimitSummary.value + getValueOrZero(curr?.externalLimit?.amount),
            currency: curr?.externalLimit?.currency,
            headquarterValue:
              acc.externalLimitSummary.headquarterValue +
              getValueOrZero(curr?.headquarterExternalLimit?.amount),
            headquarterCurrency: curr?.headquarterExternalLimit?.currency,
          },
          bookBalanceSummary: {
            value: acc.bookBalanceSummary.value + getValueOrZero(curr?.bookBalance?.amount),
            currency: curr?.bookBalance?.currency,
            headquarterValue:
              acc.bookBalanceSummary.headquarterValue +
              getValueOrZero(curr?.headquarterBookBalance?.amount),
            headquarterCurrency: curr?.headquarterBookBalance?.currency,
          },
        }),
        {
          externalLimitSummary: { value: 0, headquarterValue: 0 },
          bookBalanceSummary: { value: 0, headquarterValue: 0 },
        },
      ) ?? {}
    if (!limitsHaveSameCurrency) {
      delete summaries.externalLimitSummary.value
      delete summaries.externalLimitSummary.currency
    }
    if (!bookBalancesHaveSameCurrency) {
      delete summaries.bookBalanceSummary.value
      delete summaries.bookBalanceSummary.currency
    }
    return summaries
  }, [bcaAmountsData, bookBalancesHaveSameCurrency, getValueOrZero, limitsHaveSameCurrency])

  const addTranchesAndBcaSummary = useCallback(
    (summaryProperty, bcaSummary) => {
      const sameCurrency = !!bcaSummary?.currency && bcaSummary.currency === summary?.currency
      const summaryWithoutHeadquarter = sameCurrency
        ? {
            value: summary[summaryProperty] + bcaSummary.value,
            currency: summary.currency,
          }
        : {}
      return {
        ...summaryWithoutHeadquarter,
        headquarterValue: summaryHeadquarter?.[summaryProperty] + bcaSummary?.headquarterValue,
        headquarterCurrency: summaryHeadquarter?.currency,
      }
    },
    [summary, summaryHeadquarter],
  )

  const totalCommitment = addTranchesAndBcaSummary('totalCommitment', externalLimitSummary)
  const ownCommitment = addTranchesAndBcaSummary('ownCommitment', externalLimitSummary)
  const ownOutstanding = addTranchesAndBcaSummary('outstanding', bookBalanceSummary)

  const data = useMemo(
    () => ({
      totalCommitment,
      ownCommitment,
      ownOutstanding,
      earliestMaturityDate,
    }),
    [earliestMaturityDate, ownCommitment, totalCommitment, ownOutstanding],
  )

  const isFetching = isFetchingTranches || isFetchingBca || isFetchingBcaAmounts
  const isError = isErrorTranches || isErrorBca || isErrorBcaAmounts
  return {
    isFetching,
    isError,
    data,
  }
}
export default useFinancingCardData
