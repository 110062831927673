import { useCallback, useEffect, useMemo, useState } from 'react'
import useGetMaturityData from 'components/domains/deals/deal-cashflow/result-charts/useGetMaturityData'
import { periodValues } from 'components/domains/deals/deal-cashflow/results/PeriodSelect'
import useCashflowGroups from 'hooks/services/deals/cashflow/useCashflowGroups'
import useCashflowScenarioResultsById from 'hooks/services/deals/cashflow/useCashflowScenarioResultsById'

const useDealCashflowKpiSelect = ({
  dealUuid,
  cashflowScenarioUuid,
  defaultKpiCode,
  defaultCategory,
}) => {
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState()
  const [cashflowKpis, setCashflowKpis] = useState([])
  const [selectedCashflowKpi, setSelectedCashflowKpi] = useState()

  const {
    data: { calculatedKpis = [] } = {},
    isLoading: isLoadingData,
    isError: isErrorData,
    isFetching: isFetchingData,
  } = useCashflowScenarioResultsById(
    {
      dealUuid,
      cashflowId: cashflowScenarioUuid,
      datasetPeriod: periodValues.years,
    },
    {
      enabled: !!dealUuid && !!cashflowScenarioUuid,
    },
  )

  const {
    data: { groups = [] } = {},
    isLoading: isLoadingGroups,
    isError: isErrorGroups,
  } = useCashflowGroups()

  const cashflowKpiDict = useMemo(() => {
    const result = {}

    groups.forEach((group) => {
      group.kpis.forEach((kpi) => (result[kpi.ckpiId] = kpi))
    })

    return result
  }, [groups])

  const isLoading = isLoadingGroups || (isLoadingData && isFetchingData)
  const isError = isErrorGroups || isErrorData

  const reduceCashflowKpisByCategory = useCallback(
    (category) =>
      calculatedKpis.reduce((result, current) => {
        if (category !== current.category || !cashflowKpiDict[current.cashflowKpi.ckpiId]) {
          return result
        }

        return [...result, cashflowKpiDict[current.cashflowKpi.ckpiId]]
      }, []),
    [calculatedKpis, cashflowKpiDict],
  )

  useEffect(() => {
    if (!isLoading && categories.length === 0 && calculatedKpis.length > 0) {
      const reducedCategories = calculatedKpis.reduce(
        (result, current) =>
          !result.includes(current.category) && cashflowKpiDict[current.cashflowKpi.ckpiId]
            ? [...result, current.category]
            : result,
        [],
      )
      setCategories(reducedCategories)

      // because category can be null a explicit check for null is needed
      const preSelectedCategory =
        defaultCategory === null ? null : defaultCategory || reducedCategories.slice(-1)[0]
      setSelectedCategory(preSelectedCategory)

      const reducedCashflowKpis = reduceCashflowKpisByCategory(preSelectedCategory)

      setCashflowKpis(reducedCashflowKpis)

      const defaultKpi = reducedCashflowKpis.find((kpi) => kpi.code === defaultKpiCode)
      setSelectedCashflowKpi(defaultKpi?.ckpiId ?? reducedCashflowKpis?.[0]?.ckpiId)
    }
  }, [
    calculatedKpis,
    cashflowKpiDict,
    categories,
    defaultKpiCode,
    groups,
    isLoading,
    reduceCashflowKpisByCategory,
    defaultCategory,
  ])

  const handleOnChangeSelectedCategory = (category) => {
    const newKpis = reduceCashflowKpisByCategory(category || null)

    // category can be null
    const newSelectedCategory = category === '' ? null : category

    setSelectedCategory(newSelectedCategory)
    setCashflowKpis(newKpis)
    setSelectedCashflowKpi(newKpis[0]?.ckpiId)
  }

  const selectedData = calculatedKpis.find(
    (data) => data.category === selectedCategory && data.cashflowKpi.ckpiId === selectedCashflowKpi,
  )

  const { maturityData = {} } = useGetMaturityData(selectedData)

  return {
    selectedData,
    isLoading,
    isError,
    maturityData,
    handleOnChangeSelectedCategory,
    categories,
    cashflowKpis,
    setSelectedCashflowKpi,
    selectedCashflowKpi,
    selectedCategory,
  }
}

export default useDealCashflowKpiSelect
