import { ENTITIES } from 'components/domains/deals/change-overview/constants'

export const ROWS = {
  GENERAL_INFORMATION: 'general-information',
  FINANCING: 'financing',
  PROPERTIES: 'properties',
  CASH_FLOW: 'cash-flow',
  CONDITIONS: 'conditions',
  COVENANTS: 'covenants',
  SYNDICATION: 'syndication',
  DOCUMENTS: 'documents',
}

export const FILTER_TYPES = {
  PAGE: 'page',
  ENTITY_TYPE: 'entity_type',
}

export const convertFilter = (filter) => {
  switch (filter.filterType) {
    case FILTER_TYPES.PAGE:
      return {
        pageId: filter.filter,
      }
    case FILTER_TYPES.ENTITY_TYPE:
      return {
        entityType: filter.filter,
      }
    default:
      return null
  }
}

export const GENERAL_INFORMATION_FILTER = {
  filterType: FILTER_TYPES.PAGE,
  filter: 'deals-general-information',
}

export const FINANCING_FILTER = {
  filterType: FILTER_TYPES.PAGE,
  filter: 'deals-financing,tranche',
}

export const PROPERTIES_FILTER = {
  filterType: FILTER_TYPES.PAGE,
  filter: 'deals-properties,deals-properties-appreciation',
}

export const CASH_FLOW_FILTER = {
  filterType: FILTER_TYPES.PAGE,
  filter: 'deals-cashflows,cashflow-scenario',
}

export const CONDITIONS_FILTER = {
  filterType: FILTER_TYPES.PAGE,
  filter: 'external-condition,internal-condition,external-condition-requirement',
}

export const COVENANTS_FILTER = {
  filterType: FILTER_TYPES.PAGE,
  filter: 'deals-covenants,covenant',
}

export const SYNDICATION_FILTER = {
  filterType: FILTER_TYPES.PAGE,
  filter: 'deals-syndication',
}

export const DOCUMENTS_FILTER = {
  filterType: FILTER_TYPES.ENTITY_TYPE,
  filter: ENTITIES.DOCUMENT,
}
