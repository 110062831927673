import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import merge from 'lodash.merge'
import { useCallback, useEffect, useState } from 'react'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'

export const mapStaffMemberToFormState = (member = {}) => ({
  virtualId: member.virtualId,
  leader: member.leader,
  sapRelationId: member.relation?.sapRelationId,
  userId: member.userId,
})

const useHandleFormState = ({ staffMembers = [] }) => {
  const [formState, setFormState] = useState([])

  const addNewRowToFormState = useCallback(
    (mappedStaffMembers) => {
      const newRow = formState.find(({ virtualId }) => virtualId === rowKeyNewRow)
      if (!isEmpty(newRow)) {
        mappedStaffMembers.push(newRow)
      }
      return mappedStaffMembers
    },
    [formState],
  )

  useEffect(() => {
    setFormState(addNewRowToFormState(staffMembers.map(mapStaffMemberToFormState)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffMembers])

  const findRow = (rowKey) => ({ ...formState.find((row) => row.virtualId === rowKey) })

  const removeChangeFromFormState = (rowKey) => {
    const originalStaffMember = staffMembers.find((member) => member.virtualId === rowKey) ?? {}
    const newFormState = formState
      .map((row) => {
        if (row.virtualId === originalStaffMember.virtualId) {
          return mapStaffMemberToFormState(originalStaffMember)
        }
        if (row.virtualId === rowKeyNewRow && rowKey === rowKeyNewRow) {
          return null
        }
        return row
      })
      .filter(Boolean)
    setFormState(newFormState)
    return newFormState
  }

  const updateRow = (rowKey, keyValues = {}) => {
    const editRowsUpdated = formState.map((oldRow) => {
      if (oldRow.virtualId !== rowKey) {
        return oldRow
      }
      return merge(cloneDeep(oldRow), keyValues)
    })

    const formStateHasRowWithNewRowKey = formState.find((item) => item.virtualId === rowKeyNewRow)
    if (rowKey === rowKeyNewRow && !formStateHasRowWithNewRowKey) {
      editRowsUpdated.push(merge({ virtualId: rowKeyNewRow }, keyValues))
    }
    setFormState([...editRowsUpdated])
    return editRowsUpdated
  }

  return {
    findRow,
    removeChangeFromFormState,
    updateRow,
    formState,
  }
}

export default useHandleFormState
