import {
  BusyIndicatorSize,
  IllustratedMessage,
  IllustrationMessageType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CardSection from 'components/domains/deals/card/CardSection'
import styles from 'components/domains/deals/overview/syndication/DealOverviewSyndicationCard.module.css'
import { SyndicationStructureColorContext } from 'components/domains/deals/overview/syndication/SyndicationStructureColorContext'
import useDealOverviewSyndicationStructureChartData from 'components/domains/deals/overview/syndication/useDealOverviewSyndicationStructureChartData'
import DonutChart from 'components/ui/charts/donut-chart/DonutChart'
import LabelValueList from 'components/ui/data/LabelValueList'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useSyndicationRoles from 'hooks/services/deals/financing/useSyndicationRoles'
import useSyndicationsExistingBusiness from 'hooks/services/deals/syndication/useSyndicationsExistingBusiness'

const DealOverviewSyndicationExistingBusinessContent = ({ dealUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.syndication',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const { tooltipMaxTrancheNameLength, tooltipCuttedTrancheNameLength } = useContext(
    SyndicationStructureColorContext,
  )
  const {
    data: syndicationData,
    isFetching: isFetchingSyndications,
    isError: isErrorSyndications,
  } = useSyndicationsExistingBusiness(dealUuid)

  const bpIds = useMemo(
    () => syndicationData?.syndications?.map(({ bpId } = {}) => bpId) || [],
    [syndicationData?.syndications],
  )
  const {
    data: { businessPartnerMinis: businessPartners = [] } = {},
    isFetching: isFetchingBp,
    isError: isErrorBp,
  } = useBusinessPartnerMiniByIds(bpIds)

  const {
    data: { configuration: { SYNDICATIONROLE: roles } = {} } = {},
    isFetching: isFetchingSyndicationRoles,
    isError: isErrorFinancialRoles,
  } = useSyndicationRoles()
  const {
    data: chartData,
    isFetching: isFetchingChartData,
    isError: isErrorChartData,
  } = useDealOverviewSyndicationStructureChartData(syndicationData, businessPartners)

  const formatCurrency = useCustomizableCurrencyFormatter({
    notation: 'compact',
  })

  const roleOfOwnShare = useMemo(() => {
    const customerSyndicationRole = syndicationData?.syndicationDetails?.customerSyndicationRole
    if (customerSyndicationRole) {
      return (
        roles?.find(({ code }) => code === customerSyndicationRole)?.shortText ??
        customerSyndicationRole
      )
    }
    return '-'
  }, [roles, syndicationData?.syndicationDetails?.customerSyndicationRole])

  const getTooltip = ({ payload = [] } = {}) => {
    const { tranches = [], currency: sharedCurrency } = payload[0]?.payload ?? {}
    const { total, toolTipData } = tranches.reduce(
      (result, { trancheId, value, currency }, i) => {
        result.total += value
        result.toolTipData.push({
          rowKey: i,
          label:
            trancheId?.length > tooltipMaxTrancheNameLength
              ? trancheId.substring(0, tooltipCuttedTrancheNameLength) + '...'
              : trancheId,
          value: formatCurrency(value, currency),
        })
        return result
      },
      { total: 0, toolTipData: [] },
    )

    toolTipData.push({
      rowKey: 'total',
      label: t('total'),
      value: formatCurrency(total, sharedCurrency),
    })

    return (
      <div className={styles.tooltip}>
        <LabelValueList rows={toolTipData} />
      </div>
    )
  }
  const isFetching = useMemo(
    () => isFetchingSyndications || isFetchingBp || isFetchingChartData,
    [isFetchingBp, isFetchingSyndications, isFetchingChartData],
  )
  const isError = useMemo(
    () => isErrorSyndications || isErrorBp || isErrorChartData,
    [isErrorSyndications, isErrorBp, isErrorChartData],
  )

  return (
    <div className={compact([isFetching && styles.loadingWrapper]).join(' ')}>
      <RequestStateResolver
        isLoading={isFetching}
        isError={isError}
        errorToDisplay={
          <IllustratedMessage
            name={IllustrationMessageType.UnableToLoad}
            titleText={tNoPrefix('components.ui.card.empty-card.title')}
            subtitleText={tNoPrefix('components.ui.card.empty-card.subtitle')}
          />
        }
        center
        renderContent={() => (
          <>
            <CardSection title={t('commitment-share')}>
              <DonutChart data={chartData} tooltipContent={getTooltip} />
            </CardSection>
            <CardSection title={t('roles-of-own-share')}>
              <RequestStateResolver
                isLoading={isFetchingSyndicationRoles}
                busyIndicatorSize={BusyIndicatorSize.Small}
                isError={isErrorFinancialRoles}
                errorToDisplay={<ErrorDataUnavailableInCell />}
                renderContent={() => <Text>{roleOfOwnShare}</Text>}
              />
            </CardSection>
          </>
        )}
      />
    </div>
  )
}
DealOverviewSyndicationExistingBusinessContent.propTypes = {
  dealUuid: PropTypes.string.isRequired,
}

export default DealOverviewSyndicationExistingBusinessContent
