import { useTranslation } from 'react-i18next'
import useLeadingMemberByRelationField from 'components/domains/deals/overview/useLeadingMemberByRelationField'
import { useDealTypes } from 'hooks/services/deals/configurations/useBusinessSegments'
import { useOriginationTeams } from 'hooks/services/deals/configurations/useOriginationTeams'
import useDealTeam from 'hooks/services/deals/deal-team/useDealTeam'

const useGeneralInformationFields = ({
  dealUuid,
  businessSegmentCode,
  dealTypeCode,
  originationTeamCode,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.general-information.information',
  })

  const { data: { staffMembers = [] } = {} } = useDealTeam({ dealUuid: dealUuid })
  const {
    isLoading: isLoadingDealTypes,
    isError: isErrorDealTypes,
    data: dealTypes = [],
  } = useDealTypes(businessSegmentCode)
  const {
    isFetching: isFetchingOriginationTeams,
    isError: isErrorOriginationTeams,
    data: originationTeams = [],
  } = useOriginationTeams(businessSegmentCode, {
    enabled: !!originationTeamCode && !!businessSegmentCode,
  })

  const {
    data: { memberField: accountManagerField },
  } = useLeadingMemberByRelationField(staffMembers, 'ACCOUNT_MANAGER', 'account-manager')
  const {
    data: { memberField: loanAccountManagerField },
  } = useLeadingMemberByRelationField(staffMembers, 'LOAN_ACCOUNT_MANAGER', 'loan-account-manager')

  const getNameByCode = (config, code) => config.find((item) => item.code === code)?.name

  return {
    isLoading: isLoadingDealTypes || isFetchingOriginationTeams,
    isError: isErrorDealTypes || isErrorOriginationTeams,
    data: [
      {
        label: t('business-segment'),
        name: 'businessSegmentCode',
        value: businessSegmentCode,
      },
      {
        label: t('deal-type'),
        name: 'dealTypeCode',
        value: getNameByCode(dealTypes, dealTypeCode),
      },
      accountManagerField,
      loanAccountManagerField,
      {
        label: t('origination-team'),
        name: 'originationTeamCode',
        value: getNameByCode(originationTeams, originationTeamCode),
      },
    ],
  }
}

export default useGeneralInformationFields
