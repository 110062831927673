import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteDealTeamMember = ({ onSuccess, onError }) => {
  const { delete: deleteTeamMember } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, virtualId }) =>
      await deleteTeamMember({
        path: `/deals/${dealUuid}/deal-teams/${virtualId}`,
      }),
    { onSuccess, onError },
  )
}

export default useDeleteDealTeamMember
