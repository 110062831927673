import { IllustrationMessageType, Select, Option, Text } from '@fioneer/ui5-webcomponents-react'
import { groupBy } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ResponsiveContainer, XAxis, YAxis, BarChart, Bar, Legend } from 'recharts'
import {
  conditionTypes,
  conditionExternalTypes,
  conditionInternalTypes,
  conditionsStatusTypes,
  conditionsEntityTypes,
} from 'api/conditions/conditions'
import CardSection from 'components/domains/deals/card/CardSection'
import DealOverviewConditionsBarLabel from 'components/domains/deals/overview/conditions/DealOverviewConditionsBarLabel'
import styles from 'components/domains/deals/overview/conditions/DealOverviewConditionsCard.module.css'
import { useGetAndAppendWorkingVersionSearchParam } from 'components/domains/deals/useGetAndAppendWorkingVersionSearchParam'
import Card from 'components/ui/card/Card'
import CtrlClickableCardHeader from 'components/ui/card/CtrlClickableCardHeader'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import useCreateNavigateClickHandler from 'components/ui/link/useCreateNavigateClickHandler'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useExternalStatus from 'hooks/services/conditions/config/useExternalStatus'
import useExternalTypes from 'hooks/services/conditions/config/useExternalTypes'
import useInternalStatus from 'hooks/services/conditions/config/useInternalStatus'
import useInternalTypes from 'hooks/services/conditions/config/useInternalTypes'
import { useConditionsForEntityByType } from 'hooks/services/conditions/useConditions'

const BAR_SIZE = 26
const LEGEND_SPACE = 30

const DealOverviewConditionsCard = ({ pageData }) => {
  const {
    deal: { dealUuid },
  } = pageData

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.conditions',
  })
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const createClickHandler = useCreateNavigateClickHandler()
  const { appendWorkingVersionSearchParam } = useGetAndAppendWorkingVersionSearchParam()

  const {
    isLoading: isLoadingExternalStatus,
    isError: isErrorExternalStatus,
    data: { status: externalStatusCodes = [] } = {},
  } = useExternalStatus()
  const {
    isLoading: isLoadingInternalStatus,
    isError: isErrorInternalStatus,
    data: { status: internalStatusCodes = [] } = {},
  } = useInternalStatus()

  const { data: { types: internalTypes = [] } = {} } = useInternalTypes()
  const { data: { types: externalTypes = [] } = {} } = useExternalTypes()

  const labels = {
    [conditionExternalTypes.precedent]: t('precedent'),
    [conditionExternalTypes.subsequent]: t('subsequent'),
    [conditionInternalTypes.signing]: t('signing'),
    [conditionInternalTypes.drawdown]: t('drawdown'),
    [conditionInternalTypes.dealCommittee]: t('deal-committee'),
    [conditionInternalTypes.existingBusiness]: t('existing-business'),
    [conditionInternalTypes.creditDecision]: t('credit-decision'),
    [conditionInternalTypes.postDrawdown]: t('post-drawdown'),
    OTHER: t('other'),
  }

  const [selectedConditionType, setSelectedConditionType] = useState(conditionTypes.external)

  const {
    isLoading: isLoadingConditions,
    isError: isErrorConditions,
    data = {},
  } = useConditionsForEntityByType({
    conditionType: selectedConditionType,
    entityId: dealUuid,
    entityType: conditionsEntityTypes.deal,
  })

  const isExternal = selectedConditionType === conditionTypes.external
  const currentStatusCodes = isExternal ? externalStatusCodes : internalStatusCodes
  const currentConditionTypeCodes = isExternal
    ? externalTypes.map(({ code }) => code)
    : internalTypes.map(({ code }) => code)

  const groupedConditions = isExternal
    ? groupBy(data.externalConditions, 'info.type.code')
    : groupBy(data.internalConditions, 'info.type.code')

  const groupedConditionsByTypeAndStatus = []

  Object.keys(groupedConditions).forEach((type) => {
    const typeStatusMap = {
      type,
    }

    const groupedByStatus = groupBy(groupedConditions[type], 'status.type')

    Object.keys(groupedByStatus).forEach((status) => {
      if (currentStatusCodes.find(({ type: statusCodeType }) => statusCodeType === status)) {
        typeStatusMap[status] = groupedByStatus[status].length
      }
    })

    groupedConditionsByTypeAndStatus.push(typeStatusMap)
  })

  const sortedGroupedConditionsByTypeAndStatus = groupedConditionsByTypeAndStatus
    .slice()
    .sort(
      (a, b) =>
        currentConditionTypeCodes.indexOf(a.type) - currentConditionTypeCodes.indexOf(b.type),
    )

  const isLoading = isLoadingConditions || isLoadingExternalStatus || isLoadingInternalStatus
  const isError =
    isErrorConditions || data.total === 0 || isErrorExternalStatus || isErrorInternalStatus

  const handleClickOnBarSegment = (conditionType, statusType) => {
    const statusCode = currentStatusCodes.find(({ type }) => type === statusType).code

    navigate(
      `${pathname}/conditions?con_businessType=${selectedConditionType}&con_type=${conditionType}&con_status=${statusCode}`,
    )
  }

  const conditionsBasePath = `${pathname}conditions`
  const onHeaderClick = createClickHandler(appendWorkingVersionSearchParam(conditionsBasePath))

  return (
    <Card
      header={
        <CtrlClickableCardHeader
          action={
            <Select
              onChange={({
                detail: {
                  selectedOption: { value },
                },
              }) => setSelectedConditionType(value)}
            >
              <Option value={conditionTypes.external}> {t('external')}</Option>
              <Option value={conditionTypes.internal}> {t('internal')}</Option>
            </Select>
          }
          titleText={t('title')}
          onClick={onHeaderClick}
          interactive
        />
      }
    >
      <RequestStateResolver
        isLoading={isLoading}
        isError={isError}
        errorToDisplay={
          <EmptyCardContent illustrationName={IllustrationMessageType.UnableToLoad} />
        }
        renderContent={() => (
          <CardSection>
            <ResponsiveContainer
              width="100%"
              height={BAR_SIZE * sortedGroupedConditionsByTypeAndStatus.length + LEGEND_SPACE}
            >
              <BarChart
                layout="vertical"
                data={sortedGroupedConditionsByTypeAndStatus}
                stackOffset="expand"
                barGap={1}
                barSize={16}
                barCategoryGap={1}
              >
                <XAxis hide type="number" />
                <YAxis
                  width={42}
                  type="category"
                  dataKey="type"
                  stroke="#32363A"
                  fontSize="12"
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={(tick) => labels[tick]}
                />

                <Legend
                  payload={[
                    {
                      value: <Text className={styles.legendText}>{t('legend.open')}</Text>,
                      type: 'square',
                      color: '#DF6E0C',
                      payload: {},
                    },
                    {
                      value: <Text className={styles.legendText}>{t('legend.in-progress')}</Text>,
                      type: 'square',
                      color: '#0A6ED1',
                      payload: {},
                    },
                    {
                      value: <Text className={styles.legendText}>{t('legend.done')}</Text>,
                      type: 'square',
                      color: '#107E3E',
                      payload: {},
                    },
                  ]}
                />

                <Bar
                  dataKey={conditionsStatusTypes.open}
                  fill="#DF6E0C"
                  stackId="a"
                  label={(props) => (
                    <DealOverviewConditionsBarLabel
                      {...props}
                      dataKey={conditionsStatusTypes.open}
                      data={sortedGroupedConditionsByTypeAndStatus}
                    />
                  )}
                  className={styles.bar}
                  onClick={(e) => handleClickOnBarSegment(e.type, conditionsStatusTypes.open)}
                />

                <Bar
                  dataKey={conditionsStatusTypes.inProgress}
                  fill="#0A6ED1"
                  stackId="a"
                  label={(props) => (
                    <DealOverviewConditionsBarLabel
                      {...props}
                      dataKey={conditionsStatusTypes.inProgress}
                      data={sortedGroupedConditionsByTypeAndStatus}
                    />
                  )}
                  onClick={(e) => handleClickOnBarSegment(e.type, conditionsStatusTypes.inProgress)}
                />
                <Bar
                  dataKey={conditionsStatusTypes.done}
                  fill="#107E3E"
                  stackId="a"
                  label={(props) => (
                    <DealOverviewConditionsBarLabel
                      {...props}
                      dataKey={conditionsStatusTypes.done}
                      data={sortedGroupedConditionsByTypeAndStatus}
                    />
                  )}
                  onClick={(e) => handleClickOnBarSegment(e.type, conditionsStatusTypes.done)}
                />
              </BarChart>
            </ResponsiveContainer>
          </CardSection>
        )}
      />
    </Card>
  )
}

DealOverviewConditionsCard.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default DealOverviewConditionsCard
