import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { DateTime } from 'luxon'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isNotFoundError } from 'api/requests'
import { assessmentTypes } from 'components/domains/business-partners/tile/assessments/assessmentTypes'
import useRegulatoryInformation from 'components/domains/business-partners/tile/general-information/regulatoryInformation/useRegulatoryInformations'
import { ConfigContext } from 'hooks/config/useConfig'
import useFinancialIndicatorsHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/financial-indicators/useFinancialIndicatorsHelper'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import {
  getLegalDataAndFoundationDatesFromMembers,
  convertLegalDataObjectToString,
} from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/borrower/useBorrowerOverviewUtils'
import { DEFAULT_RATING_AGENCY_PRIORITY } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useTenantDetails'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import { useAssessments } from 'hooks/services/business-partners/assessments/useAssessments'
import { useTextualAssessments } from 'hooks/services/business-partners/assessments/useTextualAssessments'
import {
  useBusinessPartnerById,
  useBusinessPartnersByIds,
} from 'hooks/services/business-partners/getBusinessPartners'
import { useFiscalYears } from 'hooks/services/business-partners/kpis/useFiscalYears'
import useBusinessPartnerRatings from 'hooks/services/business-partners/ratings/useBusinessPartnerRatings'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import useDealMini from 'hooks/services/deals/useDealMini'

const language = 'en-US'

const useBorrowerOverview = ({ entityRef: { entityId: dealUuid } }, tileId, tileCode) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.bp-borrower-overview',
  })

  const {
    ratingAgencyPriority = DEFAULT_RATING_AGENCY_PRIORITY,
    businessPartner: {
      sapRelationshipIds: { jointAccount: jointAccountId, borrower: borrowerRelationshipId } = {},
    } = {},
    decisionPaper: { financialIndicators: financialIndicatorsFromConfig } = {},
  } = useContext(ConfigContext)

  const { isTileConfigError, getFinancialIndicators } = useFinancialIndicatorsHelper(
    financialIndicatorsFromConfig,
  )

  const sortExternalRatingsByAgencyPriority = useCallback(
    (a, b) =>
      (ratingAgencyPriority.includes(a.agency.id)
        ? ratingAgencyPriority.indexOf(a.agency.id)
        : ratingAgencyPriority.length) -
      (ratingAgencyPriority.includes(b.agency.id)
        ? ratingAgencyPriority.indexOf(b.agency.id)
        : ratingAgencyPriority.length),
    [ratingAgencyPriority],
  )

  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({
    dealUuid,
    tileCode,
  })

  const {
    isFetching: isDealFetching,
    isError: isDealError,
    data: dealData,
    error: dealError,
  } = useDealMini(dealUuidByTileCode)

  const businessPartnerId = dealData?.borrowerBpId

  // each following hook is disabled when BpId is undefined
  const borrowerBpIdIsPresent = useMemo(() => !isNil(businessPartnerId), [businessPartnerId])
  const {
    isFetching: isBorrowerFetching,
    isError: isBorrowerError,
    data: borrowerData,
    error: borrowerError,
  } = useBusinessPartnerById(businessPartnerId, {}, { language })

  const {
    data: regulatoryInformationData,
    isFetching: isRegulatoryInformationFetching,
    isError: isRegulatoryInformationError,
    error: regulatoryInformationError,
  } = useRegulatoryInformation({ businessPartnerId }, { language })

  const regulatoryInformationsNotFound = useMemo(
    () => isRegulatoryInformationError && isNotFoundError(regulatoryInformationError),
    [isRegulatoryInformationError, regulatoryInformationError],
  )

  const {
    isFetching: isAssessmentFetching,
    isError: isAssessmentError,
    data: assessmentData,
    error: assessementError,
  } = useAssessments(businessPartnerId, { enabled: borrowerBpIdIsPresent }, { language })

  const {
    isFetching: isRatingsFetching,
    isError: isRatingsError,
    data: ratingsData,
    error: ratingsError,
  } = useBusinessPartnerRatings(businessPartnerId)

  const {
    isFetching: isFiscalYearsFetching,
    isError: isFiscalYearsError,
    data: fiscalYearsData,
    error: fiscalYearsError,
  } = useFiscalYears(businessPartnerId)

  const {
    isFetching: isTextualAssessmentFetching,
    isError: isTextualAssessmentError,
    data: textualAssessmentData,
    error: textualAssessementError,
  } = useTextualAssessments(
    { businessPartnerId, type: assessmentTypes.Description },
    { enabled: borrowerBpIdIsPresent },
  )

  const {
    isError: isBorrowerRelationshipError,
    data: borrowerRelationshipData,
    error: borrowerRelationshipError,
  } = useBusinessPartnerRelationships(businessPartnerId, { enabled: borrowerBpIdIsPresent })

  const borrowerGroupMemberIds = useMemo(() => {
    if (isNil(borrowerRelationshipData)) {
      return []
    }
    const { unitRelationships } = borrowerRelationshipData
    let borrowerRelationships = unitRelationships.find(({ id }) => id === jointAccountId)
    if (!borrowerRelationships) {
      borrowerRelationships = unitRelationships.find(({ id }) => id === borrowerRelationshipId)
    }
    return borrowerRelationships?.relationships
      ?.find(({ head }) => head.id === businessPartnerId)
      ?.members.map(({ id }) => id)
  }, [borrowerRelationshipData, borrowerRelationshipId, businessPartnerId, jointAccountId])

  const {
    isFetching: isBorrowerGroupMembersFetching,
    isError: isBorrowerGroupMembersError,
    data: borrowerGroupMembersData,
    error: borrowerGroupMembersError,
  } = useBusinessPartnersByIds(borrowerGroupMemberIds)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealUuidFetching,
      isDealFetching,
      isBorrowerFetching,
      isRegulatoryInformationFetching,
      isAssessmentFetching,
      isRatingsFetching,
      isFiscalYearsFetching,
      isTextualAssessmentFetching,
      isBorrowerGroupMembersFetching,
    ],
    errorValues: [
      isDealUuidError,
      isDealError,
      isBorrowerError,
      isRegulatoryInformationError && !regulatoryInformationsNotFound,
      isAssessmentError,
      isRatingsError,
      isFiscalYearsError,
      isBorrowerRelationshipError,
      isBorrowerGroupMembersError,
      isTextualAssessmentError,
    ],
    errorDetails: [
      dealError,
      borrowerError,
      regulatoryInformationsNotFound ? undefined : regulatoryInformationError,
      assessementError,
      ratingsError,
      fiscalYearsError,
      borrowerRelationshipError,
      borrowerGroupMembersError,
      textualAssessementError,
    ],
    tileId,
  })

  const fiscalData = useMemo(() => {
    if (isNil(fiscalYearsData?.fiscal_years)) {
      return undefined
    }
    const fiscalYears = fiscalYearsData.fiscal_years
    let latestYear = 0
    for (const yearString in fiscalYears) {
      const year = +yearString
      if (year > latestYear) {
        latestYear = year
      }
    }
    return fiscalYears[String(latestYear)]
  }, [fiscalYearsData?.fiscal_years])

  const externalRating = useMemo(() => {
    const today = DateTime.now()
    return (ratingsData?.external ?? [])
      .filter((rating) => {
        const validFromDate = DateTime.fromISO(rating.validFrom)
        const validToDate = DateTime.fromISO(rating.validTo)
        return validFromDate <= today && validToDate >= today
      })
      .sort(sortExternalRatingsByAgencyPriority)
  }, [ratingsData?.external, sortExternalRatingsByAgencyPriority])?.[0]

  const internalRating = useMemo(
    () => (ratingsData?.internal ?? []).find((rating) => rating.isActive),
    [ratingsData?.internal],
  )
  const appreciation = useMemo(() => {
    if (isEmpty(textualAssessmentData?.availableVersions)) {
      return undefined
    }
    const { availableVersions } = textualAssessmentData
    // Sort descending by version number to return the content with highest version
    return [...availableVersions].sort(
      ({ version: firstVersion }, { version: secondVersion }) => secondVersion - firstVersion,
    )[0]?.content
  }, [textualAssessmentData])

  return useMemo(() => {
    if (isTileConfigError) {
      return {
        isLoading: isSomeValueLoading,
        isError: true,
        error: {
          isTileConfigError,
          customErrorMessage: t('error.config'),
        },
      }
    }

    if (isSomeValueLoading || isSomeValueError) {
      return {
        isError: isSomeValueError,
        isLoading: isSomeValueLoading,
        error,
      }
    }

    const { legalFormMap, foundationDatesMap } = getLegalDataAndFoundationDatesFromMembers(
      borrowerGroupMembersData ?? [],
    )

    const legalForm = !isEmpty(borrowerGroupMembersData)
      ? convertLegalDataObjectToString(legalFormMap)
      : borrowerData?.legalForm

    const foundationDates =
      isNil(foundationDatesMap.min) || isNil(foundationDatesMap.max)
        ? { min: borrowerData?.foundationDate, max: borrowerData?.foundationDate }
        : foundationDatesMap

    return {
      isError: false,
      isLoading: false,
      data: {
        information: isNil(borrowerData)
          ? undefined
          : {
              businessPartnerId,
              name: borrowerData.fullName,
              legalForm: legalForm,
              foundationDates,
              industryInformation: borrowerData.industry,
              lastKycCheck: borrowerData.kyc?.lastCheckDate,
              kycResult: regulatoryInformationData?.amlRiskClassification?.name,
              overallQualityAssessment: {
                category: assessmentData?.assessments?.[0]?.category,
                level: assessmentData?.assessments?.[0]?.level,
                levelDisplayValue: assessmentData?.assessments?.[0]?.levelDisplayValue,
              },
            },
        address: isNil(borrowerData?.address)
          ? undefined
          : {
              streetName: borrowerData.address.streetName,
              postalCode: borrowerData.address.postalCode,
              city: borrowerData.address.city,
              region: borrowerData.address.region,
              country: borrowerData.address.country,
            },
        internalRating: isNil(internalRating)
          ? undefined
          : {
              class: internalRating.ratingClass,
              method: internalRating.method?.name,
              validFrom: internalRating.validFrom,
            },
        externalRating: isNil(externalRating)
          ? undefined
          : {
              grade: externalRating.grade,
              agency: externalRating.agency?.name,
              validFrom: externalRating.validFrom,
            },
        financialIndicators: getFinancialIndicators(fiscalData),
        appreciation,
        sourceRender: { businessPartnerId },
      },
    }
  }, [
    appreciation,
    assessmentData?.assessments,
    borrowerData,
    borrowerGroupMembersData,
    businessPartnerId,
    error,
    externalRating,
    fiscalData,
    internalRating,
    isSomeValueError,
    isSomeValueLoading,
    regulatoryInformationData?.amlRiskClassification?.name,
    isTileConfigError,
    getFinancialIndicators,
    t,
  ])
}

export default useBorrowerOverview
