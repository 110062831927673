import { useCallback, useMemo } from 'react'
import useBusinessPartnerRelationshipUnitTypes from 'hooks/services/business-partners/config/useBusinessPartnerRelationshipUnitTypes'
import useRelationshipTypes from 'hooks/services/business-partners/config/useRelationshipTypes'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import useUnitRelationships from 'hooks/services/deals/configurations/useUnitRelationships'

const deriveIsRelatedBusinessPartnerBp2 = (unitHeadSide) =>
  ({
    1: false,
    2: true,
  }[unitHeadSide] ?? false)

const hasEditableRelationshipTypes = (relationshipTypes, heads) =>
  !heads?.length ||
  relationshipTypes.some(
    (type) =>
      type.modifiable && !heads.some(({ relationshipType }) => relationshipType.id === type.id),
  )

const isDeletableRelationship = (relationshipTypes, relationshipId) =>
  !relationshipTypes.length ||
  relationshipTypes.some(
    (relationshipType) => relationshipType.id === relationshipId && relationshipType.modifiable,
  )

const useBorrowerUnitsLoader = (borrowerBpId) => {
  const {
    data: { unitRelationships: dealRelationshipUnitTypes = [] } = {},
    isLoading: isLoadingUnitRelationshipsConfig,
    isError: isErrorUnitRelationshipsConfig,
  } = useUnitRelationships()
  const {
    data: { unitRelationships = [] } = {},
    isLoading: isLoadingUnitRelationships,
    isError: isErrorUnitRelationships,
  } = useBusinessPartnerRelationships(borrowerBpId)
  const {
    data: { unitRelationships: businessPartnerRelationshipUnitTypes = [] } = {},
    isLoading: isLoadingUnitRelationshipTypesConfig,
    isError: isErrorUnitRelationshipTypesConfig,
  } = useBusinessPartnerRelationshipUnitTypes()
  const {
    isLoading: isLoadingRelationshipTypes,
    isError: isErrorRelationshipTypes,
    data: { types = [] } = {},
  } = useRelationshipTypes()
  const {
    isLoading: isLoadingAllowedOperations,
    isError: isErrorAllowedOperations,
    data: { allowedOperations = [], ...borrowerBp } = {},
  } = useBusinessPartnerById(borrowerBpId)

  const getRelationshipHeads = useCallback(
    (dealRelationshipUnitTypeId, relationshipTypes, modifiableUnit) => {
      const { relationships = [] } =
        unitRelationships.find(({ id }) => id === dealRelationshipUnitTypeId) ?? {}

      return relationships.reduce((relationshipHeads, { members = [], head = {} }) => {
        const borrowerMember = members.find((member) => member.id === borrowerBpId)
        if (borrowerMember) {
          const isDeletableUnitHead =
            modifiableUnit &&
            isDeletableRelationship(relationshipTypes, borrowerMember.relationshipType.id)
          relationshipHeads.push({
            id: head.id,
            fullName: head.fullName,
            relationshipType: borrowerMember.relationshipType,
            isDeletable: isDeletableUnitHead,
          })
        }
        return relationshipHeads
      }, [])
    },
    [borrowerBpId, unitRelationships],
  )

  // TODO: Replace reduce with simple map once relationshipTypes is array of string.
  // ATM the deals BE has a duplicate config for modifiable state of relationship types. This config is ignored by
  // the bp service and hence needs to be removed.
  const mapBusinessPartnerRelationshipTypes = useCallback(
    (relationshipTypes, modifiableTypes) =>
      relationshipTypes.reduce((result, { id }) => {
        if (id) {
          const { name } = types.find((type) => type.id === id) ?? {}
          const modifiable = modifiableTypes?.includes(id)
          result.push({ id, name, modifiable })
        }
        return result
      }, []),
    [types],
  )
  const getRelationshipUnitTypeConfig = useCallback(
    (id) => businessPartnerRelationshipUnitTypes.find(({ categoryId }) => categoryId === id) ?? {},
    [businessPartnerRelationshipUnitTypes],
  )

  const { units, unitsCount } = useMemo(
    () =>
      dealRelationshipUnitTypes.reduce(
        (
          result,
          {
            id,
            relationshipTypes: dealRelationshipTypes,
            multipleUnitsModifiable: allowMultipleOccurrences,
            roleIds,
          },
        ) => {
          const { categoryName, unitHeadSide, isModifiable, modifiableTypes } =
            getRelationshipUnitTypeConfig(id)
          const relationshipTypes = mapBusinessPartnerRelationshipTypes(
            dealRelationshipTypes,
            modifiableTypes,
          )
          const heads = getRelationshipHeads(id, relationshipTypes, isModifiable)

          const isAddable =
            isModifiable &&
            (allowMultipleOccurrences || hasEditableRelationshipTypes(relationshipTypes, heads))

          result.units.push({
            id,
            name: categoryName,
            roleIds,
            isRelatedBusinessPartnerBp2: deriveIsRelatedBusinessPartnerBp2(unitHeadSide),
            relationshipTypes,
            heads,
            isAddable,
          })
          result.unitsCount += heads.length

          return result
        },
        { units: [], unitsCount: 0 },
      ),
    [
      dealRelationshipUnitTypes,
      getRelationshipUnitTypeConfig,
      getRelationshipHeads,
      mapBusinessPartnerRelationshipTypes,
    ],
  )

  return {
    isLoading:
      isLoadingUnitRelationships ||
      isLoadingUnitRelationshipsConfig ||
      isLoadingUnitRelationshipTypesConfig ||
      isLoadingAllowedOperations ||
      isLoadingRelationshipTypes,
    isError:
      isErrorUnitRelationships ||
      isErrorUnitRelationshipsConfig ||
      isErrorUnitRelationshipTypesConfig ||
      isErrorAllowedOperations ||
      isErrorRelationshipTypes,
    data: {
      units,
      unitsCount,
      allowedOperations,
      borrowerBp,
    },
  }
}

export default useBorrowerUnitsLoader
