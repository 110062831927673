import { Text } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/overview/useLeadingMemberByRelationField.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'

const getMembersByRelation = (dealTeamMembers, relation) => {
  if (!dealTeamMembers) {
    return []
  }
  return dealTeamMembers.filter((member) => member.relation.internalDealTeamRelation === relation)
}

const getLeadingMember = (members) => {
  if (members.length === 1) {
    return members[0]
  }
  return members.find((member) => member.leader)
}

const useLeadingMemberByRelationField = (dealTeam, relation, keySuffix) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.information-card',
  })

  const members = getMembersByRelation(dealTeam, relation)
  const leadingMember = getLeadingMember(members)
  const userId = leadingMember?.userId
  const { isLoading, isFetching, isError, data: staffMember } = useStaffMemberById(userId)

  const renderContent = () => {
    if (!members.length) return <Text className={styles.text}>{t(`no-${keySuffix}`)}</Text>
    return (
      <Text className={styles.text}>
        {!leadingMember ? t(`no-leading-${keySuffix}`) : staffMember.fullName}
      </Text>
    )
  }

  const memberField = {
    label: t(keySuffix),
    value: (
      <SmallLoadingWrapper
        //if members length equals zero isLoading stays true indefinitely -> busy indicator shown indefinitely
        isLoading={isLoading && !!members?.length}
        isError={isError}
        error={userId ?? t('empty-value')}
        renderContent={renderContent}
      />
    ),
    isShownInEdit: false,
    isShownInDisplay: true,
  }

  return { isLoading: isLoading && isFetching, isError, data: { memberField } }
}

export default useLeadingMemberByRelationField
