import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { useCallback, useMemo } from 'react'

const useFinancialIndicatorsHelper = ({
  turnover,
  ebitda,
  netResult,
  totalAssets,
  equityCapital,
  equityRatio,
} = {}) => {
  const isTileConfigError = useMemo(
    () =>
      isEmpty(turnover?.key) ||
      isEmpty(turnover?.label) ||
      isEmpty(ebitda?.key) ||
      isEmpty(ebitda?.label) ||
      isEmpty(netResult?.key) ||
      isEmpty(netResult?.label) ||
      isEmpty(totalAssets?.key) ||
      isEmpty(totalAssets?.label) ||
      isEmpty(equityCapital?.key) ||
      isEmpty(equityCapital?.label) ||
      isEmpty(equityRatio?.key) ||
      isEmpty(equityRatio?.label),
    [turnover, ebitda, netResult, totalAssets, equityCapital, equityRatio],
  )

  const getFinancialIndicators = useCallback(
    (fiscalData) => {
      if (isNil(fiscalData?.fiscal_figures) || isTileConfigError) {
        return undefined
      }
      return {
        keyDate: fiscalData.start_date,
        currency: fiscalData.currency,
        turnover: {
          label: turnover.label,
          value: get(fiscalData.fiscal_figures, turnover.key),
        },
        ebitda: {
          label: ebitda.label,
          value: get(fiscalData.fiscal_figures, ebitda.key),
        },
        netResult: {
          label: netResult.label,
          value: get(fiscalData.fiscal_figures, netResult.key),
        },
        totalAssets: {
          label: totalAssets.label,
          value: get(fiscalData.fiscal_figures, totalAssets.key),
        },
        equityCapital: {
          label: equityCapital.label,
          value: get(fiscalData.fiscal_figures, equityCapital.key),
        },
        equityRatio: {
          label: equityRatio.label,
          value: get(fiscalData.fiscal_figures, equityRatio.key),
        },
      }
    },
    [turnover, ebitda, netResult, totalAssets, equityCapital, equityRatio, isTileConfigError],
  )

  return { isTileConfigError, getFinancialIndicators }
}

export default useFinancialIndicatorsHelper
