import { FlexBox, FlexBoxDirection, FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreateEditMilestoneDialog from 'components/domains/deals/overview/milestones/CreateEditMilestoneDialog'
import MilestonesTable from 'components/domains/deals/overview/milestones/MilestonesTable'
import statusHistoryStyles from 'components/domains/deals/overview/status-history/StatusHistory.module.css'
import Card from 'components/ui/card/Card'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'
import useGetMilestones from 'hooks/services/deals/milestones/useGetMilestones'
import { useGetMilestoneCategories } from 'hooks/services/deals/milestones/useMilestoneCategories'
import { UserProfileContext } from 'routes/UserProfileContext'
import { useLastEditedText } from 'routes/deals/financing/utils/useLastEditedText'

const MilestonesCard = ({ pageData }) => {
  const {
    deal: { dealUuid },
    isAllowedToUpdateDealStatus: isAllowedToEdit,
  } = pageData

  const { t } = useTranslation('translation')
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const {
    data: { milestones = [], lastUpdatedAt, lastUpdatedBy } = {},
    isLoading: isLoadingMilestones,
    isError: isErrorMilestones,
    isFetching: isFetchingMilestones,
  } = useGetMilestones({ dealUuid: dealUuid })

  const { zoneId } = useContext(UserProfileContext) ?? {}

  const {
    data: staffMemberLastUpdated,
    isLoading: isStaffMemberLoading,
    isFetching: isStaffMemberFetching,
  } = useStaffMemberByObjectIdOrEmail({ objectIdOrEmail: lastUpdatedBy })
  const lastEditedText = useLastEditedText(
    isStaffMemberLoading || isStaffMemberFetching
      ? null
      : staffMemberLastUpdated?.fullName || lastUpdatedBy,
    lastUpdatedAt,
    zoneId,
  )

  const {
    data: { options = [] } = {},
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
  } = useGetMilestoneCategories()

  return (
    <>
      <Card
        className={statusHistoryStyles.card}
        header={
          <CardHeaderWithButtons
            title={t('pages.deals.detail.overview.milestones.title')}
            subTitle={lastEditedText}
          />
        }
      >
        <FlexBox
          fitContainer
          justifyContent={FlexBoxJustifyContent.Center}
          direction={FlexBoxDirection.Column}
        >
          <RequestStateResolver
            isLoading={isLoadingMilestones || isFetchingMilestones || isLoadingCategories}
            isError={isErrorMilestones || isErrorCategories}
            errorToDisplay={<ErrorDataUnavailableInContent />}
            center
            renderContent={() => (
              <MilestonesTable
                options={options}
                milestones={milestones}
                userIsAllowedToEdit={isAllowedToEdit}
                setIsAddDialogOpen={setIsDialogOpen}
              />
            )}
          />
        </FlexBox>
      </Card>
      <CreateEditMilestoneDialog isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} />
    </>
  )
}

MilestonesCard.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string,
    }),
    isAllowedToUpdateDealStatus: PropTypes.bool,
  }).isRequired,
}

export default MilestonesCard
