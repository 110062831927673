import { IllustratedMessage, IllustrationMessageType, Text } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import styles from 'components/domains/deals/overview/syndication/DealOverviewSyndicationCard.module.css'
import { SyndicationStructureColorContext } from 'components/domains/deals/overview/syndication/SyndicationStructureColorContext'
import { AmountWithHeadquarterCurrency } from 'components/domains/deals/pricing/AmountWithHeadquarterCurrency'
import DonutChart from 'components/ui/charts/donut-chart/DonutChart'
import LabelValueList from 'components/ui/data/LabelValueList'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useSyndication from 'hooks/services/deals/syndication/useSyndication'
import useSyndicationIntention from 'hooks/services/deals/syndication/useSyndicationIntention'

const HEADQUARTER_CURRENCY = 'EUR'
const relevantSyndicationStatusTypeCodes = ['30', '40', '50']

const DealOverviewSyndicationNewBusinessContent = ({ dealUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.syndication',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  const {
    colorOwnShare,
    colorsCounterparties,
    tooltipMaxTrancheNameLength,
    tooltipCuttedTrancheNameLength,
  } = useContext(SyndicationStructureColorContext)

  const formatCurrency = useCustomizableCurrencyFormatter({
    notation: 'compact',
  })

  const {
    data: { counterparties = [], ownShare = {} } = {},
    isLoading: isLoadingSyndication,
    isError: isErrorSyndication,
  } = useSyndication(dealUuid)

  const {
    isLoading: isLoadingSyndicationIntention,
    isError: isErrorSyndicationIntention,
    data: { syndicationPreSale = {}, syndicationPostSale = {} } = {},
  } = useSyndicationIntention(dealUuid)

  const hasSyndicationStructure = counterparties?.some((cp) =>
    relevantSyndicationStatusTypeCodes.includes(cp.statusTypeCode),
  )

  const hasSyndicationIntention = syndicationPreSale || syndicationPostSale?.selling_amount_total

  const bpIds = useMemo(
    () =>
      counterparties
        .filter((cp) => relevantSyndicationStatusTypeCodes.includes(cp.statusTypeCode))
        .map((cp) => cp.businessPartnerId),
    [counterparties],
  )

  const {
    data: { businessPartnerMinis = [] } = {},
    isFetching: isFetchingBp,
    isError: isErrorBp,
    refetch: getBPs,
  } = useBusinessPartnerMiniByIds(bpIds)

  useEffect(() => {
    if (bpIds.length > 0 && !isFetchingBp && !businessPartnerMinis?.length) {
      getBPs()
    }
  }, [bpIds, businessPartnerMinis, getBPs, isFetchingBp])

  const getBusinessPartnerNameById = useCallback(
    (bpId) => {
      const businessPartnerMini = businessPartnerMinis.find((bpMini) => bpMini.id === bpId) ?? {}
      return businessPartnerMini.fullName ?? bpId
    },
    [businessPartnerMinis],
  )

  const getReducedTranchesOfRoles = (roles = []) => {
    const tranches = roles.flatMap((role) => role.syndicationShares)
    return tranches.reduce((result, tranche) => {
      const existingTranche = result.find(({ trancheId }) => trancheId === tranche.trancheId)

      if (existingTranche) {
        existingTranche.value += tranche.amountTotalHeadquarter?.amount
        return result
      }

      result.push({
        name: tranche.name,
        trancheId: tranche.trancheId,
        value: tranche.amountTotalHeadquarter?.amount,
        currency: tranche.amountTotalHeadquarter?.currency,
      })
      return result
    }, [])
  }

  const chartData = useMemo(
    () =>
      counterparties
        .filter(
          ({ statusTypeCode, amountTotalHeadquarter, amountTotal }) =>
            relevantSyndicationStatusTypeCodes.includes(statusTypeCode) &&
            (amountTotalHeadquarter?.amount || amountTotal?.amount),
        )
        .reduce(
          (result, { amountTotalHeadquarter, amountTotal, businessPartnerId, roles }, i) => {
            const existingBpSyndication = result.find(({ bpId }) => bpId === businessPartnerId)

            if (existingBpSyndication) {
              existingBpSyndication.value += amountTotalHeadquarter?.amount || amountTotal?.amount
              existingBpSyndication.tranches.push(...getReducedTranchesOfRoles(roles))

              return result
            }

            result.push({
              value: amountTotalHeadquarter?.amount || amountTotal?.amount,
              name: getBusinessPartnerNameById(businessPartnerId),
              bpId: businessPartnerId,
              color: colorsCounterparties[i],
              tranches: getReducedTranchesOfRoles(roles),
            })
            return result
          },
          [
            {
              value: ownShare?.amountTotalHeadquarter?.amount || ownShare?.amountTotal?.amount,
              name: t('own-share'),
              color: colorOwnShare,
              tranches: getReducedTranchesOfRoles(ownShare?.roles),
            },
          ],
        ),
    [
      colorOwnShare,
      colorsCounterparties,
      counterparties,
      getBusinessPartnerNameById,
      ownShare?.amountTotal?.amount,
      ownShare?.amountTotalHeadquarter?.amount,
      ownShare?.roles,
      t,
    ],
  )

  const rolesOfOwnShare = useMemo(
    () => ownShare?.roles?.map((role) => role.roleTypeShortText) || [],
    [ownShare?.roles],
  )

  const getTooltip = ({ payload = [] } = {}) => {
    const { tranches = [] } = payload[0]?.payload ?? {}
    const { total, toolTipData } = tranches.reduce(
      (result, { name, value, currency }, i) => {
        result.total += value
        result.toolTipData.push({
          rowKey: i,
          label:
            name.length > tooltipMaxTrancheNameLength
              ? name.substring(0, tooltipCuttedTrancheNameLength) + '...'
              : name,
          value: formatCurrency(value, currency),
        })
        return result
      },
      { total: 0, toolTipData: [] },
    )

    toolTipData.push({
      rowKey: 'total',
      label: t('total'),
      value: formatCurrency(total, HEADQUARTER_CURRENCY),
    })

    return (
      <div className={styles.tooltip}>
        <LabelValueList rows={toolTipData} />
      </div>
    )
  }

  const isLoading = useMemo(
    () => isLoadingSyndication || isLoadingSyndicationIntention || isFetchingBp,
    [isFetchingBp, isLoadingSyndication, isLoadingSyndicationIntention],
  )

  return (
    <div className={compact([isLoading && styles.loadingWrapper]).join(' ')}>
      <RequestStateResolver
        isLoading={isLoading}
        center
        isError={
          isErrorSyndication ||
          isErrorSyndicationIntention ||
          (!hasSyndicationStructure && !hasSyndicationIntention) ||
          isErrorBp
        }
        errorToDisplay={
          <IllustratedMessage
            name={IllustrationMessageType.UnableToLoad}
            titleText={tNoPrefix('components.ui.card.empty-card.title')}
            subtitleText={tNoPrefix('components.ui.card.empty-card.subtitle')}
          />
        }
        renderContent={() => (
          <>
            {hasSyndicationStructure ? (
              <>
                <CardSection title={t('commitment-share')}>
                  <DonutChart data={chartData} tooltipContent={getTooltip} />
                </CardSection>
                <CardSection title={t('roles-of-own-share')}>
                  {rolesOfOwnShare.map((role, idx) => (
                    <Text key={idx}>{role}</Text>
                  ))}
                </CardSection>
              </>
            ) : (
              <CardSection>
                <CardShowItem
                  label={t('pre-sale')}
                  value={
                    syndicationPreSale?.sellingAmountTotal && (
                      <AmountWithHeadquarterCurrency
                        amount={{
                          number: syndicationPreSale.sellingAmountTotal?.amount,
                          currency_code: syndicationPreSale.sellingAmountTotal?.currency,
                        }}
                        headquarterAmount={{
                          number: syndicationPreSale.sellingAmountTotalHeadquarter?.amount,
                          currency_code: syndicationPreSale.sellingAmountTotalHeadquarter?.currency,
                        }}
                      />
                    )
                  }
                />
                <CardShowItem
                  label={t('post-sale')}
                  value={
                    syndicationPostSale?.sellingAmountTotal && (
                      <AmountWithHeadquarterCurrency
                        amount={{
                          number: syndicationPostSale.sellingAmountTotal.amount,
                          currency_code: syndicationPostSale.sellingAmountTotal.currency,
                        }}
                        headquarterAmount={{
                          number: syndicationPostSale.sellingAmountTotalHeadquarter?.amount,
                          currency_code:
                            syndicationPostSale.sellingAmountTotalHeadquarter?.currency,
                        }}
                      />
                    )
                  }
                />
              </CardSection>
            )}
          </>
        )}
      />
    </div>
  )
}

DealOverviewSyndicationNewBusinessContent.propTypes = {
  dealUuid: PropTypes.string.isRequired,
}

export default DealOverviewSyndicationNewBusinessContent
