import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/deals/pricing/AmountWithHeadquarterCurrency.module.css'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'

const amountPropTypes = PropTypes.shape({
  number: PropTypes.number,
  currency_code: PropTypes.string,
})

const dealPricingAmountWithHeadquarterCurrencyProps = {
  amount: amountPropTypes,
  headquarterAmount: amountPropTypes,
}

const AmountWithHeadquarterCurrency = ({ amount, headquarterAmount, ...props }) => {
  const formatCurrency = useCustomizableCurrencyFormatter()

  const formatAmount = (currency) => formatCurrency(currency?.number, currency?.currency_code)

  if (!amount) return ''

  if (!headquarterAmount || headquarterAmount.currency_code === amount.currency_code) {
    return <Text>{formatAmount(amount)}</Text>
  }

  return (
    <div {...props} className={styles.textContainer}>
      <Text className={styles.amount}>{formatAmount(amount)}</Text>
      <Text className={styles.headquarterAmount}>{formatAmount(headquarterAmount)}</Text>
    </div>
  )
}

AmountWithHeadquarterCurrency.propTypes = dealPricingAmountWithHeadquarterCurrencyProps

export { AmountWithHeadquarterCurrency, amountPropTypes }
