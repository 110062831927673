import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateOnHold = ({ onSuccess }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, onHold }) =>
      put({
        path: `/deals/${dealUuid}/on-hold`,
        body: onHold,
      }),
    { onSuccess },
  )
}

export default useUpdateOnHold
