import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import FinancingValue from 'components/domains/deals/financing/financing-card/FinancingValue'
import useFinancingCardData from 'components/domains/deals/financing/financing-card/useFinancingCardData'
import { useGetAndAppendWorkingVersionSearchParam } from 'components/domains/deals/useGetAndAppendWorkingVersionSearchParam'
import Card from 'components/ui/card/Card'
import CtrlClickableCardHeader from 'components/ui/card/CtrlClickableCardHeader'
import useCreateNavigateClickHandler from 'components/ui/link/useCreateNavigateClickHandler'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { DealContext } from 'routes/deals/DealContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const FinancingCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.financing.financing-card',
  })
  const createClickHandler = useCreateNavigateClickHandler()
  const { appendWorkingVersionSearchParam } = useGetAndAppendWorkingVersionSearchParam()
  const { deal: { dealUuid, displayId } = {} } = useContext(DealContext)

  const { format } = useShortDateFormatter()

  const {
    data: { totalCommitment, ownCommitment, ownOutstanding, earliestMaturityDate } = {},
    isFetching,
    isError,
  } = useFinancingCardData({ dealUuid })

  const financingBasePath = `/${paths.deals}/${displayId}/${dealDetailPaths.financing}`
  const onHeaderClick = createClickHandler(appendWorkingVersionSearchParam(financingBasePath))

  return (
    <Card
      header={
        <CtrlClickableCardHeader titleText={t('title')} onClick={onHeaderClick} interactive />
      }
    >
      <CardSection>
        <LoadingStateWrapper isLoading={isFetching} isError={isError}>
          <CardShowItem
            label={t('commitment-total')}
            value={
              <FinancingValue
                id="commitment-total"
                summaryValue={totalCommitment?.value}
                summaryCurrency={totalCommitment?.currency}
                headquarterValue={totalCommitment?.headquarterValue}
                headquarterCurrency={totalCommitment?.headquarterCurrency}
              />
            }
          />
          <CardShowItem
            label={t('commitment-own')}
            value={
              <FinancingValue
                id="commitment-own"
                summaryValue={ownCommitment?.value}
                summaryCurrency={ownCommitment?.currency}
                headquarterValue={ownCommitment?.headquarterValue}
                headquarterCurrency={ownCommitment?.headquarterCurrency}
              />
            }
          />
          <CardShowItem
            label={t('outstanding-own')}
            value={
              <FinancingValue
                id="outstanding-own"
                summaryValue={ownOutstanding?.value}
                summaryCurrency={ownOutstanding?.currency}
                headquarterValue={ownOutstanding?.headquarterValue}
                headquarterCurrency={ownOutstanding?.headquarterCurrency}
              />
            }
          />
          <CardShowItem
            id="earliest-maturity-date"
            label={t('earliest-maturity-date')}
            value={format(earliestMaturityDate)}
          />
        </LoadingStateWrapper>
      </CardSection>
    </Card>
  )
}

export default FinancingCard
