import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import {
  Button,
  ButtonDesign,
  DatePicker,
  Input,
  MessageBoxActions,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/overview/milestones/CreateEditMilestoneDialog.module.css'
import useHandleCrudMilestone from 'components/domains/deals/overview/milestones/useHandleCrudMilestone'
import Labeled from 'components/ui/data/Labeled'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { standardMappings, useFormMapped } from 'hooks/form/useFormMapped'
import { useFormValidations } from 'hooks/form/useFormValidations'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import {
  useGetMilestoneCategories,
  useMilestoneTypes,
} from 'hooks/services/deals/milestones/useMilestoneCategories'
import { DealContext } from 'routes/deals/DealContext'

const CUSTOM_TYPECODE = 'CUSTOM'

const CreateEditMilestoneDialog = ({ isOpen, setIsOpen, prefillValues, isEdit = false }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.milestones',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const { required, maxLength } = useFormValidations()
  const { parse: parseDate, localePattern, format: formatDate } = useShortDateFormatter()
  const { deal: { dealUuid } = {} } = useContext(DealContext)

  const [isDefaultApplied, setIsDefaultApplied] = useState(false)

  const { updateAction, createAction } = useHandleCrudMilestone({ dealUuid })
  const showMessageBox = useShowMessageBox()

  const isUpdating = !!prefillValues?.milestoneUuid

  const {
    data: { options = [] } = {},
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
  } = useGetMilestoneCategories()
  const selectableOptions = options.filter(({ categoryEditable }) => categoryEditable)

  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues: prefillValues
      ? {
          milestoneUuid: prefillValues.milestoneUuid,
          categoryCode: prefillValues.categoryCode,
          typeCode: prefillValues.typeCode,
          customTypeName: prefillValues.customTypeName,
          targetDate: formatDate(prefillValues.targetDate, localePattern),
        }
      : {},
  })

  const {
    reset: resetState,
    formState: { isValid, isDirty },
    register,
    watch,
    handleSubmit,
    setValue,
  } = useFormMapped(formMethods)

  const isReady = !isLoadingCategories && !isErrorCategories

  const applyDefaults = useCallback(() => {
    if (isOpen && isUpdating && isReady && !isDefaultApplied && !!prefillValues) {
      resetState({
        milestoneUuid: prefillValues.milestoneUuid,
        categoryCode: prefillValues.categoryCode,
        typeCode: prefillValues.typeCode,
        customTypeName: prefillValues.customTypeName,
        targetDate: formatDate(prefillValues.targetDate, localePattern),
      })
      setIsDefaultApplied(true)
    }
  }, [
    isOpen,
    isUpdating,
    isReady,
    isDefaultApplied,
    prefillValues,
    resetState,
    formatDate,
    localePattern,
  ])

  useEffect(() => {
    applyDefaults()
  }, [applyDefaults])

  const customMilestoneSelected = watch('typeCode') === CUSTOM_TYPECODE
  const selectedCategory = watch('categoryCode')
  const { data: typecodes } = useMilestoneTypes(selectedCategory)

  const onClose = useCallback(() => {
    resetState()
    setIsDefaultApplied(false)
    setIsOpen(false)
  }, [resetState, setIsOpen])

  const handleCancel = useCallback(
    (e) => {
      if (!isDirty) {
        onClose()
      } else if (isOpen) {
        e.preventDefault()
        showMessageBox({
          type: MessageBoxTypes.Warning,
          titleText: tNoPrefix('buttons.discard'),
          children: tNoPrefix('components.ui.buttons.cancel.popover.cancel.confirmation'),
          actions: [
            <Button
              key="button-discard"
              design={ButtonDesign.Emphasized}
              onClick={() => {
                onClose()
              }}
            >
              {tNoPrefix('buttons.discard')}
            </Button>,
            MessageBoxActions.Cancel,
          ],
        })
      }
    },
    [isDirty, isOpen, onClose, showMessageBox, tNoPrefix],
  )

  const onSubmit = (milestone) => {
    isUpdating ? updateAction({ dealUuid, milestone }) : createAction({ dealUuid, milestone })
    onClose()
  }
  const submitForm = handleSubmit(onSubmit)

  const handleSaveButtonClick = useCallback(async () => {
    if (!isValid) {
      showMessageBox({
        type: MessageBoxTypes.Error,
        children: tNoPrefix('components.input.validation.error-message-box.message'),
        onClose: async () => await submitForm(),
      })
    } else {
      await submitForm()
    }
  }, [isValid, showMessageBox, submitForm, tNoPrefix])

  const submitAndReopen = async () => {
    await submitForm()
    setIsOpen(true)
  }

  return createPortal(
    <Dialog
      open={isOpen}
      onBeforeClose={handleCancel}
      headerText={isUpdating ? t('edit') : t('add')}
      size={DialogSize.S}
      secondaryButton={
        !isUpdating && (
          <DialogSecondaryButton
            onClick={submitAndReopen}
            disabled={!isValid}
            id="save-and-add-button"
          >
            {tNoPrefix('buttons.save-and-add-new')}
          </DialogSecondaryButton>
        )
      }
      primaryButton={
        <DialogPrimaryButton
          id="save-button"
          onClick={handleSaveButtonClick}
          type="submit"
          disabled={isEdit && !isDirty && isDefaultApplied}
        >
          {tNoPrefix('buttons.save')}
        </DialogPrimaryButton>
      }
    >
      <form onSubmit={submitForm} className={styles.form}>
        {isUpdating && (
          <Input
            id="milestoneUuid"
            type="text"
            className={styles.hidden}
            {...register('milestoneUuid', {
              ...standardMappings.NativeInput,
              shouldUnregister: true,
            })}
          />
        )}
        <Labeled label={t('category')} showColon vertical required>
          <LoadingSelect
            id="categoryCode"
            loadingHook={() => ({
              isLoading: isLoadingCategories,
              isError: isErrorCategories,
              data: selectableOptions,
            })}
            optionDisplayName="categoryName"
            optionKeyName="categoryCode"
            {...register('categoryCode', {
              ...standardMappings.LoadingSelect,
              handlerMappings: {
                ...standardMappings.LoadingSelect.handlerMappings,
                onChange: (_, { trigger }) => ({
                  onChange: async (e, value) => {
                    await setValue(e.target.name, value)
                    await setValue('typeCode', undefined)
                    await trigger(e.target.name)
                  },
                }),
              },
              validate: {
                required: required(
                  null,
                  tNoPrefix('form.validation.select', {
                    label: t('category').toLowerCase(),
                  }),
                ),
              },
            })}
          />
        </Labeled>

        <Labeled label={t('milestone')} showColon vertical required>
          <LoadingSelect
            id="typeCode"
            loadingHook={() => ({
              isLoading: isLoadingCategories,
              isError: isErrorCategories,
              data: typecodes,
            })}
            optionDisplayName="name"
            optionKeyName="code"
            {...register('typeCode', {
              ...standardMappings.LoadingSelect,
              validate: {
                required: required(
                  null,
                  tNoPrefix('form.validation.select', {
                    label: t('milestone').toLowerCase(),
                  }),
                ),
              },
            })}
            disabled={!selectedCategory}
          />
        </Labeled>

        {customMilestoneSelected && (
          <Labeled label={t('customMilestoneName')} showColon vertical required>
            <Input
              id="customTypeName"
              type="text"
              {...register('customTypeName', {
                validate: {
                  required: required(
                    null,
                    tNoPrefix('form.validation.input', {
                      label: t('customMilestoneName').toLowerCase(),
                    }),
                  ),
                  // eslint-disable-next-line no-magic-numbers
                  maxLength: maxLength(50),
                },
                shouldUnregister: true,
              })}
              maxLength="50"
            />
          </Labeled>
        )}

        <Labeled label={t('targetDate')} showColon vertical required>
          <DatePicker
            id="targetDate"
            formatPattern={localePattern}
            placeholder={''}
            {...register('targetDate', {
              setValueAs: (value) => (value ? parseDate(value, localePattern) : value),
              displayAs: (value) => (value ? formatDate(value, localePattern) : value),
              validate: {
                required: required(
                  null,
                  tNoPrefix('form.validation.select', {
                    label: t('targetDate').toLowerCase(),
                  }),
                ),
              },
            })}
            className={styles.datePicker}
          />
        </Labeled>
      </form>
    </Dialog>,
    document.body,
  )
}

CreateEditMilestoneDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  prefillValues: PropTypes.object,
}

export default CreateEditMilestoneDialog
