import PropTypes from 'prop-types'
import styles from 'components/domains/deals/overview/conditions/DealOverviewConditionsBarLabel.module.css'

const Y_OFFSET = 12

const DealOverviewConditionsBarLabel = ({ x, y, value, width, index, dataKey, data }) => {
  if (value === 0) {
    return null
  }
  return (
    <text
      x={x + width / 2 - 2}
      y={y + Y_OFFSET}
      dy={0}
      fontSize="12"
      fill="#FFFFFF"
      fontWeight="Bold"
      textAnchor="start"
      className={styles.text}
    >
      {data[index][dataKey]}
    </text>
  )
}

DealOverviewConditionsBarLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  value: PropTypes.number,
  index: PropTypes.number,
  dataKey: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      DONE: PropTypes.number,
      IN_PROGRESS: PropTypes.number,
      OPEN: PropTypes.number,
    }),
  ),
}

export default DealOverviewConditionsBarLabel
