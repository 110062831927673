import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  FlexBoxWrap,
  Option,
  Select,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/deal-cashflow/result-charts/DealCashflowResultChartCardHeader.module.css'

export const DealOverviewCashflowMainScenarioChartHeader = ({
  handleOnChangeSelectedCategory,
  categories,
  cashflowKpis,
  setSelectedCashflowKpi,
  selectedCashflowKpi,
  selectedCategory,
  title,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.cashflow.financial-ratios',
  })

  return (
    <FlexBox
      wrap={FlexBoxWrap.Wrap}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      alignItems={FlexBoxAlignItems.Baseline}
    >
      <FlexBox direction={FlexBoxDirection.Column}>
        <Title level={TitleLevel.H5}>{t(title)}</Title>
      </FlexBox>
      <FlexBox direction={FlexBoxDirection.Row} wrap={FlexBoxWrap.Wrap}>
        <Select
          onChange={({
            detail: {
              selectedOption: { value },
            },
          }) => {
            handleOnChangeSelectedCategory(value)
          }}
          id="category-select"
          className={styles.selectCategory}
        >
          {categories.map((category) => (
            <Option key={category} value={category} selected={category === selectedCategory}>
              {category || t('no-category')}
            </Option>
          ))}
        </Select>
        <Select
          onChange={({
            detail: {
              selectedOption: { value },
            },
          }) => {
            setSelectedCashflowKpi(value)
          }}
          id="cashflow-kpi-select"
          className={styles.selectCashflowKpi}
        >
          {cashflowKpis.map((cashflowKpi) => (
            <Option
              key={cashflowKpi.ckpiId}
              value={cashflowKpi.ckpiId}
              selected={cashflowKpi.ckpiId === selectedCashflowKpi}
            >
              {cashflowKpi.name}
            </Option>
          ))}
        </Select>
      </FlexBox>
    </FlexBox>
  )
}

DealOverviewCashflowMainScenarioChartHeader.propTypes = {
  handleOnChangeSelectedCategory: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.string),
  cashflowKpis: PropTypes.arrayOf(
    PropTypes.shape({
      ckpiId: PropTypes.string,
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  setSelectedCashflowKpi: PropTypes.func,
  selectedCashflowKpi: PropTypes.string,
  selectedCategory: PropTypes.string,
  title: PropTypes.string,
}
