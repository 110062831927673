import isEqual from 'lodash.isequal'
import { useCallback, useEffect, useState } from 'react'
import { mapStaffMemberToFormState } from 'components/domains/deals/overview/internal-team-members/useHandleFormState'

const useCalculateLeaderFlags = ({ formState, staffMembers }) => {
  const [leaders, setLeaders] = useState([])

  const calculateIsLeaderEditable = useCallback(
    (virtualId) => {
      const currentFormStateEntry = formState.find((member) => virtualId === member.virtualId)
      const currentStaffMemberEntry = mapStaffMemberToFormState(
        staffMembers.find((member) => virtualId === member.virtualId),
      )
      const currentRelationId = currentFormStateEntry?.sapRelationId
      const staffMembersWithSameRelationId = formState.filter(
        (member) => member?.sapRelationId === currentRelationId,
      )

      // when there is only one user for the relation
      if (staffMembersWithSameRelationId.length === 1) {
        return false
      }

      // when formState was modified
      if (!isEqual(currentFormStateEntry, currentStaffMemberEntry)) {
        return true
      }

      // when no leader within the same relation
      if (staffMembersWithSameRelationId.every((member) => !member.leader)) {
        return false
      }

      // when the user is already leading
      if (formState.find((member) => member.virtualId === virtualId).leader) {
        return false
      }

      return true
    },
    [formState, staffMembers],
  )

  const calculateLeaderFlag = useCallback(
    (virtualId) => {
      const currentRelationId = formState.find(
        (member) => virtualId === member.virtualId,
      )?.sapRelationId
      const staffMembersWithSameRelationId = formState.filter(
        (member) => member?.sapRelationId === currentRelationId,
      )

      if (staffMembersWithSameRelationId.length === 1) {
        return true
      }

      return formState.find((member) => member.virtualId === virtualId)?.leader
    },
    [formState],
  )

  const calculateLeadersAndDisabledState = useCallback(() => {
    const mapped = formState.map((member) => ({
      virtualId: member.virtualId,
      isLeaderEditable: calculateIsLeaderEditable(member.virtualId),
      isLeader: calculateLeaderFlag(member.virtualId),
    }))

    setLeaders(mapped)
  }, [calculateIsLeaderEditable, calculateLeaderFlag, formState])

  useEffect(() => {
    calculateLeadersAndDisabledState()
  }, [calculateLeadersAndDisabledState, formState])

  return { leaders }
}

export default useCalculateLeaderFlags
