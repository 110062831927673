import { Button, ButtonDesign, MessageBox, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import useOnHold from 'hooks/services/deals/useOnHold'
import useUpdateOnHold from 'hooks/services/deals/useUpdateOnHold'

const OnHoldToggle = ({ dealUuid, dealDisplayId, isAllowedToEdit }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.on-hold',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const { data: isOnHold = false, isLoading: isLoadingOnHold } = useOnHold(dealUuid)
  const [isConfirmMessageBoxOpen, setIsConfirmMessageBoxOpen] = useState(false)

  const onUpdateSuccess = () => {
    setIsConfirmMessageBoxOpen(false)

    showToast({
      children: isOnHold ? t('deactivate.success') : t('activate.success'),
    })

    queryClient.invalidateQueries(['deals', dealUuid, 'on-hold'])
    queryClient.invalidateQueries(['deals', dealDisplayId, 'headers'])
  }
  const { mutateAsync: updateOnHold } = useUpdateOnHold({ onSuccess: onUpdateSuccess })

  const onActivationConfirm = () => {
    updateOnHold({ dealUuid: dealUuid, onHold: true })
  }

  const onDeactivateConfirm = () => {
    updateOnHold({ dealUuid: dealUuid, onHold: false })
  }

  return (
    <>
      <LabeledSwitch
        disabled={!isAllowedToEdit}
        isLoading={isLoadingOnHold}
        onChange={() => setIsConfirmMessageBoxOpen(true)}
        checked={isOnHold}
        checkedText={t('on')}
        uncheckedText={t('off')}
      />
      {createPortal(
        <MessageBox
          open={isConfirmMessageBoxOpen}
          titleText={tNoPrefix('components.confirmation-dialog.title')}
          onClose={() => setIsConfirmMessageBoxOpen(false)}
          actions={[
            <Button
              key="confirm"
              design={ButtonDesign.Emphasized}
              id="confirm-on-hold-update"
              onClick={() => (isOnHold ? onDeactivateConfirm() : onActivationConfirm())}
            >
              {tNoPrefix('buttons.confirm')}
            </Button>,
            <Button key="cancel" onClick={() => setIsConfirmMessageBoxOpen(false)}>
              {tNoPrefix('buttons.cancel')}
            </Button>,
          ]}
        >
          {isOnHold ? t('deactivate-confirm-text') : t('activate-confirm-text')}
        </MessageBox>,
        document.body,
      )}
    </>
  )
}

OnHoldToggle.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  dealDisplayId: PropTypes.string.isRequired,
  isAllowedToEdit: PropTypes.bool,
}

export default OnHoldToggle
