import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteMilestone = ({ onSuccess, onError }) => {
  const { delete: deleteMilestone } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, milestoneId }) => {
      await deleteMilestone({
        path: `/deals/${dealUuid}/milestones/${milestoneId}`,
        options: { enabled: !!dealUuid && !!milestoneId },
      })
    },
    { onSuccess, onError },
  )
}

export default useDeleteMilestone
