import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import {
  useAreaMeasurementUnitFormatter,
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  usePercentageFormatter,
} from 'hooks/i18n/useI18n'

const PropertyRentRollCardSection = ({ multiPropertyKpisData }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.portfolio.rent-roll',
  })

  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const formatCurrency = useCustomizableCurrencyFormatter()

  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatWault = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  return (
    <>
      {!isEmpty(multiPropertyKpisData) && (
        <CardSection title={t('title')}>
          <CardShowItem
            label={t('lettable-area-size')}
            value={`${formatNumber(multiPropertyKpisData.totalAreaSurface.value)} ${formatAreaUnit(
              multiPropertyKpisData.totalAreaSurface.measurementUnit,
            )}`}
          />

          <CardShowItem
            label={t('lettable-area-no')}
            value={multiPropertyKpisData.totalNumberOfUnits + ''}
          />

          <CardShowItem
            label={t('occupancy-rate')}
            value={formatPercentage(1 - multiPropertyKpisData.vacancySurface.percent)}
          />

          <CardShowItem
            label={t('current-rental-income')}
            value={formatCurrency(
              multiPropertyKpisData.annualizedCurrentRent.number,
              multiPropertyKpisData.annualizedCurrentRent.currency,
            )}
          />

          <CardShowItem
            label={t('wault-break')}
            value={`${formatWault(multiPropertyKpisData.waultToBreakInYears)} ${t('years')}`}
          />

          <CardShowItem
            label={t('wault-expiry')}
            value={`${formatWault(multiPropertyKpisData.waultToExpiryInYears)} ${t('years')}`}
          />
        </CardSection>
      )}
    </>
  )
}

PropertyRentRollCardSection.propTypes = {
  multiPropertyKpisData: PropTypes.shape({
    totalAreaSurface: PropTypes.shape({
      value: PropTypes.number,
      measurementUnit: PropTypes.string,
    }),
    totalNumberOfUnits: PropTypes.number,
    vacancySurface: PropTypes.shape({ percent: PropTypes.number }),
    annualizedCurrentRent: PropTypes.shape({
      number: PropTypes.number,
      currency: PropTypes.string,
    }),
    waultToBreakInYears: PropTypes.number,
    waultToExpiryInYears: PropTypes.number,
  }),
}

export default PropertyRentRollCardSection
