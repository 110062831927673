import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateMilestone = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, milestone }) => {
      await post({
        path: `/deals/${dealUuid}/milestones`,
        body: snakecaseKeys(milestone, { deep: true }),
      })
    },
    { onSuccess, onError },
  )
}

export default useCreateMilestone
