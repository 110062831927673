import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SyndicationStructureColorContext } from 'components/domains/deals/overview/syndication/SyndicationStructureColorContext'
import { useTypeCodesAllValues } from 'hooks/services/deals/configurations/useTypeCodesAllValues'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDealOverviewSyndicationStructureChartData = (syndicationStructure, businessPartners) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.syndication',
  })
  const { colorOwnShare, colorsCounterparties } = useContext(SyndicationStructureColorContext)

  const { data, isFetching, isError } = useCamelizedResponse(
    useTypeCodesAllValues('CUSTOMER_BP_ID'),
  )
  /**
   * customerBusinessPartnerIdResponse - flag used to cover the case when customer is participant in syndication.
   * In that case should be displayed only as own share value
   */
  const customerBusinessPartnerIdResponse = data?.typeCodes?.[0]?.key

  /**
   * Gather the tranche information for a BP that is part of the syndication.
   * The information is displayed in the chart tooltip for a business partner.
   */
  const getTrancheInformationForBp = useCallback(
    (bpId) =>
      syndicationStructure?.tranches?.reduce((result, currentTranche) => {
        const bpIncludedInTrancheSyndication = currentTranche.syndications.find(
          ({ bpId: businessPartnerId }) => businessPartnerId === bpId,
        )
        const isSyndicationBusinessPartnerCustomer = bpId === customerBusinessPartnerIdResponse
        if (bpIncludedInTrancheSyndication) {
          const syndicatedAmountInTrancheForBp = currentTranche.syndications.reduce(
            (syndicationResultForTranche, syndication) => {
              const syndicationAmount =
                syndication?.syndicationAmount?.amount?.amountHeadQuarterCurrency
              if (syndication?.bpId === bpId && !isSyndicationBusinessPartnerCustomer) {
                syndicationResultForTranche.amount += syndicationAmount?.amount
                syndicationResultForTranche.currency = syndicationAmount?.currency
              }
              return syndicationResultForTranche
            },
            {
              amount: 0,
              currency: undefined,
            },
          )
          result.push({
            trancheId: currentTranche.id,
            value: syndicatedAmountInTrancheForBp.amount,
            currency: syndicatedAmountInTrancheForBp.currency,
          })
        }
        return result
      }, []),
    [customerBusinessPartnerIdResponse, syndicationStructure?.tranches],
  )

  /**
   * Gather the own share information of the syndication.
   * The information is displayed in the chart tooltip for the own share.
   */
  const getTrancheInformationForOwnShare = useCallback(
    () =>
      syndicationStructure?.tranches?.reduce((result, currentTranche) => {
        result.push({
          trancheId: currentTranche.id,
          value: currentTranche.ownShare?.amount?.amountHeadQuarterCurrency?.amount,
          currency: currentTranche.ownShare?.amount?.amountHeadQuarterCurrency?.currency,
        })
        return result
      }, []),

    [syndicationStructure],
  )
  const getBusinessPartnerNameById = useCallback(
    (bpId) => businessPartners.find(({ id }) => id === bpId)?.fullName || bpId,
    [businessPartners],
  )

  const chartData = useMemo(
    () =>
      // Return the result of the reduce function
      syndicationStructure.syndications.reduce(
        (acc, { syndicationAmount, bpId: businessPartnerId }, i) => {
          const existingBpSyndication = acc.find(({ bpId }) => bpId === businessPartnerId)
          const isSyndicationBPWithCustomer =
            businessPartnerId === customerBusinessPartnerIdResponse
          if (existingBpSyndication && !isSyndicationBPWithCustomer) {
            existingBpSyndication.value +=
              syndicationAmount?.amount?.amountHeadquarterCurrency?.amount
            existingBpSyndication.tranches.push(...getTrancheInformationForBp(businessPartnerId))
            return acc
          }
          if (!isSyndicationBPWithCustomer) {
            acc.push({
              value: syndicationAmount?.amount?.amountHeadQuarterCurrency?.amount,
              currency: syndicationAmount?.amount?.amountHeadQuarterCurrency?.currency,
              name: getBusinessPartnerNameById(businessPartnerId),
              bpId: businessPartnerId,
              color: colorsCounterparties[i],
              tranches: getTrancheInformationForBp(businessPartnerId),
            })
          }
          return acc
        },
        [
          {
            value: syndicationStructure?.ownShare?.amount?.amountHeadQuarterCurrency?.amount,
            currency: syndicationStructure?.ownShare?.amount?.amountHeadQuarterCurrency?.currency,
            name: t('own-share'),
            color: colorOwnShare,
            tranches: getTrancheInformationForOwnShare() || [],
          },
        ],
      ),
    [
      colorOwnShare,
      colorsCounterparties,
      getBusinessPartnerNameById,
      getTrancheInformationForBp,
      getTrancheInformationForOwnShare,
      syndicationStructure?.ownShare?.amount?.amountHeadQuarterCurrency?.amount,
      syndicationStructure?.ownShare?.amount?.amountHeadQuarterCurrency?.currency,
      syndicationStructure.syndications,
      customerBusinessPartnerIdResponse,
      t,
    ],
  )

  return { data: chartData, isFetching, isError }
}

export default useDealOverviewSyndicationStructureChartData
