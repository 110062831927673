import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateMilestone = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, milestone }) => {
      await put({
        path: `/deals/${dealUuid}/milestones/${milestone.milestoneUuid}`,
        body: snakecaseKeys(milestone, { deep: true }),
      })
    },
    { onSuccess, onError },
  )
}

export default useUpdateMilestone
