import { Button, ButtonDesign, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useHandleCrudMilestone from 'components/domains/deals/overview/milestones/useHandleCrudMilestone'
import MessageBox from 'components/ui/message-box/MessageBox'

const DeleteMilestoneDialog = ({ isOpen, setIsOpen, dealUuid, milestoneUuid }) => {
  const { t } = useTranslation('translation')

  const { deleteAction } = useHandleCrudMilestone({ dealUuid })

  const handleDelete = async () => {
    await deleteAction(milestoneUuid)
    setIsOpen(false)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <MessageBox
      open={isOpen}
      type={MessageBoxTypes.Warning}
      onClose={onClose}
      titleText={t('buttons.delete')}
      actions={[
        <Button key="delete" design={ButtonDesign.Emphasized} onClick={handleDelete}>
          {t('buttons.delete')}
        </Button>,
        <Button key="cancel">{t('buttons.cancel')}</Button>,
      ]}
    >
      {t('pages.deals.detail.overview.milestones.delete-text')}
    </MessageBox>
  )
}

DeleteMilestoneDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  dealUuid: PropTypes.string.isRequired,
  milestoneUuid: PropTypes.string,
}

export default DeleteMilestoneDialog
