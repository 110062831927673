import PropTypes from 'prop-types'
import CardSection from 'components/domains/deals/card/CardSection'
import styles from 'components/domains/deals/overview/properties/PropertyMapCardSection.module.css'
import { LeafletMapWithMarkers } from 'components/ui/map/LeafletMapWithMarkers'
import { getBoundingBoxCenter, getBounds } from 'components/ui/map/MapPolygonUtils'

const PropertyMapCardSection = ({ properties }) => {
  const mapPadding = 0.06

  const isValidGeoLocation = (location) =>
    !isNaN(location?.lat) && !isNaN(location?.lng) && (location?.lat !== 0 || location?.lng !== 0)

  const locations = properties
    .map((it) => ({
      lat: parseFloat(it['geo_location']?.latitude),
      lng: parseFloat(it['geo_location']?.longitude),
    }))
    .filter(({ lat, lng }) => isValidGeoLocation({ lat, lng }))

  const markers = locations.map(({ lat, lng }) => ({
    latitude: lat,
    longitude: lng,
  }))

  const bounds = getBounds(
    locations.map(({ lat, lng }) => [lat, lng]),
    false,
    mapPadding,
  )
  const center = getBoundingBoxCenter(bounds)
  const orientation = { latitude: center[0], longitude: center[1] }

  return (
    <>
      {locations.length >= 1 && (
        <CardSection className={styles['card-section-map']}>
          <LeafletMapWithMarkers
            markers={markers}
            className={styles.propertyMap}
            orientation={orientation}
            center={center}
            bounds={bounds}
            initialZoomLevel={1}
            scrollWheelZoom={true}
          />
        </CardSection>
      )}
    </>
  )
}
PropertyMapCardSection.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      geo_location: PropTypes.shape({
        latitude: PropTypes.string.isRequired,
        longitude: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
}

export default PropertyMapCardSection
