import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateDealTeamMember = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, dealTeamMember, virtualId }) =>
      await put({
        path: `/deals/${dealUuid}/deal-teams/${virtualId}`,
        body: snakecaseKeys(dealTeamMember),
      }),
    { onSuccess, onError },
  )
}

export default useUpdateDealTeamMember
