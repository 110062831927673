import { IllustrationMessageSize, IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/overview/properties/DealPropertiesOverviewInformationCard.module.css'
import PortfolioInformationCardSection from 'components/domains/deals/overview/properties/PortfolioInformationCardSection'
import PropertyMapCardSection from 'components/domains/deals/overview/properties/PropertyMapCardSection'
import PropertyRentRollCardSection from 'components/domains/deals/overview/properties/PropertyRentRollCardSection'
import { useGetAndAppendWorkingVersionSearchParam } from 'components/domains/deals/useGetAndAppendWorkingVersionSearchParam'
import Card from 'components/ui/card/Card'
import CtrlClickableCardHeader from 'components/ui/card/CtrlClickableCardHeader'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import useCreateNavigateClickHandler from 'components/ui/link/useCreateNavigateClickHandler'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useAggregatedPortfolio from 'hooks/services/deals/portfolio/useAggregatedPortfolio'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import { useCountryCodes } from 'hooks/services/properties/useCountryCodes'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'

const DealPropertiesOverviewInformationCard = ({ pageData }) => {
  const {
    deal: { dealUuid },
  } = pageData

  const createClickHandler = useCreateNavigateClickHandler()
  const { appendWorkingVersionSearchParam } = useGetAndAppendWorkingVersionSearchParam()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.portfolio-card',
  })
  const { t: tNoPrefix } = useTranslation()

  const {
    data: aggregatedPortfolio = {},
    isFetching: isFetchingPortfolio,
    isError: isErrorPortfolio,
  } = useAggregatedPortfolio(dealUuid)

  const {
    data: { dealProperties = [] } = [],
    isFetching: isFetchingDealProperties,
    isError: isErrorDealProperties,
  } = useDealProperties({
    dealUuid: dealUuid,
    options: { enabled: !!dealUuid },
  })

  const propertyUuids = useMemo(
    () => dealProperties.map((item) => item.propertyUuid),
    [dealProperties],
  )

  const {
    data: multiPropertyKpisData = {},
    isFetching: isFetchingMultiPropertyKpis,
    isError: isErrorMultiPropertyKpis,
  } = useCurrentMultiPropertyKpis(propertyUuids)

  const {
    data: { data: { properties = [] } = {} } = {},
    isError: isErrorPropertyUuids,
    isFetching: isFetchingPropertyUuids,
  } = usePropertyUuids(propertyUuids, { enabled: !!propertyUuids.length })

  const {
    data: typecodeData,
    isFetching: isFetchingTypeCodes,
    isError: isErrorTypeCodes,
  } = useTypecodes()

  const {
    data: countryCodeData,
    isFetching: isFetchingCountryCodes,
    isError: isErrorCountryCodes,
  } = useCountryCodes()

  const renderPropertyContent = () => (
    <>
      <PropertyMapCardSection properties={properties} />
      <PortfolioInformationCardSection
        portfolio={aggregatedPortfolio}
        countryCodes={countryCodeData.country_codes}
        typeCodes={typecodeData.typecodes}
      />
      <PropertyRentRollCardSection multiPropertyKpisData={multiPropertyKpisData} />
    </>
  )

  const renderPortfolioContent = () => (
    <PortfolioInformationCardSection portfolio={aggregatedPortfolio} />
  )

  const renderNoPropertyContent = () => (
    <EmptyCardContent
      illustrationName={IllustrationMessageType.UnableToLoad}
      size={IllustrationMessageSize.Scene}
      title={tNoPrefix('components.cards.empty.title')}
      subtitle={tNoPrefix('components.cards.empty.subtitle')}
    />
  )

  const renderContent = () =>
    ({
      NO_PROPERTY: renderNoPropertyContent(),
      PROPERTY: renderPropertyContent(),
      PORTFOLIO: renderPortfolioContent(),
    }[aggregatedPortfolio?.financedObjectType] ?? renderNoPropertyContent())

  const propertiesBasePath = 'financed-objects'
  const handleOnHeaderClick = createClickHandler(
    appendWorkingVersionSearchParam(propertiesBasePath),
  )

  const cardHeader = (
    <CtrlClickableCardHeader
      titleText={t('title')}
      interactive={true}
      onClick={handleOnHeaderClick}
    />
  )

  const isLoading =
    isFetchingPortfolio ||
    isFetchingDealProperties ||
    isFetchingCountryCodes ||
    isFetchingTypeCodes ||
    isFetchingMultiPropertyKpis ||
    isFetchingPropertyUuids
  const isError =
    isErrorPortfolio ||
    isErrorDealProperties ||
    isErrorCountryCodes ||
    isErrorTypeCodes ||
    isErrorMultiPropertyKpis ||
    isErrorPropertyUuids
  return (
    <Card header={cardHeader}>
      <div className={isLoading ? styles.loadingCard : ''}>
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          renderContent={renderContent}
          center
          errorToDisplay={<ErrorDataUnavailableInContent />}
        />
      </div>
    </Card>
  )
}

DealPropertiesOverviewInformationCard.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string,
    }),
  }),
}

export default DealPropertiesOverviewInformationCard
