import {
  ButtonDesign,
  MessageBox,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/overview/status-history/ChangeStatusDialog.module.css'
import Labeled from 'components/ui/data/Labeled'
import Dialog, { DialogPrimaryButton, DialogSize } from 'components/ui/dialog/Dialog'
import TextAreaWithMaxCharacterRestriction from 'components/ui/input/TextAreaWithMaxCharacterRestriction'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useRestrictedDealStatus } from 'hooks/services/deals/configurations/useDealStatus'
import useCreateDealStatusHistoryEntry from 'hooks/services/deals/status-history/useCreateDealStatusHistoryEntry'

const dealStatusCommentMaxLength = 200

const ChangeStatusDialog = ({
  dealUuid,
  dealStatus,
  onCloseChangeStatusDialog,
  showChangeStatusDialog,
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.status-history',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState()
  const [statusSelectValueState, setStatusSelectValueState] = useState(ValueState.None)
  const [comment, setComment] = useState()
  const showMessageBox = Modals.useShowMessageBox()
  const dialogRef = useRef()

  const createDealStatusHistoryEntry = useCreateDealStatusHistoryEntry({
    onSuccess: () => {
      queryClient.invalidateQueries(dealUuid)
      setSelectedStatus(undefined)
      setComment('')
      onCloseChangeStatusDialog(true)
    },
    onError: () => {
      setIsErrorDialogOpen(true)
    },
  })

  /************
   * Save
   */
  const handleOnConfirmSave = () => {
    createDealStatusHistoryEntry.mutate({
      dealUuid: dealUuid,
      dealStatus: {
        status: selectedStatus,
        comment,
      },
    })
  }
  const confirmSaveButton = (
    <DialogPrimaryButton key="save-button" onClick={handleOnConfirmSave}>
      {tNoPrefix('buttons.confirm')}
    </DialogPrimaryButton>
  )

  const handleOnClickSave = () => {
    showMessageBox(
      {
        type: MessageBoxTypes.Confirm,
        children: t('change-status-dialog.confirm-save-text'),
        actions: [confirmSaveButton, MessageBoxActions.Cancel],
        draggable: true,
        resizable: true,
      },
      document.body,
    )
  }

  /*************
   * Cancel
   */
  const onCloseCancelDialog = (event) => {
    setIsCancelDialogOpen(false)
    dialogRef.current.show()
    if (event.detail.action === MessageBoxActions.OK) {
      setSelectedStatus(undefined)
      setComment('')
      onCloseChangeStatusDialog()
    }
  }

  const onCloseErrorDialog = (event) => {
    setIsErrorDialogOpen(false)
    if (event.detail.action === MessageBoxActions.Retry) {
      handleOnClickSave()
    }
  }

  const handleOnCloseDialog = () => {
    if (selectedStatus || comment) {
      setIsCancelDialogOpen(true)
    } else {
      onCloseChangeStatusDialog()
    }
  }

  return (
    <>
      <Dialog
        ref={dialogRef}
        id="change-status-dialog"
        headerText={t('change-status-dialog.title')}
        open={showChangeStatusDialog}
        size={DialogSize.S}
        draggable
        resizable
        onBeforeClose={(e) => {
          if (e.detail.escPressed) {
            e.preventDefault()
          }
          handleOnCloseDialog()
        }}
        primaryButton={
          <DialogPrimaryButton
            design={ButtonDesign.Emphasized}
            onClick={handleOnClickSave}
            disabled={!selectedStatus}
          >
            {tNoPrefix('buttons.save')}
          </DialogPrimaryButton>
        }
      >
        <form>
          <Labeled label={t('change-status-dialog.status')} vertical showColon required>
            <LoadingSelect
              id="deal-status"
              selectedKey={selectedStatus}
              loadingHook={useRestrictedDealStatus}
              loadingHookParams={dealStatus}
              optionKeyName={'code'}
              optionDisplayName={'name'}
              onChange={(e) => setSelectedStatus(e?.detail?.selectedOption?.textContent)}
              onBlur={() =>
                setStatusSelectValueState(selectedStatus ? ValueState.None : ValueState.Error)
              }
              valueState={statusSelectValueState}
              valueStateMessage={<Text>{tNoPrefix('form.validation.required')}</Text>}
            />
          </Labeled>
          <Labeled
            label={t('change-status-dialog.comment')}
            vertical
            showColon
            className={styles.textarea}
          >
            <TextAreaWithMaxCharacterRestriction
              id="deal-status-comment-textarea"
              maxLength={dealStatusCommentMaxLength}
              value={comment}
              onInput={setComment}
            />
          </Labeled>
        </form>
      </Dialog>

      <MessageBox
        id={'change-status-dialog-cancel-dialog'}
        type={MessageBoxTypes.Warning}
        open={isCancelDialogOpen}
        onClose={onCloseCancelDialog}
        actions={[MessageBoxActions.OK, MessageBoxActions.Cancel]}
        emphasizedAction={MessageBoxActions.OK}
        titleText={t('cancel-dialog.title')}
      >
        <div>{t('cancel-dialog.description')}</div>
      </MessageBox>

      <MessageBox
        id={'deal-change-status-error-dialog'}
        type={MessageBoxTypes.Error}
        open={isErrorDialogOpen}
        onClose={onCloseErrorDialog}
        actions={[MessageBoxActions.Retry, MessageBoxActions.Cancel]}
        emphasizedAction={MessageBoxActions.Retry}
        titleText={t('error-dialog.title')}
      >
        <div>
          {t('error-dialog.description', {
            returnObjects: true,
          }).map((element) => (
            <div key={element}>{element}</div>
          ))}
        </div>
      </MessageBox>
    </>
  )
}

ChangeStatusDialog.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  dealStatus: PropTypes.string.isRequired,
  onCloseChangeStatusDialog: PropTypes.func.isRequired,
  showChangeStatusDialog: PropTypes.bool.isRequired,
}

export default ChangeStatusDialog
