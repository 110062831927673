const COVENANT_STATUS_ACTIVE = 'ACTIVE'
const COVENANT_STATUS_PLANNED = 'PLANNED'

export const mapNextAnnualReview = (businessPartnerId, multipleCovenantMonitoringData) => {
  const nextAnnualReviewMonitoringItems = multipleCovenantMonitoringData?.find(
    ({ businessPartnerId: responseBusinessPartnerId }) =>
      responseBusinessPartnerId === businessPartnerId,
  )

  // this replicates the same logic applied to get the covenant's nextCovenantMonitoringItem
  // that is then presented as the "Check Due Date"
  const nextAnnualReview = nextAnnualReviewMonitoringItems?.covenantMonitoringItems
    ?.filter(
      ({ status } = {}) => status === COVENANT_STATUS_ACTIVE || status === COVENANT_STATUS_PLANNED,
    )
    ?.reduce((a, b) => {
      const dateA = new Date(a?.dueDate?.targetDeliveryDate)
      const dateB = new Date(b?.dueDate?.targetDeliveryDate)
      return dateA < dateB ? a : b
    }, {})

  return nextAnnualReview ? nextAnnualReview?.dueDate?.checkDate : null
}
