import {
  CheckBox,
  DatePicker,
  FlexBox,
  FlexBoxDirection,
  Input,
  Label,
  Option,
  Select,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import 'components/ui/card/Card.css'
import 'components/domains/properties/PropertyCards.css'
import isNil from 'lodash.isnil'
import uniq from 'lodash.uniq'
import PropTypes from 'prop-types'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Card from 'components/ui/card/Card'
import CardHeaderWithEditMode from 'components/ui/card/CardHeaderWithEditMode'
import EditCardItem from 'components/ui/card/EditCardItem'
import styles from 'components/ui/card/EditCardView.module.css'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import TextAreaWithMaxCharacterRestriction from 'components/ui/input/TextAreaWithMaxCharacterRestriction'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const HIDE_OPTIONS = {
  alwaysHidden: 'alwaysHidden',
  hideWhenEmpty: 'hideWhenEmpty',
}

/* Display Card Standard Component
 *
 * props:
 * - cardHeaderTitle: Title for the card
 * - cardHeaderSubtitle: (optional) Subtitle for the card
 * - additionalEditHeaderProps: (optional) Passes additional props to the header
 * - setEditModeHasChanges: State setter, set to true when something got changed
 * - editModeHasChanges: State value, set by setter above. Disables the save button if there are no changes
 * - onChange: (optional) Specify an external onChange handler. Receives: ([key]: changedValue, isMandatory-flag, invalidFormStateSetter)
 * - values: object containing key-value pairs of editable fields. Example: { propertyName: "Test", propertyType: "001"}
 * - onSaveClicked: function that is called when the save button is pressed
 * - onCancelClicked: function that is called when the cancel button is pressed
 * - isLoading: Flag for the LoadingStateWrapper (Loading animation when true)
 * - fieldDefinitions: array of objects defining this view
 *    -> possible definitions: sections, <Input />, <Select/>, custom components (see below)
 *    -> possible custom validation function that can raise validation error (see below)
 *
 * Example field Definition:
  const fieldDefinitions = [
    {
      value: {title: "Title 1", subtitle: "Subtitle"},
      isSectionTitle: true,
    },
    {
      label: 'Label 1',
      name: 'propertyName',
      editComponentType: editComponentTypes.Input,
      editComponentProps: { type: 'string' },
      isMandatory: true,
    },
    {
      label: 'Label 2',
      name: 'propertyType',
      editComponentType: editComponentTypes.Select,
      editComponentProps: { type: 'string' },
      isMandatory: true,
      editComponentSelectOptions: [{ key: "001", display_name: "Test1" }, { key: "002", display_name: "Test2" }]
    },
    {
      label: 'Label 3',
      name: 'propertyName',
      editComponentType: editComponentTypes.Input,
      editComponentProps: { type: 'string' },
      validationFunction: isAmountValid,
    },
  ]
 */

const EditCardView = ({
  cardHeaderTitle,
  cardHeaderSubtitle,
  setEditModeHasChanges,
  editModeHasChanges,
  onChange,
  values,
  onSaveClicked,
  onCancelClicked,
  isLoading = false,
  fieldDefinitions,
  additionalEditHeaderProps = {},
  className,
  requiredFields,
  isSaveDisabled = false,
  additionalContentProps,
  customOnChangeHandler = (_0, _1, _2, _3) => {},
}) => {
  const getDefaultInvalidFormState = () => {
    if (values === undefined || values === null) {
      return []
    }
    const findFieldDefinitionForName = (name) => fieldDefinitions.find((def) => def.name === name)
    return Object.keys(values)
      .filter((name) => findFieldDefinitionForName(name)?.isMandatory)
      .filter((name) => values[name] === null || values[name] === '')
  }
  const [invalidFormState, setInvalidFormState] = useState(getDefaultInvalidFormState())
  const formattedNumberRef = useRef(null)

  const isFieldRequiredButEmpty = (name, value) => requiredFields?.includes(name) && !value

  const getValueStateFromItem = (name, value) => {
    if (invalidFormState.includes(name)) {
      return ValueState.Error
    } else if (isFieldRequiredButEmpty(name, value)) {
      return ValueState.Warning
    } else {
      return ValueState.None
    }
  }

  const getValueState = () => {
    let fieldValueState = {}
    fieldDefinitions.map((item) => {
      const valueState = getValueStateFromItem(item.name, item.value)
      fieldValueState = {
        ...fieldValueState,
        [item.name]: valueState,
      }
    })
    return fieldValueState
  }

  const { t } = useTranslation()
  const { localePattern } = useShortDateFormatter()
  const [fieldValuesState, setFieldValueState] = useState(getValueState())
  const [fieldValues, setFieldValues] = useState(values || {})

  const validate = (key, value, validationFunction) => {
    if (validationFunction) {
      const validationFunctionResult = validationFunction(value)
      if (!validationFunctionResult) {
        setInvalidFormState((oldState) => uniq([...oldState, key]))
      } else if (validationFunctionResult) {
        setInvalidFormState((oldState) => oldState.filter((element) => element !== key))
      }
    }
  }

  const handleOnChange = (key, value, isFocussed, isMandatory, validationFunction) => {
    setEditModeHasChanges(true)
    setFieldValues((oldState) => ({ ...oldState, [key]: value }))
    if (onChange) {
      onChange({ [key]: value }, isMandatory, setInvalidFormState, isFocussed, validationFunction)
    }
    if (isMandatory && value === '') {
      setInvalidFormState((oldState) => uniq([...oldState, key]))
    } else if (isMandatory && value !== '') {
      setInvalidFormState((oldState) => oldState.filter((element) => element !== key))
    }

    validate(key, value, validationFunction)
  }
  const hasRequiredFieldsError =
    Object.keys(fieldValuesState).filter(
      (fieldValueState) => fieldValueState !== ValueState.Warning,
    ).length !== 0
  const handleBulkOnChange = (newValues) => {
    setEditModeHasChanges(true)
    setFieldValues((oldState) => ({ ...oldState, ...newValues }))
    if (onChange) {
      onChange(newValues, null, setInvalidFormState, null)
    }
    setInvalidFormState((oldState) =>
      Object.keys(newValues)
        .filter(
          (name) => fieldDefinitions.find((definition) => definition?.name === name)?.isMandatory,
        )
        .reduce(
          (state, name) =>
            newValues[name]?.length < 1 ? [...state, name] : state.filter((el) => el !== name),
          [...oldState],
        ),
    )
  }

  const handleOnChangeCustom = (fieldName, value) =>
    customOnChangeHandler(fieldName, value, fieldValues, values)

  useMemo(() => {
    setFieldValueState(getValueState())
  }, [invalidFormState])

  const renderEditItem = (item) => {
    switch (item.editComponentType) {
      case editComponentTypes.Select:
        return (
          <Select
            {...item.editComponentProps}
            onChange={(event) => {
              handleOnChange(
                item.name,
                event.detail.selectedOption.dataset.id,
                false,
                item.isMandatory,
                item.validationFunction,
              )
              handleOnChangeCustom(item.name, event.detail.selectedOption.dataset.id)
            }}
            className="edit-input-field"
          >
            {item.editComponentSelectOptions?.map((option, index) => (
              <Option
                key={`key-${option.key}-index-${index}`}
                data-id={option.key}
                selected={option.key === fieldValues[item.name]}
              >
                {option.display_name}
              </Option>
            ))}
          </Select>
        )
      case editComponentTypes.LoadingSelect:
        return (
          <LoadingSelect
            {...item.editComponentProps}
            className="edit-input-field"
            selectedKey={fieldValues[item.name]}
            onChange={(event) =>
              handleOnChange(
                item.name,
                event.detail.selectedOption.dataset.id,
                false,
                item.isMandatory,
              )
            }
          />
        )
      case editComponentTypes.Input:
        return (
          <Input
            {...item.editComponentProps}
            value={fieldValues[item.name]}
            valueState={fieldValuesState[item.name]}
            className="edit-input-field"
            valueStateMessage={
              item.editComponentProps?.valueStateMessage ?? (
                <span>
                  {t('components.ui.card.display-edit-card.input-error-message', {
                    label: item.label,
                  })}
                </span>
              )
            }
            onInput={(event) => {
              handleOnChange(
                item.name,
                event.target.value,
                true,
                item.isMandatory,
                item.validationFunction,
              )
            }}
            onBlur={(event) => {
              handleOnChange(
                item.name,
                event.target.value,
                false,
                item.isMandatory,
                item.validationFunction,
              )
              handleOnChangeCustom(item.name, event.target.value)
            }}
          />
        )
      case editComponentTypes.FormattedNumberInput:
        return (
          <FormattedNumberInput
            {...item.editComponentProps}
            value={fieldValues[item.name]}
            valueState={fieldValuesState[item.name]}
            className="edit-input-field"
            valueStateMessage={
              item.editComponentProps?.valueStateMessage ?? (
                <span>
                  {t('components.ui.card.display-edit-card.input-error-message', {
                    label: item.label,
                  })}
                </span>
              )
            }
            onChange={(parsedNumericValue) => {
              const validationFunction = (value) =>
                formattedNumberRef?.current?.isValid &&
                (!item.validationFunction || item.validationFunction(value))

              handleOnChange(
                item.name,
                parsedNumericValue,
                false,
                item.isMandatory,
                validationFunction,
              )
            }}
            ref={formattedNumberRef}
          />
        )
      case editComponentTypes.TextArea:
        return (
          <TextAreaWithMaxCharacterRestriction
            maxLength={200}
            {...item.editComponentProps}
            value={fieldValues[item.name]}
            valueState={fieldValuesState[item.name]}
            className="edit-input-field"
            valueStateMessage={
              item.editComponentProps?.valueStateMessage ?? (
                <span>
                  {t('components.ui.card.display-edit-card.input-error-message', {
                    label: item.label,
                  })}
                </span>
              )
            }
            onInput={(value, event) =>
              handleOnChange(
                item.name,
                event.target.value,
                true,
                item.isMandatory,
                item.validationFunction,
              )
            }
            onBlur={(event) =>
              handleOnChange(
                item.name,
                event.target.value,
                false,
                item.isMandatory,
                item.validationFunction,
              )
            }
          />
        )
      case editComponentTypes.DatePicker:
        return (
          <DatePicker
            formatPattern={localePattern}
            className={styles.datePicker}
            valueState={fieldValuesState[item.name]}
            {...item.editComponentProps}
            value={fieldValues[item.name]}
            onChange={(event) => {
              handleOnChange(
                item.name,
                event.detail.valid ? event.detail.value : '',
                false,
                item.isMandatory,
                item.validationFunction ??
                  (() => {
                    if (item.isMandatory) {
                      return (
                        !isNil(event.detail.value) &&
                        event.detail.value !== '' &&
                        event.detail.valid
                      )
                    } else {
                      return event.detail.valid
                    }
                  }),
              )
            }}
          />
        )
      case editComponentTypes.DatePickerWithoutMinWidth:
        return (
          <DatePickerWithoutMinWidth
            {...item.editComponentProps}
            value={fieldValues[item.name]}
            onChange={(event) => {
              handleOnChange(
                item.name,
                event.detail.valid ? event.detail.value : '',
                false,
                item.isMandatory,
                item.validationFunction ??
                  (() => {
                    if (item.isMandatory) {
                      return (
                        !isNil(event.detail.value) &&
                        event.detail.value !== '' &&
                        event.detail.valid
                      )
                    } else {
                      return event.detail.valid
                    }
                  }),
              )
            }}
            formatPattern={localePattern}
            className={styles.datePicker}
            valueState={fieldValuesState[item.name]}
          />
        )
      case editComponentTypes.CheckBox:
        return (
          <CheckBox
            {...item.editComponentProps}
            text={fieldValues[item.name]}
            onChange={(event) =>
              handleOnChange(
                item.name,
                event.target.checked,
                false,
                item.isMandatory,
                item.validationFunction,
              )
            }
          />
        )
    }
    return <></>
  }

  const renderSectionTitle = (item, index) => {
    const separatorLine =
      index !== 0 ? <FlexBox fitContainer className={styles['edit-title-separator']} /> : <></>
    const title = item.value?.sectionTitle ? (
      <Label className={styles['displayAndEditTitle']}>{item.value.sectionTitle}</Label>
    ) : (
      <></>
    )
    const subtitle = item.value?.sectionSubtitle ? (
      <Label className={styles['displayAndEditSubtitle']}>{item.value.sectionSubtitle}</Label>
    ) : (
      <div style={{ paddingBottom: '8px' }} />
    )
    return (
      <FlexBox key={`subsection-${index}`} fitContainer direction={FlexBoxDirection.Column}>
        {separatorLine}
        {title}
        {subtitle}
      </FlexBox>
    )
  }

  const renderCustomComponent = (item) => {
    if (item.customEditComponent) {
      return item.customEditComponent
    }
    if (item.renderCustomEditComponent) {
      return item.renderCustomEditComponent({
        handleOnChange,
        handleBulkOnChange,
        handleOnChangeCustom,
        fieldValues,
        setFieldValues,
        item,
        isInvalid: invalidFormState.includes(item.name),
        setInvalidFormState,
      })
    }
  }

  const isCustomComponent = (item) => item.customEditComponent || item.renderCustomEditComponent

  const additionalProps = {
    ...additionalEditHeaderProps,
    requiredFieldsValidation: {
      ...additionalEditHeaderProps.requiredFieldsValidation,
      hasWarning: hasRequiredFieldsError,
    },
  }

  const renderEditContent = () => (
    <FlexBox
      className={styles['card-edit-mode-wrapper']}
      direction={FlexBoxDirection.Column}
      {...(!!additionalProps?.isSaveLoading && { inert: '' })}
    >
      {fieldDefinitions.map((item, index) => {
        if (item.isSectionTitle) {
          return renderSectionTitle(item, index)
        }
        if (isCustomComponent(item)) {
          return <div key={`custom-component-${index}`}>{renderCustomComponent(item)}</div>
        }
        return (
          <EditCardItem
            key={index}
            isMandatory={item.isMandatory}
            label={item.label}
            editComponent={renderEditItem(item)}
          />
        )
      })}
      {additionalContentProps}
    </FlexBox>
  )
  const disableSaveButton = () => {
    const formHasErrors = invalidFormState.length > 0
    return !editModeHasChanges || formHasErrors || (isSaveDisabled && editModeHasChanges)
  }

  const renderView = () => (
    <Card
      header={
        <CardHeaderWithEditMode
          isEditMode={true}
          titleText={cardHeaderTitle}
          subtitleText={cardHeaderSubtitle}
          onSave={() => onSaveClicked(fieldValues)}
          checkSaveDisabled={disableSaveButton}
          onCancel={onCancelClicked}
          {...additionalProps}
        />
      }
      className={className}
    >
      <LoadingStateWrapper
        // In this context, the loading state is not design compliant and should only be used as a fallback/legacy feature.
        // Instead, the isSaveLoading prop should be set, which disables the card's content using inert
        isLoading={isLoading && isNil(additionalProps?.isSaveLoading)}
        isError={false} // Error handling by DisplayAndEditCard (display MessageBox)
        renderContent={renderEditContent}
      />
    </Card>
  )

  return renderView()
}
EditCardView.propTypes = {
  cardHeaderTitle: PropTypes.string.isRequired,
  cardHeaderSubtitle: PropTypes.string,
  setEditModeHasChanges: PropTypes.func.isRequired,
  editModeHasChanges: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  values: PropTypes.object,
  reqiredFieldsId: PropTypes.string,
  onSaveClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  fieldDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      formattedValue: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.bool,
      ]),
      hideOption: PropTypes.oneOf([HIDE_OPTIONS.alwaysHidden, HIDE_OPTIONS.hideWhenEmpty]),
      isShownInDisplay: PropTypes.bool,
      customInfoComponent: PropTypes.element,
      isShownInEdit: PropTypes.bool,
      customEditComponent: PropTypes.element,
      renderCustomEditComponent: PropTypes.func,
      editComponentType: PropTypes.oneOf(Object.values(editComponentTypes)),
      editComponentProps: PropTypes.object,
      isMandatory: PropTypes.bool,
      editComponentSelectOptions: PropTypes.array,
      validationFunction: PropTypes.func,
    }),
  ),
  additionalEditHeaderProps: PropTypes.object,
  className: PropTypes.string,
  isSaveDisabled: PropTypes.bool,
  additionalContentProps: PropTypes.element,
  customOnChangeHandler: PropTypes.func,
}
export default EditCardView
