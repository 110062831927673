import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'

const PortfolioInformationCardSection = ({ portfolio, countryCodes, typeCodes }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.portfolio-card',
  })

  const getDisplayNameByKey = (list, selection) =>
    (list ?? []).find(({ key }) => key === selection)?.display_name

  const formatCurrency = useCustomizableCurrencyFormatter()

  const typeCodeName = useMemo(
    () => getDisplayNameByKey(typeCodes, portfolio.mainPropertyTypeCode),
    [typeCodes, portfolio.mainPropertyTypeCode],
  )

  const countryNames = useMemo(
    () => portfolio.countryCodes?.map?.((code) => getDisplayNameByKey(countryCodes, code)),
    [countryCodes, portfolio.countryCodes],
  )

  return (
    <CardSection title={t('information')}>
      <CardShowItem label={t('number-of-properties')} value={portfolio.numberOfProperties} />
      <CardShowItem label={t('main-property-type')} value={typeCodeName} />
      <CardShowItem label={t('countries')} value={countryNames?.join(', ')} />
      <CardShowItem
        label={t('market-value')}
        value={formatCurrency(portfolio.marketValue?.number, portfolio.marketValue?.currencyCode)}
      />
      <CardShowItem
        label={t('mortgage-lending-value')}
        value={formatCurrency(
          portfolio.mortgageLendingValue?.number,
          portfolio.mortgageLendingValue?.currencyCode,
        )}
      />
    </CardSection>
  )
}

PortfolioInformationCardSection.propTypes = {
  portfolio: PropTypes.shape({
    numberOfProperties: PropTypes.number,
    mainPropertyTypeCode: PropTypes.string,
    countryCodes: PropTypes.arrayOf(PropTypes.string),
    marketValue: PropTypes.shape({
      number: PropTypes.number,
      currencyCode: PropTypes.string,
    }),
    mortgageLendingValue: PropTypes.shape({
      number: PropTypes.number,
      currencyCode: PropTypes.string,
    }),
  }),
  countryCodes: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, display_name: PropTypes.string }),
  ),
  typeCodes: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, display_name: PropTypes.string }),
  ),
}

export default PortfolioInformationCardSection
