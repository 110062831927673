import isNil from 'lodash.isnil'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AmountWithHeadquarterCurrency } from 'components/domains/deals/pricing/AmountWithHeadquarterCurrency'
import { usePercentageFormatter } from 'hooks/i18n/useI18n'
import useGetPricing from 'hooks/services/deals/pricing/useGetPricing'

const addFieldConfig = (value, config) => (isNil(value) ? [] : [config])

const usePricingFields = (dealUuid) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.overview.general-information.pricing',
  })
  const formatShare = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const {
    data: {
      values: {
        customer_margin_average,
        economic_profit_margin_full_cost,
        economic_profit_amount_full_cost,
        roe_cap_full_cost,
      } = {},
    } = {},
    isLoading,
    isError,
  } = useGetPricing({ dealUuid })

  const fields = useMemo(() => {
    if (isLoading || isError) return []

    return [
      ...addFieldConfig(customer_margin_average, {
        label: t('customerMargin'),
        name: 'customerMarginAvg',
        value: formatShare(customer_margin_average),
      }),
      ...addFieldConfig(economic_profit_margin_full_cost, {
        label: t('econProfitMargin'),
        name: 'econProfitMargin',
        value: formatShare(economic_profit_margin_full_cost),
      }),
      ...addFieldConfig(economic_profit_amount_full_cost, {
        label: t('econProfitAmount'),
        name: 'econProfitAmount',
        value: <AmountWithHeadquarterCurrency amount={economic_profit_amount_full_cost} />,
      }),
      ...addFieldConfig(roe_cap_full_cost, {
        label: t('roeCap'),
        name: 'roeCap',
        value: formatShare(roe_cap_full_cost),
      }),
    ]
  }, [
    customer_margin_average,
    economic_profit_amount_full_cost,
    economic_profit_margin_full_cost,
    formatShare,
    isError,
    isLoading,
    roe_cap_full_cost,
    t,
  ])

  return { isLoading, isError, data: fields }
}

export default usePricingFields
