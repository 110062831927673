import { Outlet } from 'react-router-dom'
import DealWrapper from 'routes/deals/DealWrapper'
import DealsListPage from 'routes/deals/DealsListPage'
import DealCashflowDetails from 'routes/deals/cashflow/DealCashflowDetails'
import DealCashflowOutlet from 'routes/deals/cashflow/DealCashflowOutlet'
import DealCashflowOverview from 'routes/deals/cashflow/DealCashflowOverview'
import DealChangeOverview from 'routes/deals/change-overview/DealChangeOverview'
import CollateralsOutlet from 'routes/deals/collaterals/CollateralsOutlet'
import DealCollaterals from 'routes/deals/collaterals/DealCollaterals'
import DealConditions from 'routes/deals/conditions/DealConditions'
import CovenantCheckDetails from 'routes/deals/covenant-checks/CovenantCheckDetails'
import DealCovenantDetails from 'routes/deals/covenants/DealCovenantDetails'
import DealCovenantOutlet from 'routes/deals/covenants/DealCovenantOutlet'
import DealCovenantsTabs from 'routes/deals/covenants/DealCovenantsTabs'
import DealDocuments from 'routes/deals/documents/DealDocuments'
import DealFinancingOutlet from 'routes/deals/financing/DealFinancingOutlet'
import DealFinancingOverview from 'routes/deals/financing/DealFinancingOverview'
import DealFinancingBankCustomerAccountDetails from 'routes/deals/financing/bank-customer-accounts/DealFinancingBankCustomerAccountDetails'
import DealFinancingTrancheDetails from 'routes/deals/financing/trancheDetails/DealFinancingTrancheDetails'
import DealGeneralInformation from 'routes/deals/general-information/DealGeneralInformation'
import DealOverviewDynamic from 'routes/deals/overview/DealOverviewDynamic'
import DealPropertiesTabs from 'routes/deals/properties/DealPropertiesTabs'
import DealSyndicationOverview from 'routes/deals/syndication/DealSyndicationOverview'
import DealKpiDetailsPage from 'routes/kpis/DealKpiDetailsPage'
import DealKpiOverviewPage from 'routes/kpis/DealKpiOverviewPage'

export const queryParameterNames = {
  workingVersion: 'working-version',
}

export const paths = {
  list: '',
  detail: ':dealId/*',
}

export const dealDetailPaths = {
  overview: '',
  generalInformation: 'general-information',
  financing: 'financing',
  financedObjects: 'financed-objects',
  collaterals: 'collaterals',
  cashflows: 'cashflows',
  cashflowDetails: ':cashflowId/*',
  conditions: 'conditions',
  covenants: 'covenants',
  covenantDetails: ':covenantId/*',
  covenantChecks: 'covenant-checks',
  covenantCheckDetails: ':covenantCheckUuid/*',
  syndication: 'syndication',
  documents: 'documents',
  changeOverview: 'change-overview',
  kpis: 'kpis',
  kpiDetails: ':kpiId/*',
  trancheDetails: 'tranches/:trancheDisplayId/*',
  drawdownDetails: 'drawdowns/:trancheDisplayId/*', // TODO: should be changed based on https://fioneer.atlassian.net/browse/CWP-7274
  collateralDetails: ':collateralId/*',
  bankCustomerAccountsDetails: 'bank-customer-accounts/:externalAccountNumber',
}

const DealsRoutes = [
  { path: paths.list, element: <DealsListPage /> },
  {
    path: paths.detail,
    element: <DealWrapper />,
    children: [
      {
        path: dealDetailPaths.overview,
        element: <DealOverviewDynamic />,
      },
      {
        path: dealDetailPaths.generalInformation,
        element: <DealGeneralInformation />,
      },
      {
        path: dealDetailPaths.financing,
        element: <DealFinancingOutlet />,
        children: [
          {
            index: true,
            element: <DealFinancingOverview />,
          },
          {
            path: dealDetailPaths.trancheDetails,
            element: <DealFinancingTrancheDetails />,
          },
          {
            path: dealDetailPaths.drawdownDetails,
            element: <DealFinancingTrancheDetails />,
          },
          {
            path: dealDetailPaths.bankCustomerAccountsDetails,
            element: <DealFinancingBankCustomerAccountDetails />,
          },
        ],
      },
      {
        path: dealDetailPaths.syndication,
        element: <DealSyndicationOverview />,
      },
      {
        path: dealDetailPaths.financedObjects,
        element: <DealPropertiesTabs />,
      },
      {
        path: dealDetailPaths.cashflows,
        element: <DealCashflowOutlet />,
        children: [
          {
            index: true,
            element: <DealCashflowOverview />,
          },
          {
            path: dealDetailPaths.cashflowDetails,
            element: <DealCashflowDetails />,
          },
        ],
      },
      {
        path: dealDetailPaths.collaterals,
        element: <CollateralsOutlet />,
        children: [
          {
            element: <DealCollaterals />,
            index: true,
          },
        ],
      },
      {
        path: dealDetailPaths.conditions,
        element: <DealConditions />,
      },
      {
        path: dealDetailPaths.covenants,
        element: <DealCovenantOutlet />,
        children: [
          {
            index: true,
            element: <DealCovenantsTabs />,
          },
          {
            path: dealDetailPaths.covenantDetails,
            element: <DealCovenantDetails />,
          },
          {
            path: dealDetailPaths.covenantChecks,
            element: <Outlet />,
            children: [
              {
                path: dealDetailPaths.covenantCheckDetails,
                element: <CovenantCheckDetails />,
              },
            ],
          },
        ],
      },
      {
        path: dealDetailPaths.kpis,
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <DealKpiOverviewPage />,
          },
          {
            path: dealDetailPaths.kpiDetails,
            element: <DealKpiDetailsPage />,
          },
        ],
      },
      {
        path: dealDetailPaths.documents,
        element: <DealDocuments />,
      },
      {
        path: dealDetailPaths.changeOverview,
        element: <DealChangeOverview />,
      },
    ],
  },
]

export default DealsRoutes
