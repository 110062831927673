import { Label } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import { string } from 'prop-types'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import styles from 'components/domains/deals/overview/external-team-members/ExternalTeamMembers.module.css'
import CardWithDisplayAndEditTable from 'components/ui/tables/display-and-edit-table/CardWithDisplayAndEditTable'
import { useExternalDealTeam } from 'hooks/services/deals/deal-team/useExternalDealTeam'
import { DealContext } from 'routes/deals/DealContext'

const ExternalTeamMembers = () => {
  const { deal: { dealUuid: dealUuid } = {} } = useContext(DealContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.external-team-members',
  })

  const {
    data: { externalDealTeamMembers = [], lastUpdatedBy, lastUpdatedAt } = {},
    isLoading: isExternalDealTeamLoading,
    isError: isExternalDealTeamError,
  } = useExternalDealTeam({ dealUuid: dealUuid })

  const { lastEditedText } = useLastEditedTextByEmail({
    email: lastUpdatedBy,
    timestamp: lastUpdatedAt,
  })

  const columnDefinitions = [
    {
      columnKey: 'name',
      title: t('user'),
    },
    {
      columnKey: 'userFunction',
      title: t('user-function'),
    },
    {
      columnKey: 'telephoneNumber',
      title: t('telephone-number'),
    },
    {
      columnKey: 'role',
      title: t('role'),
    },
    {
      columnKey: 'email',
      title: t('email'),
    },
  ]

  const tableData = externalDealTeamMembers.map(
    ({ email, name, userFunction, telephoneNumber, role }) => ({
      rowKey: `external-team-member-${email}`,
      name: {
        cellContentReadMode: (
          <Label className={styles['bold-cell-text']}>{isEmpty(name) ? email : name}</Label>
        ),
      },
      userFunction: {
        cellContentReadMode: <Label>{userFunction}</Label>,
      },
      email: {
        cellContentReadMode: <a href={`mailto:${email}`}>{email}</a>,
      },
      telephoneNumber: {
        cellContentReadMode: <a href={`ciscotel:${telephoneNumber}`}>{telephoneNumber}</a>,
      },
      role: {
        cellContentReadMode: <Label>{role}</Label>,
      },
    }),
  )

  return (
    <CardWithDisplayAndEditTable
      cardTitle={t('title-header')}
      toolbarTitle={t('title-toolbar', { teamMemberCount: externalDealTeamMembers.length })}
      subTitle={lastEditedText}
      isLoading={isExternalDealTeamLoading}
      isError={isExternalDealTeamError}
      columnDefinitions={columnDefinitions}
      tableData={tableData}
      newRow={{}}
      handleDeleteRow={() => {}}
      handleCancelEditRow={() => {}}
      handleSaveRow={() => {}}
    />
  )
}

ExternalTeamMembers.propTypes = {
  dealUuid: string,
}

export default ExternalTeamMembers
