import isNil from 'lodash.isnil'
import { DateTime } from 'luxon'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigContext } from 'hooks/config/useConfig'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useFinancialIndicatorsHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/financial-indicators/useFinancialIndicatorsHelper'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import {
  useMultipleAssessmentsAggregated,
  useMultipleBusinessPartnerRatingsAggregated,
  useMultipleBusinessPartnersByIdAggregated,
  useMultipleFiscalYearsAggregated,
  useMultipleTextualAssessmentsAggregated,
} from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/sponsor-guarantor/useInvolvedPartiesAggregatedQueries'
import { DEFAULT_RATING_AGENCY_PRIORITY } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useTenantDetails'
import useMultipleBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useMultipleBusinessPartnerRelationships'
import useMultipleCollateralAgreementsByTrancheIds from 'hooks/services/collaterals/useMultipleCollateralAgreementsByTrancheIds'
import useTranchesByDeals from 'hooks/services/deals/covenants/useTranchesByDeals'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const language = 'en-US'
const trancheDataSourceNewBusiness = DATA_SOURCES.NEW_BUSINESS

const mapAggregatedDataToBusinessPartners = (aggregatedData, multipleAllBpIds) =>
  multipleAllBpIds.map((allBpIds) => {
    const mappedBusinessPartner = {}
    allBpIds?.forEach((bpId) => {
      const businessPartnerData = aggregatedData?.[bpId]
      if (businessPartnerData) {
        mappedBusinessPartner[bpId] = businessPartnerData
      }
    })
    return mappedBusinessPartner
  })

const sortBusinessPartnersInfo = (businessPartnersInfo) =>
  businessPartnersInfo.sort(
    ({ information: { name: nameA } }, { information: { name: nameB } }) => {
      if (nameA > nameB) {
        return 1
      }
      if (nameA < nameB) {
        return -1
      }
      return 0
    },
  )

const getRelationshipHeadsIds = (relationships = [], borrowerBpId) => {
  const relationshipHeads = []
  relationships.forEach(({ members = [], head = {} }) => {
    if (members.find((member) => member.id === borrowerBpId)) {
      relationshipHeads.push(head.id)
    }
  })
  return relationshipHeads
}

const getCurrentAppreciationContent = ({ availableVersions } = {}) => {
  if ((availableVersions ?? []).length === 0) {
    return undefined
  }
  let largestVersion = availableVersions[0].version
  let assessmentContent = availableVersions[0].content
  for (const assessmentVersion of availableVersions) {
    if (assessmentVersion.version > largestVersion) {
      assessmentContent = assessmentVersion.content
    }
    largestVersion = assessmentVersion.version
  }
  return assessmentContent
}

const getLatestFiscalData = (fiscalYearsData) => {
  if (isNil(fiscalYearsData?.fiscal_years)) {
    return undefined
  }
  const fiscalYears = fiscalYearsData.fiscal_years
  let latestYear = 0
  Object.keys(fiscalYearsData.fiscal_years).forEach((yearString) => {
    const year = +yearString
    if (year > latestYear) {
      latestYear = year
    }
  })
  return fiscalYears[String(latestYear)]
}

const getInternalRating = (ratingsData) =>
  (ratingsData?.internal ?? []).find((rating) => rating.isActive)

const useAnnualReviewSponsorGuarantorOverview = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.bp-sponsor-guarantor-overview',
  })

  const {
    businessPartner: {
      sapRelationshipIds: { sponsor: sponsorRelationshipId } = {},
      functionCodes: { guarantor: guarantorFunctionCode } = {},
    } = {},
    ratingAgencyPriority = DEFAULT_RATING_AGENCY_PRIORITY,
    decisionPaper: { financialIndicators: financialIndicatorsFromConfig } = {},
  } = useContext(ConfigContext)

  const { isTileConfigError, getFinancialIndicators } = useFinancialIndicatorsHelper(
    financialIndicatorsFromConfig,
  )

  const {
    data: { selectedDeal, dealsData, dealUuids } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })
  const { selectedDealUuid } = selectedDeal ?? {}

  const selectedDealIndex = dealsData?.findIndex((deal) => deal?.dealUuid === selectedDealUuid)
  const selectedBusinessPartnerId = useMemo(
    () => dealsData?.find((deal) => deal?.dealUuid === selectedDealUuid)?.bpId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dealsData],
  )

  const businessPartnerIds = useMemo(() => dealsData?.map((deal) => deal?.bpId) ?? [], [dealsData])

  const {
    data: multipleBusinessPartnerRelationshipsData,
    isLoading: isMultipleBusinessPartnerRelationshipsLoading,
    isError: isMultipleBusinessPartnerRelationshipsError,
  } = useCombinedQueryResults(useMultipleBusinessPartnerRelationships(businessPartnerIds))

  const {
    isLoading: isMultipleTranchesLoading,
    isError: isMultipleTranchesError,
    trancheIdsByDeal,
  } = useTranchesByDeals({
    dealUuids,
    dataSource: trancheDataSourceNewBusiness,
    options: { enabled: dealUuids?.length > 0 },
  })

  const dealUuidsWithTranches = Object.entries(trancheIdsByDeal).map(([dealUuid, tranches]) => ({
    data: {
      dealUuid,
      tranches: tranches.map((tranche) => ({ trancheId: tranche })),
    },
  }))

  const {
    isLoading: isMultipleCollateralAgreementsLoading,
    isError: isMultipleCollateralAgreementsError,
    error: multipleCollateralAgreementsError,
    data: multipleCollateralAgreementsData,
  } = useCombinedQueryResults(useMultipleCollateralAgreementsByTrancheIds(dealUuidsWithTranches), {
    forceDataReturn: true,
  })

  const multipleGuarantorBpIds = useMemo(
    () =>
      multipleCollateralAgreementsData?.map((collateralAgreements) => {
        const result = new Set()
        collateralAgreements.data?.collateralAgreements?.forEach(({ businessPartners }) => {
          businessPartners?.forEach(({ function: { id: functionCode }, displayId }) => {
            if (functionCode === guarantorFunctionCode) {
              result.add(displayId)
            }
          })
        })
        return Array.from(result)
      }) ?? [],
    [guarantorFunctionCode, multipleCollateralAgreementsData],
  )

  const multipleSponsorRelationships = useMemo(
    () =>
      multipleBusinessPartnerRelationshipsData?.map(({ unitRelationships = [] }) =>
        unitRelationships.find(({ id }) => id === sponsorRelationshipId),
      ),
    [multipleBusinessPartnerRelationshipsData, sponsorRelationshipId],
  )

  const multipleSponsorBpIds = multipleSponsorRelationships?.map(
    ({ relationships = [] } = {}, index) =>
      getRelationshipHeadsIds(relationships, businessPartnerIds[index]),
  )

  const multipleAllBpIds = useMemo(
    () =>
      businessPartnerIds?.map((_, index) =>
        Array.from(
          new Set([
            ...(multipleSponsorBpIds?.[index] ?? []),
            ...(multipleGuarantorBpIds?.[index] ?? []),
          ]),
        ),
      ),
    [businessPartnerIds, multipleGuarantorBpIds, multipleSponsorBpIds],
  )

  const flatMultipleAllBpIds = multipleAllBpIds?.flatMap((allBpIds) => allBpIds)
  const enableFollowUpRequests = multipleAllBpIds?.some((allBpIds) => allBpIds?.length > 0)

  const {
    isLoading: isAllBusinessPartnersLoading,
    isError: isAllBusinessPartnersError,
    errors: allBusinessPartnersErrors,
    data: allBusinessPartners = {},
  } = useMultipleBusinessPartnersByIdAggregated({
    businessPartnerIds: flatMultipleAllBpIds,
    options: {
      enabled: enableFollowUpRequests,
      language,
    },
  })

  const multipleBusinessPartnerData = mapAggregatedDataToBusinessPartners(
    allBusinessPartners,
    multipleAllBpIds,
  )

  const {
    isLoading: isAggregatedFiscalYearsLoading,
    isError: isAggregatedFiscalYearsError,
    data: aggregatedFiscalYearsData,
    errors: aggregatedFiscalYearsErrors,
  } = useMultipleFiscalYearsAggregated({
    businessPartnerIds: flatMultipleAllBpIds,
    options: { enabled: enableFollowUpRequests },
  })

  const multipleFiscalYearsData = mapAggregatedDataToBusinessPartners(
    aggregatedFiscalYearsData,
    multipleAllBpIds,
  )

  const {
    isLoading: isAggregatedTextualAssessmentsLoading,
    isError: isAggregatedTextualAssessmentsError,
    data: aggregatedTextualAssessmentsData,
    errors: aggregatedTextualAssessmentsErrors,
  } = useMultipleTextualAssessmentsAggregated({
    businessPartnerIds: flatMultipleAllBpIds,
    type: 'description',
    options: { enabled: enableFollowUpRequests },
  })

  const multipleTextualAssessmentsData = mapAggregatedDataToBusinessPartners(
    aggregatedTextualAssessmentsData,
    multipleAllBpIds,
  )

  const {
    isLoading: isAggregatedAssessmentsLoading,
    isError: isAggregatedAssessmentsError,
    errors: aggregatedAssessmentsErrors,
    data: aggregatedAssessmentsData,
  } = useMultipleAssessmentsAggregated({
    businessPartnerIds: flatMultipleAllBpIds,
    options: { enabled: enableFollowUpRequests },
  })

  const multipleAssessmentsData = mapAggregatedDataToBusinessPartners(
    aggregatedAssessmentsData,
    multipleAllBpIds,
  )

  const {
    isLoading: isAggregatedRatingsLoading,
    isError: isAggregatedRatingsError,
    data: aggregatedRatingsData,
    errors: aggregatedRatingsErrors,
  } = useMultipleBusinessPartnerRatingsAggregated({
    businessPartnerIds: flatMultipleAllBpIds,
    options: { enabled: enableFollowUpRequests },
  })

  const multipleRatingsData = mapAggregatedDataToBusinessPartners(
    aggregatedRatingsData,
    multipleAllBpIds,
  )

  const sortExternalRatingsByAgencyPriority = useCallback(
    (a, b) =>
      (ratingAgencyPriority.includes(a.agency.id)
        ? ratingAgencyPriority.indexOf(a.agency.id)
        : ratingAgencyPriority.length) -
      (ratingAgencyPriority.includes(b.agency.id)
        ? ratingAgencyPriority.indexOf(b.agency.id)
        : ratingAgencyPriority.length),
    [ratingAgencyPriority],
  )

  const getExternalRating = useCallback(
    (ratingsData) => {
      const today = DateTime.now()
      return (ratingsData?.external ?? [])
        .filter((rating) => {
          const validFromDate = DateTime.fromISO(rating.validFrom)
          const validToDate = DateTime.fromISO(rating.validTo)
          return validFromDate <= today && validToDate >= today
        })
        .slice()
        .sort(sortExternalRatingsByAgencyPriority)?.[0]
    },
    [sortExternalRatingsByAgencyPriority],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isMultipleBusinessPartnerRelationshipsLoading,
      isMultipleTranchesLoading,
      isMultipleCollateralAgreementsLoading,
      enableFollowUpRequests && isAllBusinessPartnersLoading,
      enableFollowUpRequests && isAggregatedFiscalYearsLoading,
      enableFollowUpRequests && isAggregatedTextualAssessmentsLoading,
      enableFollowUpRequests && isAggregatedAssessmentsLoading,
      enableFollowUpRequests && isAggregatedRatingsLoading,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isMultipleBusinessPartnerRelationshipsError,
      isMultipleTranchesError,
      isMultipleCollateralAgreementsError,
      isAllBusinessPartnersError,
      isAggregatedFiscalYearsError,
      isAggregatedTextualAssessmentsError,
      isAggregatedAssessmentsError,
      isAggregatedRatingsError,
    ],
    errorDetails: [
      annualReviewDealsError,
      multipleCollateralAgreementsError,
      ...(allBusinessPartnersErrors ?? []),
      ...(aggregatedFiscalYearsErrors ?? []),
      ...(aggregatedTextualAssessmentsErrors ?? []),
      ...(aggregatedAssessmentsErrors ?? []),
      ...(aggregatedRatingsErrors ?? []),
    ],
    tileId,
  })

  const multipleCategorizedBusinessPartners = useMemo(
    () =>
      multipleAllBpIds?.map((allBpIds = [], index) => {
        const categorizedTileInfo = {
          guarantors: [],
          sponsors: [],
          guarantorsAndSponsors: [],
        }

        for (const bpId of allBpIds) {
          /** @type {string} */
          const businessPartner = multipleBusinessPartnerData?.[index]?.[bpId]

          if (isNil(businessPartner)) {
            break
          }
          const isGuarantor = multipleGuarantorBpIds?.[index]?.includes(bpId)
          const isSponsor = multipleSponsorBpIds?.[index].includes(bpId)

          const assessmentData = multipleAssessmentsData?.[index]?.[bpId]
          const appreciation = getCurrentAppreciationContent(
            multipleTextualAssessmentsData?.[index]?.[bpId],
          )
          const fiscalData = getLatestFiscalData(multipleFiscalYearsData?.[index]?.[bpId])
          const ratingData = multipleRatingsData?.[index]?.[bpId]
          const internalRating = getInternalRating(ratingData)
          const externalRating = getExternalRating(ratingData)

          const businessPartnerTileInfo = {
            information: {
              businessPartnerId: bpId,
              name: businessPartner.fullName,
              legalForm: businessPartner.legalForm,
              foundationDate: businessPartner.foundationDate,
              industryInformation: businessPartner.industry,
              overallQualityAssessment: {
                category: assessmentData?.assessments?.[0]?.category,
                level: assessmentData?.assessments?.[0]?.level,
                levelDisplayValue: assessmentData?.assessments?.[0]?.levelDisplayValue,
              },
            },
            address: isNil(businessPartner.address)
              ? undefined
              : {
                  streetName: businessPartner.address.streetName,
                  postalCode: businessPartner.address.postalCode,
                  city: businessPartner.address.city,
                  region: businessPartner.address.region,
                  country: businessPartner.address.country,
                },
            internalRating: isNil(internalRating)
              ? undefined
              : {
                  class: internalRating.ratingClass,
                  method: internalRating.method?.name,
                  validFrom: internalRating.validFrom,
                },
            externalRating: isNil(externalRating)
              ? undefined
              : {
                  grade: externalRating.grade,
                  agency: externalRating.agency?.name,
                  validFrom: externalRating.validFrom,
                },
            financialIndicators: getFinancialIndicators(fiscalData),
            appreciation,
          }

          if (isGuarantor && isSponsor) {
            categorizedTileInfo.guarantorsAndSponsors.push(businessPartnerTileInfo)
          } else if (isGuarantor) {
            categorizedTileInfo.guarantors.push(businessPartnerTileInfo)
          } else if (isSponsor) {
            categorizedTileInfo.sponsors.push(businessPartnerTileInfo)
          }
        }

        const sponsors = sortBusinessPartnersInfo(categorizedTileInfo.sponsors)
        const guarantorsAndSponsors = sortBusinessPartnersInfo(
          categorizedTileInfo.guarantorsAndSponsors,
        )
        const guarantors = sortBusinessPartnersInfo(categorizedTileInfo.guarantors)

        return {
          sponsors,
          guarantorsAndSponsors,
          guarantors,
        }
      }),
    [
      getExternalRating,
      getFinancialIndicators,
      multipleAllBpIds,
      multipleAssessmentsData,
      multipleBusinessPartnerData,
      multipleFiscalYearsData,
      multipleGuarantorBpIds,
      multipleRatingsData,
      multipleSponsorBpIds,
      multipleTextualAssessmentsData,
    ],
  )

  return useMemo(() => {
    if (isTileConfigError) {
      return {
        isLoading: isSomeValueLoading,
        isError: true,
        error: {
          isTileConfigError,
          customErrorMessage: t('error.config'),
        },
      }
    }
    if (isSomeValueLoading || isSomeValueError) {
      return { isLoading: isSomeValueLoading, isError: isSomeValueError, error }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multipleCategorizedBusinessPartners,
        isHidden:
          multipleCategorizedBusinessPartners?.[selectedDealIndex]?.sponsors.length === 0 &&
          multipleCategorizedBusinessPartners?.[selectedDealIndex]?.guarantorsAndSponsors.length ===
            0 &&
          multipleCategorizedBusinessPartners?.[selectedDealIndex]?.guarantors.length === 0,
        sourceRender: {
          businessPartnerId: selectedBusinessPartnerId,
        },
      },
    }
  }, [
    isTileConfigError,
    isSomeValueLoading,
    isSomeValueError,
    dealsData,
    multipleCategorizedBusinessPartners,
    selectedDealIndex,
    selectedBusinessPartnerId,
    t,
    error,
  ])
}

export default useAnnualReviewSponsorGuarantorOverview
