import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useCreateDealTeamMember from 'hooks/services/deals/deal-team/useCreateDealTeamMember'
import useDealTeam from 'hooks/services/deals/deal-team/useDealTeam'
import useDealTeamUsersByIds from 'hooks/services/deals/deal-team/useDealTeamUsersByIds'
import useDeleteDealTeamMember from 'hooks/services/deals/deal-team/useDeleteDealTeamMember'
import useUpdateDealTeamMember from 'hooks/services/deals/deal-team/useUpdateDealTeamMember'
import useDealMini from 'hooks/services/deals/useDealMini'

const useHandleExternalCommunication = ({ dealUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.overview.internal-team-members',
  })
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()

  const errorMessages = {
    DL_NO_ACCOUNT_MANAGER_ERROR: t('no-account-manager'),
    DL_INVALID_AMOUNT_OF_LEADERS_ERROR: t('invalid-amount-of-leaders'),
  }

  const { data: { borrowerBpId } = {} } = useDealMini(dealUuid)
  const { data: { allowedOperations: bpAllowedOperations = [] } = {} } =
    useBusinessPartnerById(borrowerBpId)

  const {
    data: { staffMembers = [] } = {},
    isLoading: isDealTeamLoading,
    isError: isDealTeamError,
  } = useDealTeam({ dealUuid })

  const staffMembersWithVirtualId = useMemo(
    () =>
      staffMembers.map((member) =>
        merge(cloneDeep(member), {
          virtualId: `${member.userId}-${member.relation.sapRelationId}`,
        }),
      ),
    [staffMembers],
  )

  const userIds = staffMembersWithVirtualId.map(({ userId }) => userId)
  const { data: users = [], isSomeLoading: isUsersLoading } = useDealTeamUsersByIds(userIds)

  const getUserById = (id) => users.find((user) => user?.id === id) ?? { fullName: id, team: '' }

  const handleError = async (error, defaultMessage) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    const errorKey = Object.keys(errorResponse?.metadata ?? {})?.[0]
    showErrorMessageBox({
      message: errorMessages[errorKey] ?? defaultMessage,
      error: errorResponse,
    })
  }

  const createDealTeamMember = useCreateDealTeamMember({
    onSuccess: () => {
      queryClient.invalidateQueries(['deals', dealUuid, 'deal-teams'])
      showToast({ children: t('create-success') }, document.body)
    },
    onError: (error) => handleError(error, t('create-error')),
  })

  const updateDealTeamMember = useUpdateDealTeamMember({
    onSuccess: () => {
      queryClient.invalidateQueries(['deals', dealUuid, 'deal-teams'])
      showToast({ children: t('update-success') }, document.body)
    },
    onError: (error) => handleError(error, t('update-error')),
  })

  const deleteDealTeamMember = useDeleteDealTeamMember({
    onSuccess: () => {
      queryClient.invalidateQueries(['deals', dealUuid, 'deal-teams'])
      showToast({ children: t('delete-success') }, document.body)
    },
    onError: (error) => handleError(error, t('delete-error')),
  })

  return {
    createDealTeamMember,
    updateDealTeamMember,
    deleteDealTeamMember,
    businessPartnerAllowedOperations: bpAllowedOperations,
    staffMembers: staffMembersWithVirtualId,
    isDealTeamError,
    isDealTeamLoading,
    getUserById,
    isUsersLoading,
  }
}

export default useHandleExternalCommunication
